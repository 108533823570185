import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(private http: HttpClient) { }

    private apiEndPoint = environment.apiBaseUrl + 'api/storage/';

    getAzureSasToken() {
        return this.http.get(this.apiEndPoint + 'get-azure-sas-token');
    }

    createContainerIfNotExists(containerName: string) {
        return this.http.post(`${this.apiEndPoint}container?containerName=${containerName}`, null);
    }
}
