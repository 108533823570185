import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import moment from "moment";
import { Subscription } from "rxjs";
import { NzDrawerComponent } from "ng-zorro-antd/drawer";
import { AppUtils, Constants } from "src/helpers";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Guid } from "guid-typescript";
import { NzModalService } from "ng-zorro-antd/modal";
import { BroadcastListItemModel, DisplayListListItemModel, GroupDetailModel, MediaAppDetailModel, MediaAppListItemModel, SelectListItemModel, TimeZoneModel, UserMediaAppVimeoUpsertModel } from "src/app/shared/models";
import { BaseService, DisplayListService, FolderService, GroupService, MediaAppService, UserMediaAppService } from "src/app/shared/services";
import { UserMediaAppUpsertModel } from "src/app/shared/models/user-media-app/user-media-app-upsert.model";

@Component({
    selector: 'app-user-media-app-vimeo-drawer',
    templateUrl: './user-media-app-vimeo.component.html',
    styleUrls: ['./user-media-app-vimeo.component.scss'],
})

export class UserMediaAppVimeoComponent implements OnInit {
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @Output() closeDrawer: EventEmitter<{ mediaId: number, mediaAdded: boolean }> = new EventEmitter<{ mediaId: number, mediaAdded: boolean }>();
    @Input() mediaAppId: any;
    @Input() selectedGroupId: number;

    isLoading = false;
    showScheduleFields = false;
    visible = false;
    model = new UserMediaAppUpsertModel();
    showGroupSelection = true;
    subscriptions = new Array<Subscription>();
    subscriptionPlans = new Array<SelectListItemModel>();
    mediaAppModel = new MediaAppDetailModel();
    groups = new Array<GroupDetailModel>();
    accessToken: string;
    url: any;
    searchVideo: boolean;
    displayLists = new Array<SelectListItemModel>();
    selectedDisplayLists = new Array<SelectListItemModel>();
    mediaApps: Array<MediaAppListItemModel>;
    pendingDisplayListIds = new Array<number>();
    showDuration = false;
    isEndDateValid = true;
    isEndTimeValid = true;
    isStartTimeEndTimeValid = true;
    schedule = false;
    modelLoaded = false;
    timeZonesLoaded = false;
    showPlaylists = false;
    showBroadcasts = false;
    noMediaInPlaylist: boolean;
    selectedVideo: any;
    mediaIcon = './assets/images/media-types/icons/Vimeo.svg';
    minDateTime: any;
    timeZones = new Array<TimeZoneModel>();
    folders = new Array<SelectListItemModel>();
    selectedFolder = new Array<SelectListItemModel>();
    selectedPlaylists = new Array<DisplayListListItemModel>();
    selectedBroadcasts = new Array<BroadcastListItemModel>();
    selectedBroadcast: BroadcastListItemModel;
    editBroadcastList = true;
    imageUrlToPreview: string;
    selectedBroadcastIds = new Array<number>();
    selectedPlayListIds = new Array<number>();
    isScheduled: boolean = false;
    scheduleStartTime: any;
    scheduleEndTime: any;
    vimeoVideosResponse: any;
    vimeoVideos: any;
    showVimeoView: boolean;
    isGroupLoaded: boolean = false;
    get constants() { return Constants; }

    constructor(private httpClient: HttpClient,
        private baseService: BaseService,
        private groupService: GroupService,
        private folderService: FolderService,
        private userMediaAppService: UserMediaAppService,
        private mediaAppService: MediaAppService,
        private displayListService: DisplayListService,
        private modal: NzModalService,
        public appUtils: AppUtils,
    ) {
        this.model = new UserMediaAppUpsertModel();
        this.model.vimeo = new UserMediaAppVimeoUpsertModel();

        this.subscriptionPlans = new Array<SelectListItemModel>();
        this.folders = new Array<SelectListItemModel>();
        this.groups = new Array<GroupDetailModel>();
        this.mediaApps = new Array<MediaAppListItemModel>();

        this.minDateTime = appUtils.getFullDateTime();
    }

    ngOnInit() {
        this.model.startDate = new Date();
        this.model.endDate = new Date();
        this.model.mediaAppId = this.mediaAppId;
        this.getMediaAppDetails();
        this.loadGroups();
    }

    getMediaAppDetails() {
        this.mediaAppService.get(this.model.mediaAppId)
            .subscribe({
                next: (response: MediaAppDetailModel) => {
                    this.mediaAppModel = response;
                    this.mediaAppModel.iconUrl = this.generateMediaIcon(this.mediaAppModel.typeString)

                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    generateMediaIcon(mediaAppType: string): string {
        return `./assets/images/media-types/icons/v2/${mediaAppType}.svg`;
    }

    loadGroups(): void {
        this.isLoading = true;
        const requestSubscription =
            this.groupService.getList()
                .subscribe({
                    next: (response: Array<GroupDetailModel>) => {
                        let selectListItem = new SelectListItemModel();
                        selectListItem.keyString = '-1';
                        selectListItem.keyInt = -1;
                        selectListItem.value = `My Subscription (${this.appUtils.getUserName()})`;

                        this.subscriptionPlans.push(selectListItem);

                        Object.assign(this.groups, response);

                        this.groups.forEach(group => {
                            selectListItem = new SelectListItemModel();
                            selectListItem.keyInt = group.id;
                            selectListItem.keyString = group.id.toString();
                            selectListItem.value = `${group.name} (${group.ownerName})`;
                            this.subscriptionPlans.push(selectListItem);
                        });

                        if (this.selectedGroupId != undefined && this.selectedGroupId > 0) {
                            this.model.groupId = this.selectedGroupId;
                        }
                        else {
                            this.selectedGroupId = -1;
                            this.model.groupId = -1;
                        }
                        this.isGroupLoaded = true;
                        this.isLoading = false;
                        this.getFolders();
                        this.loadDisplayLists();
                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);

                    }
                });

        this.subscriptions.push(requestSubscription);
    }

    loadDisplayLists() {
        if (!this.isGroupLoaded) {
            return;
        }
        this.displayLists = new Array<SelectListItemModel>();
        this.isLoading = true;
        const requestSubscription = this.displayListService
            .getSelectListItems(this.model.groupId)
            .subscribe({
                next: (data: Array<any>) => {
                    this.isLoading = false;
                    this.displayLists = data;
                    this.updateSelectedDisplayList();
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestSubscription);
    }

    updateSelectedDisplayList() {
        if (this.displayLists.length === 0) {
            return;
        }

        const selectedDisplayLists = new Array<SelectListItemModel>();
        this.model.displayLists.forEach((displayList) => {
            selectedDisplayLists.push(
                this.displayLists.find(
                    (x) => Number(x.keyInt) === Number(displayList.keyInt)
                )
            );
        });

        if (selectedDisplayLists && selectedDisplayLists.length > 0) {
            this.selectedDisplayLists = selectedDisplayLists;
        }
    }

    onGroupChange(): void {
        this.getFolders();
    }

    openLoginPage(url: string): void {
        const popup = window.open(url, 'vimeo', 'popup');
        const vimeoLoginEndpointInterval = setInterval(() => {
            try {
                if (AppUtils.isNullOrUndefined(popup.location.href)) {
                    clearInterval(vimeoLoginEndpointInterval);
                    this.isLoading = false;
                }
                if (popup.location.href.indexOf('#access_token') !== -1) {
                    const uri = popup.location.href.replace('#', '');
                    const params = (new URL(uri)).searchParams;
                    if (params.has('access_token')) {
                        this.accessToken = params.get('access_token');
                        localStorage.setItem(Constants.varTempAuthToken, this.accessToken);
                        this.isLoading = false;
                        this.getAuthorizedBy();
                        this.searchVideo = true;
                        this.drawer.open();
                    }
                    popup.close();
                    clearInterval(vimeoLoginEndpointInterval);
                }
            } catch (e) {
                this.isLoading = false;
            }
        }, 100);
    }

    selectVimeoVideo(): void {

        if (this.appUtils.isNullOrEmpty(this.accessToken)) {
            this.signInWithVimeo();
        } else {
            this.getAuthorizedBy();
            this.searchVideo = true;
            this.drawer.open();
        }
    }

    getAuthorizedBy(): void {
        let headers = new HttpHeaders();
        this.accessToken = this.appUtils.getTempAuthToken();
        headers = headers.set('Authorization', `Bearer ${this.accessToken}`);

        this.httpClient.get('https://api.vimeo.com/me', { headers: headers })
            .subscribe({
                next: (response: any) => {
                    this.model.vimeo.authorizedBy = response.name;
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }


    signInWithVimeo(): void {
        this.isLoading = true;
        const state = Guid.create().toString();
        this.url = this.appUtils.buildVimeoAuthorizationEndpoint(state);
        if (!AppUtils.isNullOrUndefined(this.url)) {
            this.openLoginPage(this.url);
        } else {
            this.isLoading = false;
            this.baseService.error('Something went wrong please try again.');
        }
    }

    getFolders() {
        this.isLoading = true;

        const requestsubscription =
            this.folderService.getList(this.model.groupId, this.model.folderId)
                .subscribe({
                    next: (response: Array<SelectListItemModel>) => {
                        this.folders = response;
                        this.updateSelectedFolder();
                        this.isLoading = false;
                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);
                    }
                });

        this.subscriptions.push(requestsubscription);
    }

    updateSelectedFolder() {
        if (this.folders.length === 0 || !this.model.folderId) {
            return;
        }

        const selectedFolder = this.folders.filter(x => Number(x.keyInt) === Number(this.model.folderId));

        if (selectedFolder) {
            this.selectedFolder = selectedFolder;
        }
    }

    disableStartDate = (current: Date): boolean => {
        return current && current < new Date(new Date().setHours(0, 0, 0, 0));
    };

    disableEndDate = (current: Date): boolean => {
        if (!this.model.startDate) {
            return current && current < new Date(new Date().setHours(0, 0, 0, 0));
        }
        return current && current < new Date(this.model.startDate);
    };

    openVideoSearchDrawer(): void {
        this.visible = true;
    }

    updateUrl() {
        if (!this.model.vimeo.url) {
            this.model.vimeo.url = undefined;
        }
    }

    onScheduleClick() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.model.startTime = null;
        this.model.endTime = null;
        this.showScheduleFields = this.model.isScheduled;
    }

    validateScheduleDetails(): boolean {
        if (!this.model.isScheduled) {
            this.model.startTime = null;
            this.model.endTime = null;
            this.model.startDate = null;
            this.model.endDate = null;
            return true;
        }

        if (this.scheduleStartTime && this.scheduleEndTime) {
            if (!this.isStartTimeEndTimeValid) {
                return false;
            }

            const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
            const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
            if (
                !moment(endTime).isAfter(moment(startTime)) &&
                startTime.format('a') === endTime.format('a')
            ) {
                this.isEndTimeValid = false;
                return false;
            }
            this.model.startTime = this.appUtils.getFormattedTime(
                startTime.toString(),
                null
            );
            this.model.endTime = this.appUtils.getFormattedTime(
                endTime.toString(),
                null
            );

            if (this.model.isDaily) {
                this.model.startDate = null;
                this.model.endDate = null;
                return true;
            }
            this.model.startDate = this.appUtils.getFormattedDate(
                this.model.startDate,
                null
            );
            this.model.endDate = this.appUtils.getFormattedDate(
                this.model.endDate,
                null
            );
            if (moment(this.model.endDate).isBefore(this.model.startDate)) {
                this.isEndDateValid = false;
                return false;
            }
            this.isEndDateValid = true;
            return true;
        } else {
            return false;
        }
    }

    isTimeValid(time: any) {
        const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
        const endTime = moment(time, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    checkMediaStatus() {
        if (!this.model.startTime || !this.model.endTime) {
            this.model.isScheduled = false;
            return;
        }
        this.model.isScheduled = true;
    }

    cancel() {
        this.closeDrawer.emit({ mediaId: 0, mediaAdded: false });
    }

    openPlaylistDrawer(): void {
        this.model.displayLists.forEach(x =>
            this.selectedPlayListIds.push(x.keyInt));
        this.showPlaylists = true;
    }

    closeVimeoDrawer(event: any): void {
        if (event.updateValue) {
            this.selectedVideo = event.selectedVideo;
            this.setEmbeddedUrl(event.selectedVideo)
        }
        this.drawer.close();
        this.searchVideo = false;
    }

    removeVideo() {
        this.selectedVideo = null;
        this.model.vimeo = new UserMediaAppVimeoUpsertModel();

    }

    setEmbeddedUrl(item: any) {
        this.model.vimeo.videoTitle = item.name;
        this.model.vimeo.embeddedUrl = item.player_embed_url;
        this.model.vimeo.duration = item.duration;
        this.model.lengthInSeconds = item.duration;
        this.showVimeoView = false;
        this.visible = false;
    }

    generateMediaIconUrl(mediaType: string): string {
        return `./assets/images/media-types/icons/${mediaType}.svg`;
    }

    generateMediaPreviewUrl(mediaType: string): string {
        return `./assets/images/media-types/preview/${mediaType}.svg`;
    }

    getMediaForPreview(): string {
        return `./assets/images/media-types/preview/Vimeo.svg`;
    }

    getMediaIcon(): string {
        return `./assets/images/media-types/icons/Vimeo.svg`;
    }

    closePlaylistDrawer(event: any): void {
        if (event.updateValue) {
            this.selectedPlaylists = new Array<DisplayListListItemModel>();
            Object.assign(this.selectedPlaylists, event.selectedDisplayLists);

            this.model.displayLists = new Array<SelectListItemModel>();
            event.selectedDisplayLists.forEach(x => {

                let displayList = new SelectListItemModel();
                displayList.keyInt = x.id;
                this.model.displayLists.push(displayList);
            }
            )
        }
        this.drawer.close();
        this.showPlaylists = false;
    }


    removePlaylist(index: number): void {
        const deletedList = this.selectedPlaylists.splice(index, 1);
        this.selectedPlaylists = this.selectedPlaylists.filter(x => x.id !== deletedList[0].id);
        this.model.displayLists = this.model.displayLists.filter(x => x.keyInt !== deletedList[0].id);
        this.selectedPlayListIds = this.selectedPlayListIds.filter(x => x !== deletedList[0].id);
    }

    onPlaylistUrlError(item: DisplayListListItemModel): void {
        item.urlError = true;
    }

    openBroadcastDrawer(): void {
        this.model.broadcastIds.forEach(x =>
            this.selectedBroadcastIds.push(x));
        this.showBroadcasts = true;
        this.drawer.open();
    }

    closeBroadcastDrawer(event: any): void {
        if (event.updateValue) {

            this.selectedBroadcasts = new Array<BroadcastListItemModel>();
            Object.assign(this.selectedBroadcasts, event.selectedBroadcasts);
        }
        this.drawer.close();
        this.showBroadcasts = false;
    }

    removeBroadcast(index: number): void {
        const deletedList = this.selectedBroadcasts.splice(index, 1);
        this.selectedBroadcasts = this.selectedBroadcasts.filter(x => x.id !== deletedList[0].id);
        this.model.broadcastIds = this.model.broadcastIds.filter(x => x !== deletedList[0].id);
        this.selectedBroadcastIds = this.selectedBroadcastIds.filter(x => x !== deletedList[0].id);
    }

    openBroadcastListDrawer(): void {
        this.editBroadcastList = true;
        this.drawer.open();
    }


    closeBroadcastListDrawer(event: any): void {
        if (event.updateValue) {

            this.selectedBroadcasts = new Array<BroadcastListItemModel>();
            Object.assign(this.selectedBroadcasts, event.selectedBroadcasts);

            this.model.broadcastIds = new Array<number>();
            event.selectedBroadcasts.forEach(x => {
                this.model.broadcastIds.push(x.id);
            });
        }
        this.drawer.close();
        this.showBroadcasts = false;
    }

    onBroadcastImgError(item: BroadcastListItemModel): void {
        item.urlError = true;
    }


    onSchedule() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.scheduleStartTime = null;
        this.scheduleEndTime = null;

    }

    scheduleTypeChanged(isDaily: boolean) {
        this.model.isDaily = isDaily;
        this.model.startDate = null;
        this.model.endDate = null;
    }

    isStartTimeValid(time: any): void {
        const startTime = moment(time, 'hh:mm A').set({ s: 0 });
        const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    submit() {
        this.isLoading = true;
        if (this.model.groupId == null || this.model.groupId == -1) {
            this.model.groupId = null;
        }

        this.model.isScheduled = this.isScheduled;
        if (!this.validateScheduleDetails()) {
            this.isScheduled = true;
            this.isLoading = false;
            return;
        }

        this.model.displayLists = new Array<SelectListItemModel>();
        if (this.selectedDisplayLists && this.selectedDisplayLists.length > 0) {
            this.selectedDisplayLists.forEach((item) => {
                let selectedDisplayListModel = new SelectListItemModel();
                selectedDisplayListModel.keyInt = item.keyInt;
                selectedDisplayListModel.value = item.value;
                this.model.displayLists.push(selectedDisplayListModel);
            });
            this.model.lengthInSeconds =
                this.selectedDisplayLists.length === 1 || this.showDuration
                    ? this.model.lengthInSeconds
                    : '0';
        }

        if (this.selectedFolder && this.selectedFolder.length > 0) {
            this.model.folderId = this.selectedFolder[0].keyInt;
        } else {
            this.model.folderId = null;
        }

        this.model.id = 0;
        this.userMediaAppService.add(this.model).subscribe({
            next: (response: number) => {
                this.isLoading = false;
                this.baseService.successNotification('Media added successfully.');
                this.closeDrawer.emit({ mediaId: response, mediaAdded: true });
            },
            error: (error: any) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            }
        });
    }

    removeDisplayList(item: SelectListItemModel) {
        const tempArr = [];
        this.selectedDisplayLists.forEach((displayList) => {
            tempArr.push(displayList);
        });
        const itemIndex = tempArr.indexOf(item);
        tempArr.splice(itemIndex, 1);
        this.selectedDisplayLists = tempArr;
    }
}
