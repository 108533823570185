export class CardDetailModel {
    fullName: string;
    cardNumber: number;
    expiryMonth: number;
    expiryYear: number;
    cvv: string;
    billingCountry: string;
    addOnId: string;
    cardNumberMarked: string;
    planQuantity: number;
    cardType: string;
    billingAddress1: string;
    billingCity: string;
    billingState: string;
    billingStateCode: string;
    billingZip: string;
}
