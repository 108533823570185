import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TimeZoneModel } from '../models';

@Injectable()
export class TimeZoneService {
  private apiEndPoint = environment.apiBaseUrl + 'api/timezone/';

  constructor(private http: HttpClient) {}

  getAll(): Observable<Array<TimeZoneModel>> {
    return this.http.get<Array<TimeZoneModel>>(this.apiEndPoint + 'list');
  }
}
