import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { Guid } from 'guid-typescript';
import moment from 'moment';
import { NzDrawerComponent } from 'ng-zorro-antd/drawer';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Subscription } from 'rxjs';
import { DisplayListListItemModel, GroupDetailModel, MediaAppDetailModel, SelectListItemModel, TimeZoneModel, UserMediaAppClockUpsertModel } from 'src/app/shared/models';
import { UserMediaAppUpsertModel } from 'src/app/shared/models/user-media-app/user-media-app-upsert.model';
import { BaseService, DisplayListService, FolderService, GroupService, MediaAppService, StorageService, TimeZoneService, UserMediaAppService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { AppUtils, Constants } from 'src/helpers';

@Component({
    selector: 'app-user-media-app-clock-drawer',
    templateUrl: './user-media-app-clock.component.html',
    styleUrls: ['./user-media-app-clock.component.scss'],
})
export class UserMediaAppClockComponent implements OnInit {
    @Output() closeDrawer: EventEmitter<{ mediaId: number, mediaAdded: boolean }> = new EventEmitter<{ mediaId: number, mediaAdded: boolean }>();
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @Input() mediaAppId: any;
    @Input() selectedGroupId: number;
    isLoading: boolean = false;
    isTimeZoneLoaded: boolean = false;
    timeZoneModel = new Array<TimeZoneModel>();
    model = new UserMediaAppUpsertModel();
    folders = new Array<SelectListItemModel>();
    subscriptionPlans = new Array<SelectListItemModel>();
    groups = new Array<GroupDetailModel>();
    subscriptions = new Array<Subscription>();
    showPlaylists: boolean = false;
    showBroadcasts: boolean = false;
    showMedias: boolean = false;
    editDisplayList: boolean = false;
    editBroadcastList: boolean = true;
    visible: boolean = false;
    noMediaInPlaylist: boolean = false;
    imageUrlToPreview: string;
    displayLists = new Array<SelectListItemModel>();
    selectedDisplayLists = new Array<SelectListItemModel>();
    mediaAppModel = new MediaAppDetailModel();
    selectedImageName: any;
    fileUploadRequest: any;
    userMediaAppId: number;
    isFileUploadInProgress = false;
    isScheduled: boolean = false;
    isEndDateValid: boolean = true;
    isEndTimeValid: boolean = true;
    isStartTimeEndTimeValid: boolean = true;
    minDateTime: any;
    selectedBroadcastIds = new Array<number>();
    selectedPlaylistIds = new Array<number>();
    scheduleStartTime: any;
    scheduleEndTime: any;
    isGroupLoaded: boolean = false;

    get constants() { return Constants; }

    constructor(
        private timeZoneService: TimeZoneService,
        private folderService: FolderService,
        private groupService: GroupService,
        private displayListService: DisplayListService,
        private storageService: StorageService,
        private baseService: BaseService,
        private userMediaAppService: UserMediaAppService,
        private mediaAppService: MediaAppService,
        private appUtils: AppUtils
    ) {

        this.model = new UserMediaAppUpsertModel();
        this.model.clock = new UserMediaAppClockUpsertModel();
    }

    ngOnInit(): void {
        this.model.startDate = new Date();
        this.model.endDate = new Date();
        this.model.mediaAppId = this.mediaAppId;
        this.getMediaAppDetails();
        this.loadGroups();
        this.loadTimeZones();
    }

    getMediaAppDetails() {
        this.mediaAppService.get(this.model.mediaAppId)
            .subscribe({
                next: (response: MediaAppDetailModel) => {
                    this.mediaAppModel = response;
                    this.mediaAppModel.iconUrl = this.generateMediaIcon(this.mediaAppModel.typeString)

                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    generateMediaIcon(mediaAppType: string): string {
        return `./assets/images/media-types/icons/v2/${mediaAppType}.svg`;
    }

    timeFormatChanged(is24HoursFormat: boolean) {
        this.model.clock.is24HoursFormat = is24HoursFormat;
    }

    onPlaylistUrlError(item: DisplayListListItemModel): void {
        item.urlError = true;
    }

    loadDisplayLists() {
        if(!this.isGroupLoaded) {
            return;
        }
        this.displayLists = new Array<SelectListItemModel>();
        this.isLoading = true;
        const requestSubscription = this.displayListService
            .getSelectListItems(this.model.groupId)
            .subscribe({
                next: (data: Array<any>) => {
                    this.isLoading = false;
                    this.displayLists = data;
                    this.updateSelectedDisplayList();
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestSubscription);
    }

    updateSelectedDisplayList() {
        if (this.displayLists.length === 0) {
            return;
        }

        const selectedDisplayLists = new Array<SelectListItemModel>();
        this.model.displayLists.forEach((displayList) => {
            selectedDisplayLists.push(
                this.displayLists.find(
                    (x) => Number(x.keyInt) === Number(displayList.keyInt)
                )
            );
        });

        if (selectedDisplayLists && selectedDisplayLists.length > 0) {
            this.selectedDisplayLists = selectedDisplayLists;
        }
    }

    loadTimeZones() {
        this.isLoading = true;
        this.timeZoneService.getAll().subscribe({
            next: (response: TimeZoneModel[]) => {
                this.timeZoneModel = response;
                this.isTimeZoneLoaded = true;
                this.isLoading = false;
            },
            error: (error) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            },
        });
    }

    autoDetectChanged(isAutoDetect: boolean) {
        this.model.clock.isAutoDetect = isAutoDetect;
        if (isAutoDetect) {
            this.model.clock.timeZone = '';
        } else {
            const offSet = this.appUtils.getUtcOffSet();
            const currentTimeZone = this.timeZoneModel.find(
                (x) => x.baseUtcOffsetMinutes === offSet
            );
            if (currentTimeZone) {
                this.model.clock.timeZone = currentTimeZone.standardName;
            } else {
                console.log('Unable to detect current timezone');
            }
        }
    }

    beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean | Observable<boolean> => {
        // Call your onFileSelected method here
        this.onFileSelected(this.model.clock, { file: { originFileObj: file } }, true);
        return false; // Prevent the default upload behavior
    };

    onFileSelected(clock: UserMediaAppClockUpsertModel, event: any, isAntUpload = false) {
        const uploadedFile = isAntUpload ? event.file?.originFileObj : event.target.files.item(0);

        if (!uploadedFile) {
            return;
        }

        const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
        if (uploadedFile.size > maxSizeInBytes) {
            this.baseService.error('The file size exceeds the maximum limit of 2 MB.');
            this.resetImageFileElement(event);
            return;
        }
        if (uploadedFile.type !== 'image/png' && uploadedFile.type !== 'image/jpeg') {
            this.baseService.error(
              'Your logo does not meet the requirements. You must upload a PNG or JPG image.'
            );
            this.resetImageFileElement(event);
            return;
        }

        clock.file = uploadedFile;

        const img = new Image();
        img.src = URL.createObjectURL(uploadedFile);
        img.onload = () => {
            if (img.width !== 583 || img.height !== 150) {
                this.baseService.error(
                    'Your logo does not meet the requirements. You must upload a PNG image with 583x150 px dimensions.'
                );
                this.resetImageFileElement(event);
                return;
            }
            this.initUpload();

            const fileReader = new FileReader();
            this.isLoading = true;
            fileReader.onload = (fileLoadedEvent: any) => {
                const srcData = fileLoadedEvent.target.result;
                clock.fileUrl = srcData.toString();
                clock.isUploadingImage = false;
                this.isLoading = false;
            };
            fileReader.readAsDataURL(clock.file);
        };
    }

    initUpload() {
        this.isLoading = true;
        this.storageService.getAzureSasToken().subscribe({
            next: async (data) => {
                this.isLoading = false;
                await this.uploadBlob(this.model.clock, data);
            },
            error: (error) => {
                this.isLoading = false;
                this.baseService.error('Unable to upload image file please try again.');
                console.log(error);
            },
        });
    }

    async uploadBlob(
        clock: UserMediaAppClockUpsertModel,
        sasToken: any
    ): Promise<any> {
        this.isLoading = true;
        clock.fileUploadPercent = 1;
        return new Promise((resolve, reject) => {
            this.fileUploadRequest = null;
            const cloudConfig = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: `${environment.azureContainers.shared}`,
            };

            const fileName = `clock/${Guid.create()}.${this.appUtils.getFileExtension(
                clock.file.name
            )}`;
            const blobUrl = `${environment.azureContainerBaseUrl}/${cloudConfig.containerName}/${fileName}`;

            const blobServiceClientUrl = `${environment.azureContainerBaseUrl}?${sasToken}`;
            const blobServiceClient = new BlobServiceClient(blobServiceClientUrl);
            const containerClient = blobServiceClient.getContainerClient(
                cloudConfig.containerName
            );
            const blockBlobClient = containerClient.getBlockBlobClient(fileName);

            blockBlobClient
                .uploadData(clock.file, {
                    blockSize: 262144, // 256KB block size
                    onProgress: (progress) => {
                        clock.fileUploadPercent = Math.round(
                            (progress.loadedBytes / clock.file.size) * 100
                        );
                    },
                })
                .then(() => {
                    Constants.interceptAuthToken = true;
                    clock.fileName = fileName;
                    clock.fileUrl = blobUrl;
                    this.isFileUploadInProgress = false;
                    this.isLoading = false;
                    resolve(true);
                })
                .catch((err) => {
                    Constants.interceptAuthToken = true;
                    this.isFileUploadInProgress = false;
                    this.baseService.error('File upload timeout.');
                    this.isLoading = false;
                    reject(err);
                    console.log(err);
                });
        });
    }

    initRemove() {
        this.isLoading = true;
        this.storageService.getAzureSasToken().subscribe({
            next: async (data: string) => {
                this.isLoading = false;
                await this.removeLogo(data);
                this.model.clock.fileUrl = null;
            },
            error: () => {
                this.isLoading = false;
                this.baseService.error('Unable to remove logo file please try again.');
            }
        });
    }

    resetImageFileElement(event: any) {
        if (event?.target?.files) {
            event.target.value = '';
        } else if (event?.file?.originFileObj) {
            this.model.clock.file = null;
        }
    }

    async removeLogo(sasToken: string) {
        this.isLoading = true;
        await this.deleteBlob(sasToken, this.model.clock.fileName);
        this.model.clock.fileUrl = null;
        this.model.clock.logo = null;
        this.model.clock.fileName = null;
        this.isLoading = false;
    }

    async deleteBlob(sasToken: any, fileKey: string) {
        this.isLoading = true;
        if (this.appUtils.isNullOrEmpty(fileKey)) {
            this.isLoading = false;
            console.log('Logo not found');
            return;
        }
        const blobServiceClient = new BlobServiceClient(`${this.appUtils.generateBlobUrlBase()}?${sasToken}`);
        const containerClient = blobServiceClient.getContainerClient(environment.azureContainers.shared);
        const blockBlobClient = containerClient.getBlockBlobClient(fileKey);
        await blockBlobClient.delete();
        this.isLoading = false;
    }

    backgroundColorChanged(isDefaultBackgroundColor: boolean) {
        this.model.clock.isDefaultBackgroundColor = isDefaultBackgroundColor;
    }

    fontColorChanged(isDefaultFontColor: boolean) {
        this.model.clock.isDefaultFontColor = isDefaultFontColor;
    }

    getFolders(): void {
        this.isLoading = true;
        this.folderService.getList(this.model.groupId, null).subscribe({
            next: (response: Array<SelectListItemModel>) => {
                this.isLoading = false;
                this.folders = response;
            },
            error: (error: any) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            },
        });
    }

    onGroupChange(): void {
        this.getFolders();
    }

    openPlaylistDrawer(): void {
        this.model.displayLists.forEach(x =>
            this.selectedPlaylistIds.push(x.keyInt));
        this.showPlaylists = true;
        this.drawer.open();
    }

    openBroadcastDrawer(): void {
        this.selectedBroadcastIds = this.model.broadcastIds;
        this.showBroadcasts = true;
        this.drawer.open();
    }

    cancel(): void {
        this.closeDrawer.emit({ mediaId: 0, mediaAdded: false });
    }

    close(): void {
        this.visible = false;
    }

    loadGroups(): void {
        this.groupService.getList().subscribe({
            next: (response: Array<GroupDetailModel>) => {
                this.isLoading = true;
                this.groups = new Array<GroupDetailModel>();
                this.groups = response;

                this.subscriptionPlans = new Array<SelectListItemModel>();
                let subscriptionPlan = new SelectListItemModel();
                subscriptionPlan.keyInt = -1;
                subscriptionPlan.value = `My Subscription (${this.appUtils.getUserName()})`;
                this.subscriptionPlans.push(subscriptionPlan);

                this.groups.forEach((group) => {
                    subscriptionPlan = new SelectListItemModel();
                    subscriptionPlan.keyInt = group.id;
                    subscriptionPlan.value = `${group.name} (${group.ownerName})`;
                    this.subscriptionPlans.push(subscriptionPlan);
                });

                if (this.selectedGroupId != undefined && this.selectedGroupId > 0) {
                    this.model.groupId = this.selectedGroupId;
                }
                else {
                    this.selectedGroupId = -1;
                    this.model.groupId = -1;
                }

                this.isGroupLoaded = true;
                this.isLoading = false;
                this.getFolders();
                this.loadDisplayLists();
            },
            error: (error: any) => {
                this.baseService.processErrorResponse(error);
            },
        });
    }

    onSchedule() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.scheduleStartTime = null;
        this.scheduleEndTime = null;
    }

    scheduleTypeChanged(isDaily: boolean) {
        this.model.isDaily = isDaily;
        this.model.startDate = null;
        this.model.endDate = null;
    }

    disableStartDate = (current: Date): boolean => {
        return current && current < new Date(new Date().setHours(0, 0, 0, 0));
    };

    disableEndDate = (current: Date): boolean => {
        if (!this.model.startDate) {
            return current && current < new Date(new Date().setHours(0, 0, 0, 0));
        }
        return current && current < new Date(this.model.startDate);
    };

    isTimeValid(time: any): void {
        const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
        const endTime = moment(time, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    isStartTimeValid(time: any): void {
        const startTime = moment(time, 'hh:mm A').set({ s: 0 });
        const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    validateScheduleDetails(): boolean {
        if (!this.model.isScheduled) {
            this.model.startTime = null;
            this.model.endTime = null;
            this.model.startDate = null;
            this.model.endDate = null;
            return true;
        }

        if (this.scheduleStartTime && this.scheduleEndTime) {
            if (!this.isStartTimeEndTimeValid) {
                return false;
            }

            const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
            const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
            if (
                !moment(endTime).isAfter(moment(startTime)) &&
                startTime.format('a') === endTime.format('a')
            ) {
                this.isEndTimeValid = false;
                return false;
            }
            this.model.startTime = this.appUtils.getFormattedTime(
                startTime.toString(),
                null
            );
            this.model.endTime = this.appUtils.getFormattedTime(
                endTime.toString(),
                null
            );

            if (this.model.isDaily) {
                this.model.startDate = null;
                this.model.endDate = null;
                return true;
            }
            this.model.startDate = this.appUtils.getFormattedDate(
                this.model.startDate,
                null
            );
            this.model.endDate = this.appUtils.getFormattedDate(
                this.model.endDate,
                null
            );
            if (moment(this.model.endDate).isBefore(this.model.startDate)) {
                this.isEndDateValid = false;
                return false;
            }
            this.isEndDateValid = true;
            return true;
        } else {
            return false;
        }
    }

    isDisabledStartDate(date: Date): boolean {
        return date <= new Date(); // Disable dates before today
    }

    isDisabledEndDate(date: Date): boolean {
        const selectedStartDate = this.model.startDate;
        return !selectedStartDate || date < selectedStartDate; // Disable dates before start date (or if start date is not selected)
    }

    removeDisplayList(item: SelectListItemModel) {
        const tempArr = [];
        this.selectedDisplayLists.forEach((displayList) => {
            tempArr.push(displayList);
        });
        const itemIndex = tempArr.indexOf(item);
        tempArr.splice(itemIndex, 1);
        this.selectedDisplayLists = tempArr;
    }

    submit(): void {
        this.isLoading = true;
        if (this.model.groupId === '' || this.model.groupId == -1) {
            this.model.groupId = null;
        }

        if (this.model.clock.timeZone) {
            this.model.clock.baseUtcOffsetTicks = this.timeZoneModel.find(
                (x) => x.standardName === this.model.clock.timeZone
            ).baseUtcOffsetTicks;
        }

        this.model.clock.logo = this.model.clock.fileName;
        this.model.clock.logoUrl = this.model.clock.fileUrl;

        this.model.displayLists = new Array<SelectListItemModel>();
        if (this.selectedDisplayLists && this.selectedDisplayLists.length > 0) {
            this.selectedDisplayLists.forEach((item) => {
                let selectedDisplayListModel = new SelectListItemModel();
                selectedDisplayListModel.keyInt = item.keyInt;
                selectedDisplayListModel.value = item.value;
                this.model.displayLists.push(selectedDisplayListModel);
            });
            this.model.lengthInSeconds =
                this.selectedDisplayLists.length === 1
                    ? this.model.lengthInSeconds
                    : '0';
        }

        this.model.isScheduled = this.isScheduled;
        if (!this.validateScheduleDetails()) {
            this.isScheduled = true;
            this.isLoading = false;
            return;
        }
        this.model.id = 0;
        this.userMediaAppService.add(this.model).subscribe({
            next: (id: number) => {
                this.isLoading = false;
                this.baseService.successNotification('Media added successfully.');
                this.closeDrawer.emit({ mediaId: id, mediaAdded: true });
            },
            error: (error: any) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            }
        });
    }
}
