<div class="toolbar">
    <img class="brand-logo" src="./assets/images/logodark.png" alt="">
</div>

<section *ngIf="isModelLoaded && existingUser && !invitationExists && groupExists">
    <div class="text-container">
        <div nz-col nzSpan="24" class="text-center">
            <div>
                <img class="lightmode_img" src="./assets/images/dashboard-icons/no-screen.svg" alt="">
                <img class="darkmode_img" src="./assets/images/dashboard-icons/no-screen-darkmode.svg" alt="">
            </div>
            <div class="mt-3 heading-fs-18">It seems like the links is expired or the request has been cancelled.</div>
            <div class="mt-4">
                <button nz-button nzType="primary" *ngIf="loggedInUser" [routerLink]="['/group/manage']">Go to the
                    groups</button>
                <button nz-button nzType="primary" *ngIf="!loggedInUser" (click)="goToGroups()">Go to the
                    groups</button>
            </div>
        </div>
    </div>
</section>

<section *ngIf="isModelLoaded && !this.existingUser && !invitationExists && groupExists && !linkOrInvitationExpired">
    <div class="text-container">
        <div nz-col nzSpan="24" class="text-center">
            <div>
                <img class="lightmode_img" src="./assets/images/dashboard-icons/no-screen.svg" alt="">
                <img class="darkmode_img" src="./assets/images/dashboard-icons/no-screen-darkmode.svg" alt="">
            </div>
            <div class="mt-3 heading-fs-18">It seems like the links is expired or the request has been cancelled.</div>

            <div class="mt-3 fw-bold text-muted" *ngIf="!loggedInUser">Please go to the manager.displaynow.com and
                create your account.</div>

            <div class="mt-4">
                <button nz-button nzType="primary" type="button" *ngIf="!loggedInUser"
                    [routerLink]="['/account/signup']">Create
                    account</button>
                <button nz-button nzType="primary" type="button" *ngIf="loggedInUser"
                    [routerLink]="['/group/manage']">Go to groups
                </button>
            </div>
        </div>
    </div>
</section>

<section *ngIf="isModelLoaded && !this.existingUser  && linkOrInvitationExpired">
    <div class="text-container">
        <div nz-col nzSpan="24" class="text-center">
            <div>
                <img src="./assets/images/Link_Expired.svg" alt="">
            </div>
            <div class="mt-3 heading-fs-18">Link is expired!</div>
            <div class="mt-3 fw-bold">The link you followed is no longer valid.</div>
            <div class="fw-bold" *ngIf="!loggedInUser">Please go to the manager.displaynow.com and create your account.
            </div>

            <div class="mt-4">
                <button nz-button nzType="primary" type="button" *ngIf="!loggedInUser"
                    [routerLink]="['/account/signup']">Create
                    account</button>
                <button nz-button nzType="primary" type="button" *ngIf="loggedInUser"
                    [routerLink]="['/group/manage']">Go to Groups</button>
            </div>
        </div>
    </div>
</section>


<section *ngIf="isModelLoaded && existingUser && !groupExists">
    <div class="text-container">
        <div nz-col nzSpan="24" class="text-center">
            <div>
                <img class="lightmode_img" src="./assets/images/dashboard-icons/no-screen.svg" alt="">
                <img class="darkmode_img" src="./assets/images/dashboard-icons/no-screen-darkmode.svg" alt="">
            </div>
            <div class="mt-3 heading-fs-18">It seems like the group has been deleted by the owner</div>

            <div class="mt-4">
                <button nz-button nzType="primary" *ngIf="loggedInUser" [routerLink]="['/group/manage']">Go to the
                    groups</button>
                <button nz-button nzType="primary" *ngIf="!loggedInUser" (click)="goToGroups()">Go to the
                    groups</button>
            </div>
        </div>
    </div>
</section>

<section *ngIf="isModelLoaded && !this.existingUser && !groupExists">
    <div class="text-container">
        <div nz-col nzSpan="24" class="text-center">
            <div>
                <img class="lightmode_img" src="./assets/images/dashboard-icons/no-screen.svg" alt="">
                <img class="darkmode_img" src="./assets/images/dashboard-icons/no-screen-darkmode.svg" alt="">
            </div>
            <div class="mt-3 heading-fs-18">It seems like the group has been deleted by the owner.</div>

            <div class="mt-3 fw-bold text-muted" *ngIf="!loggedInUser">Please go to the manager.displaynow.com and
                create your account.</div>

            <div class="mt-4">
                <button nz-button nzType="primary" type="button" *ngIf="!loggedInUser"
                    [routerLink]="['/account/signup']">Create
                    account</button>
                <button nz-button nzType="primary" type="button" *ngIf="loggedInUser"
                    [routerLink]="['/group/manage']">Go to Groups</button>
            </div>
        </div>
    </div>
</section>

<section *ngIf="!isModelLoaded">
    <nz-spin [nzSpinning]="isLoading">
        <div class="text-container heading-fs-18"> Please wait!</div>
    </nz-spin>
</section>