import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Constants } from "../../../../helpers/app.constants";
import { DomSanitizer, SafeHtml, SafeResourceUrl } from "@angular/platform-browser";
import { PageContentDetailModel } from "../../../../models";

@Component({
 selector:'app-help',
 templateUrl:'./help.component.html',
 styleUrls: ['./help.component.scss']
})

export class HelpComponent implements OnInit, OnChanges{
    @Input() model: PageContentDetailModel;

    helpVideo: SafeHtml;
    isVisible = false;

    get constants() { return Constants; }

    constructor(
        private sanitizer: DomSanitizer,
    ) {}

    ngOnChanges() {
        this.helpVideo = this.sanitizeHtml(this.model.content);
    }

    ngOnInit(): void {
        this.helpVideo = this.sanitizeHtml(this.model.content);
    }

    sanitizeHtml(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustHtml(url);
    }

    showModal(): void {
        this.isVisible = true;
    }

    closeModal(): void {
        this.isVisible = false;
    }
}