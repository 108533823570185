import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DisplayListModel, ListItemsFilterModel, UserMediaAppEditModel } from "../models";
import { FilterModel } from "src/models";

@Injectable()
export class DisplayListService {
    private apiEndPoint = environment.apiBaseUrl + 'api/displayList';

    constructor(private http: HttpClient) { }

    getList(groupId?: number, model?: FilterModel): Observable<any> {
        if (!groupId || groupId === -1) {
            groupId = null;
        }

        if(model == null || model == undefined){
            return this.http.get(this.apiEndPoint + '/list/' + groupId);
        }
        if (model.offset == undefined) {
            model.offset = null;
        }
        if (model.limit == undefined) {
            model.limit = null;
        }
        if (model.filterKey == undefined || model.filterKey == null) {
            model.filterKey = '';
        }

        return this.http.get(this.apiEndPoint + '/list/' + groupId+'?' + model.toQueryString());

    }

    add(model: DisplayListModel): Observable<any> {
        return this.http.post(`${this.apiEndPoint}/add`, model);
    }

    edit(model: DisplayListModel): Observable<any> {
        return this.http.post(`${this.apiEndPoint}/edit`, model);
    }

    getSelectListItems(groupId: any) {
        if (!groupId || groupId === -1) {
            groupId = null;
        }
        return this.http.get(this.apiEndPoint + '/get-select-list-items/' + groupId);
    }

    getForEdit(id: number) {
        return this.http.get(this.apiEndPoint + '/get-for-edit/' + id);
    }

    editUserMedia(model: UserMediaAppEditModel) {
        return this.http.post(`${this.apiEndPoint}/edit-user-media-apps`, model);
    }

}