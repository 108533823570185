export class UserMediaAppSummaryModel {
    public id: number;
    public mediaAppType: string;
    public mediaAppTitle: string;
    public title: string;
    public lengthInSeconds: number;
    public displayOrder: number;
    public status: number;
    public isScheduled: boolean;
    public startTime: any;
    public startDate: any;
    public endTime: any;
    public endDate: any;
    public videoEncodingStatus: number;
    public iconUrl: string;
    public isSelected: boolean;
    public urlError: any;
    public showDefaultIcon: boolean;
    public isActive: boolean;
    public createdOn: string;
    public isEnabled: boolean;
    public url: string;
    public groupId: number;

    constructor() {
        this.isActive = false;
    }

}
