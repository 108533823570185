import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()

export class MediaService {
    private apiBaseUrl = environment.apiBaseUrl + 'api/media';

    constructor(private http: HttpClient){}

    getList(): Observable<any> {
        return this.http.get(`${this.apiBaseUrl}/select-list-item`);
    }
}