<section>
    <div class="scroll-container" #scrollContainer (scroll)="onScroll($event)">
        <div nz-row>
            <div nz-col nzSpan="18">
                <div class="heading-fs-18 mb-0">
                    <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
                        (click)="cancel()"></span>
                    All Broadcasts
                </div>
            </div>
            <div nz-col nzSpan="6" class="text-right pt-2 disp-web">
                <span nz-icon nzType="close" nzTheme="outline" class="cursor-pointer" (click)="cancel()"></span>
            </div>
            <div nz-col nzSpan="24">
                <nz-divider></nz-divider>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="24">
                <form nz-form>
                    <div class="d-flex">
                        <div class="w-100">
                            <div class="input-container mt-2">
                                <nz-form-item class="mb-0">
                                    <nz-form-control class="drawer-search-input">
                                        <nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="suffixIcon">
                                            <input nz-input placeholder="Search Broadcast" name="filterKey" [disabled]="!hasBroadcastList"
                                                [(ngModel)]="filterModel.filterKey" (keydown.enter)="onSearch()"
                                                (ngModelChange)="filterKeyChanged()" [maxlength]="100" [disabled]="!hasBroadcastList" appAlphaNumericsLevelThree/>
                                        </nz-input-group>
                                        <ng-template #prefixIcon>
                                            <span nz-icon nzType="search" nzTheme="outline" (click)="onSearch()"
                                                class="cursor-pointer"></span>
                                        </ng-template>
                                        <ng-template #suffixIcon>
                                            <span nz-icon nzType="close" nzTheme="outline" *ngIf="filterModel.filterKey"
                                                (click)="clearSearch()" class="clear-search cursor-pointer"></span>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <!-- <div class="text-right mt-1 ml-3">
                        <button nz-button nzType="primary" class="mt-2" (click)="openBroadcastAddDrawer()">
                            <span nz-icon nzType="plus" nzTheme="outline"></span>Add New
                        </button>
                    </div> -->
                    </div>
                </form>
            </div>
        </div>

        <form (ngSubmit)="submit()">
            <div nz-col nzSpan="24">
                <div nz-row class="mt-2">
                    <div nz-col nzSpan="8" class="mt-1">
                        <label nz-checkbox [(ngModel)]="allBroadcastSelected" name="selectAll"
                            (ngModelChange)="selectAllBroadcast($event)"
                            *ngIf="isModelLoaded && hasBroadcastList && model.length > 0"
                            class="align-center selected-square">Select all</label>
                    </div>
                    <div nz-col nzSpan="16">
                        <div class="d-flex justify-content-end cursor-pointer">
                            <nz-form-item class="sort-by-dropdown"
                                *ngIf="isModelLoaded && hasBroadcastList && model.length > 0">
                                <nz-form-control>
                                    <nz-select nzPlaceHolder="Sort By" [(ngModel)]="sortOrder"
                                        (ngModelChange)="sortBroadcast()" name="sortBroadcast">
                                        <nz-option [nzValue]="'ascending'" [nzLabel]="'Ascending'"></nz-option>
                                        <nz-option [nzValue]="'descending'" [nzLabel]="'Descending'"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                            <div class="align-center disp-web ml-3"
                                *ngIf="isModelLoaded && hasBroadcastList && model.length > 0">
                                <button type="submit" nz-button (click)="sendBroadcast()"
                                    [ngClass]="{'selected-button': isBroadcastSelected}"
                                    [disabled]="!isBroadcastSelected">Send to
                                    Media</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <nz-spin [nzSpinning]="isLoading" class="mobOne-action-m-b">
                <div *ngIf="isModelLoaded && model && model.length > 0">
                    <div nz-row *ngFor="let item of model" [nzGutter]="[16,16]"
                        class="list-item mb-2 pt-1 cursor-pointer"
                        (click)="item.isSelected = !item.isSelected; toggleBroadcast()">
                        <div nz-col nzSpan="16">
                            <div class="d-flex cursor-pointer">
                                <div class="align-items-center mr-3">
                                    <label nz-checkbox [(ngModel)]="item.isSelected" (ngModelChange)="toggleBroadcast()"
                                        name="selectBroadcast{{item.name}}" #selectBroadcast="ngModel"></label>
                                </div>
                                <div class="d-flex align-items-center overflow-hidden">
                                    <div class="mr-3"
                                        [ngClass]="{'list-img-wrapper': !item.urlError, 'list-default-wrapper': item.urlError}">
                                        <img *ngIf="!item.urlError" [src]="item.defaultUserMediaAppUrl" alt=""
                                            (error)="onError(item)" [ngClass]="{'hidden': item.urlError}">
                                    </div>
                                    <div class="text-ellipsis">{{item.name}}
                                        <div class="fs-12 text-primary" *ngIf="item.id == newBroadcastId">New</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div nz-col nzSpan="8" class="align-items-center">

                            <span nz-icon nzType="close-circle" nzTheme="fill" *ngIf="!item.isEnabled"
                                class="status-icon-danger mr-2"></span>

                            <span nz-icon nzType="check-circle" nzTheme="fill"
                                *ngIf="item.isEnabled && item.broadcastStatusName == 'Broadcasting'"
                                class="status-icon-success mr-2"></span>

                            <span nz-icon nzType="minus-circle" nzTheme="fill"
                                *ngIf="item.isEnabled && item.broadcastStatusName == 'Expired'"
                                class="status-icon-danger mr-2"></span>

                            <span nz-icon nzType="clock-circle" nzTheme="fill"
                                *ngIf="item.isEnabled && item.broadcastStatusName == 'Scheduled'"
                                class="status-icon-primary mr-2"></span>

                            <span nz-icon nzType="clock-circle" nzTheme="fill"
                                *ngIf="item.isEnabled && item.broadcastStatusName == 'Triggered'"
                                class="status-icon-scheduled mr-2"></span>

                            <span *ngIf="!item.isEnabled">In-active</span>
                            <span *ngIf="item.isEnabled">{{item.broadcastStatusName}}</span>
                        </div>
                        <nz-divider class="mt-1 mb-0"></nz-divider>
                    </div>
                </div>
                <div *ngIf="isModelLoaded && model && model.length == 0 && hasBroadcastList"
                    class="empty-list-container">
                    <span class="text-muted fs-14">No broadcast match your search</span>
                </div>
                <div *ngIf="isModelLoaded && !hasBroadcastList" class="empty-list-container">
                    <span class="text-muted fs-14">No broadcast found</span>
                </div>

                <!-- <div *ngIf="loadingMore" class="loading-more-container">
                    <nz-spin></nz-spin>
                </div> -->
            </nz-spin>
        </form>
        <nz-drawer #drawer [nzClosable]="false" [nzVisible]="isDrawerVisisble" nzPlacement="right" (nzOnClose)="close()"
            [nzMask]="false" class="custom-drawer">
            <ng-container *nzDrawerContent>
                <app-broadcast-add (closeDrawer)="closeBroadcastAddDrawer($event)" [selectedGroupId]="selectedGroupId"
                    *ngIf="showBroadcastAddDrawer"></app-broadcast-add>
            </ng-container>
        </nz-drawer>

        <div nz-row class="disp-mob mobOne-action-bar">
            <div nz-col nzSpan="24" class="text-right">
                <nz-card>
                    <div nz-row [nzGutter]="[16]">
                        <div nz-col nzSpan="24">
                            <button type="submit" class="btn-full" nz-button (click)="sendBroadcast()"
                                [ngClass]="{'selected-button': isBroadcastSelected}"
                                [disabled]="!isBroadcastSelected">Send to
                                Media</button>
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>
    </div>
</section>