import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseService } from 'src/app/shared/services';
import { PageContentService } from 'src/services';
import { PageContentDetailModel } from 'src/models';

@Component({
    selector: 'app-content-text-view',
    templateUrl: './content-text-view.component.html',
    styleUrls: ['./content-text-view.component.scss']
})

export class ContentTextViewComponent implements OnInit {
    @Input() contentType: string;
    content: any;

    constructor(private pageContentService: PageContentService,
        private baseService: BaseService, private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.loadPageContent();
    }

    changePageContent(contentType: string) {
        this.contentType = contentType;
        this.loadPageContent();
    }

    loadPageContent() {
        this.pageContentService.getByType(this.contentType)
            .subscribe({
                next: (data: PageContentDetailModel) => {
                    if (data) {
                        const text = data.content;
                        this.content = this.sanitizer.bypassSecurityTrustHtml(text);
                    }
                },
                error: error => {
                    this.baseService.processErrorResponse(error);
                }
            });
    }
}
