import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PageContentService {

    private apiEndPoint = environment.apiBaseUrl + 'api/pageContent/';

    constructor(private http: HttpClient) { }

    getByType(pageContentType: string) {
        return this.http.get(this.apiEndPoint + 'get-by-type/' + pageContentType);
    }
}
