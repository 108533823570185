import { UserMediaAppWebPageUpsertModel } from "./user-media-app-webpage-upsert.model";
import { BroadcastListItemModel, DisplayListListItemModel, SelectListItemModel } from "src/app/shared/models";
import { UserMediaAppClockUpsertModel } from "./user-media-app-clock-upsert.model";
import { UserMediaAppYouTubeUpsertModel } from "./user-media-app-youtube-upsert.model";
import { UserMediaAppWeatherUpsertModel } from "./user-media-app-weather-upsert.model";
import { UserMediaAppImageUpsertModel } from "./user-media-app-image-upsert.model";
import { UserMediaAppVimeoUpsertModel } from "./user-media-app-vimeo-upsert.model";
import { UserMediaAppPowerPointUpsertModel } from "./user-media-app-power-point-upsert.model";
import { UserMediaAppGoogleSlideUpsertModel } from "./user-media-app-google-slide-upsert.model";
import { UserMediaAppPdfUpsertModel } from "./user-media-app-pdf-upsert.model";
import { UserMediaAppVideoUpsertModel } from "./user-media-app-video-upsert.model";
import { UserMediaAppCanvaUpsertModel } from "./user-media-app-canva-upsert.model";


export class UserMediaAppUpsertModel{
    id: number;
    mediaAppId: number;
    type: number;
    //type: string;
    title: string;
    subtitle: string;
    description: string;
    groupId: any;
    groupName: string;
    groupOwnerId: string;
    lengthInSeconds: string;
    folderId: number;
    startDate: any;
    startTime: any;
    endDate: any;
    endTime: any;
    isDaily: boolean;
    isScheduled: boolean;
    hasError: boolean;
    errorMessage: string;
    status: number;
    updatedOn: string;

    broadcastIds: Array<number>;

    playlists = new Array<DisplayListListItemModel>();
    broadcasts = new Array<BroadcastListItemModel>();

    displayLists: Array<SelectListItemModel>;

    public webPage: UserMediaAppWebPageUpsertModel;

    public clock: UserMediaAppClockUpsertModel;
    public youTube: UserMediaAppYouTubeUpsertModel;
    public weather: UserMediaAppWeatherUpsertModel;
    public canva: UserMediaAppCanvaUpsertModel;
    public image: UserMediaAppImageUpsertModel;
    public vimeo: UserMediaAppVimeoUpsertModel;
    public powerPoint: UserMediaAppPowerPointUpsertModel;
    public googleSlide: UserMediaAppGoogleSlideUpsertModel;
    public pdf: UserMediaAppPdfUpsertModel;
    public video: UserMediaAppVideoUpsertModel;

    constructor() {
        this.displayLists = new Array<SelectListItemModel>();
        this.broadcastIds = new Array<number>();
        this.lengthInSeconds = '10';
        this.isDaily = true;
        this.startDate = null;
        this.startTime = null;
        this.endDate = null;
        this.endTime = null;
        this.isScheduled = false;
    }
}
