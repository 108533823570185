import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FilterModel, SelectListItemModel } from "src/models";
import { UserMediaAppUpsertModel } from "../models/user-media-app/user-media-app-upsert.model";
import { AppUtils } from "src/helpers";
import { ListItemsFilterModel } from "../models";

@Injectable()
export class UserMediaAppService {
    private apiEndPoint = environment.apiBaseUrl + 'api/userMediaApp';

    constructor(private http: HttpClient) { }

    exists(groupId?: number): Observable<any> {
        if (!groupId || groupId === -1) {
            groupId = null;
        }
        return this.http.get(`${this.apiEndPoint}/exists/${groupId}`);
    }

    getSelectListItems(groupId?: number): Observable<Array<SelectListItemModel>> {
        if (!groupId || groupId === -1) {
            groupId = null;
        }
        return this.http.get<Array<SelectListItemModel>>(`${this.apiEndPoint}/get-select-list-items/${groupId}`);
    }

    getPagedResult(model: FilterModel) {
        if (!model.groupId || model.groupId === -1) {
            model.groupId = null;
        }
        return this.http.get(this.apiEndPoint + '/paged-result?' + model.toQueryString());
    }

    delete(id: number) {
        return this.http.post(this.apiEndPoint + '/delete/' + id, null);
    }

    getPagedList(model: FilterModel) {
        if (!model.groupId || model.groupId === -1) {
            model.groupId = null;
        }
        return this.http.get(this.apiEndPoint + '/paged-list?' + model.toQueryString());
    }

    getList(groupId?: number, filterModel?: FilterModel): Observable<Array<any>> {
        console.log("filterModel test = ", filterModel);
        if (!groupId || groupId === -1) {
            groupId = null;
        }

        if (filterModel == null || filterModel == undefined) {
            return this.http.get<Array<any>>(this.apiEndPoint + '/list-items/' + groupId);
        }

        return this.http.get<Array<any>>(this.apiEndPoint + '/list-items/' + groupId + '?' + filterModel.toQueryString());
    }

    getForEdit(id: number) {
        return this.http.get(this.apiEndPoint + '/get-for-edit/' + id);
    }

    listSummary(groupId: any) {
        if (!groupId || groupId === -1) {
            groupId = null;
        }
        return this.http.get(this.apiEndPoint + '/list-summary/' + groupId);
    }

    getDuration(id: number, displayListId: number) {
        const requestUrl = `${this.apiEndPoint}/get-duration?id=${id}&displayListId=${displayListId}`;
        return this.http.get(requestUrl);
    }

    edit(model: UserMediaAppUpsertModel) {
        return this.http.post(this.apiEndPoint + '/edit', model);
    }

    add(model: UserMediaAppUpsertModel) {
        return this.http.post(this.apiEndPoint + '/add', model);
    }

    getYoutubeChannelsSnippet(channelId: string) {
        return this.http
            .get(`${environment.googleApi.youtubeSearchBaseUrl}/channels?key=${environment.googleApi.key
                }&part=snippet&id=${channelId}`);
    }

    getYoutubeVideoStatistics(videoId: string) {
        return this.http
            .get(`${environment.googleApi.youtubeSearchBaseUrl}/videos?key=${environment.googleApi.key
                }&part=statistics&id=${videoId}`);
    }

    getYoutubeVideos(query: string, pageToken: string = '') {
        return this.http
            .get(`${environment.googleApi.youtubeSearchBaseUrl}/search?q=${query
                }&key=${environment.googleApi.key
                }&part=snippet&videoEmbeddable=true&type=video&maxResults=${environment.googleApi.youtubeSearchMaxResult
                }&pageToken=${pageToken}`);
    }
}
