export class UserMediaAppWebPageUpsertModel {
    public id: string;
    public userMediaAppId: number;
    public isEnabled: boolean;
    public displayOrder: number;
    public temperatureFormat: number;
    public showPromotionalText: boolean;
    public detectLocationAutomatically: boolean;
    public city: string;
    public latitude: number;
    public longitude: number;
    public url: string;

    constructor() {
        this.isEnabled = true;
        this.showPromotionalText = false;
        this.temperatureFormat = 1;
        this.displayOrder = 0;
        this.detectLocationAutomatically = true;
        this.url = '';
    }
}