export class BroadcastRecurringModel {
    recurringType: number;
    separationCount: number;
    maxNumOfOccurrence: string;
    endDate: any;
    days: Array<number>;

    isAfterOccurrence: boolean;

    constructor() {
        this.separationCount = 1;
        this.isAfterOccurrence = false;
        this.recurringType = null;
        this.days = new Array<number>();
    }
}