import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AppUtils } from "src/helpers";
import { BroadcastListItemModel, FilterModel, ListItemsFilterModel, MediaAppListItemModel } from "src/app/shared/models";
import { BaseService, BroadcastService } from "src/app/shared/services";
import { NzDrawerComponent } from "ng-zorro-antd/drawer";
import { Subscription } from "rxjs";
import { ListenerService } from "src/services";

@Component({
    selector: 'app-broadcast-list',
    templateUrl: './broadcast-list.component.html',
    styleUrls: ['./broadcast-list.component.scss']
})

export class BroadcastListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
    @Input() selectedGroupId: number;
    @Input() medias: Array<MediaAppListItemModel> = new Array<MediaAppListItemModel>();
    @Output() closeDrawer: EventEmitter<{ selectedBroadcast: BroadcastListItemModel, updateValue: boolean }> = new EventEmitter<{ selectedBroadcast: BroadcastListItemModel, updateValue: boolean }>();
    isModelLoaded: boolean;
    isLoading = true;
    newBroadcastId = 0;
    filterKey: string;
    groupId: number;
    model: Array<BroadcastListItemModel>;
    hasBroadcastList: boolean = true;
    sortOrder: string;
    showBroadcastAddDrawer = false;
    isDrawerVisisble = false;
    subscriptions = new Array<Subscription>();
    totalRecords: number = 0;
    recordsToSkip: number;
    pageSize: number;
    loadingMore = false;
    hasMore = true;
    filterModel = new FilterModel();

    constructor(private baseService: BaseService,
        private appUtils: AppUtils,
        private service: BroadcastService,
        private messageService: ListenerService) {
        this.model = new Array<BroadcastListItemModel>();
        const reloadBroadcastDataSubscription = this.messageService
            .listenReloadBroadcastData.subscribe((broadcastIds: Array<number>) => {
                this.handleReloadBroadcast(broadcastIds);
            });

        this.subscriptions.push(reloadBroadcastDataSubscription);

        if (this.selectedGroupId == undefined) {
            this.groupId == null;
        }
        else {
            this.groupId = this.selectedGroupId
        }

        this.filterModel.sortBy = 'name';
        this.filterModel.sortDir = 'asc';
    }

    ngOnInit() {
        this.getBroadcastList(true);
    }

    ngAfterViewInit() {
        this.scrollContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }

    ngOnChanges() {
        if (this.selectedGroupId == undefined) {
            this.groupId == null;
        }
        else {
            this.groupId = this.selectedGroupId;
        }
    }

    onScroll(event: any) {
        const element = this.scrollContainer.nativeElement;
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight * 0.8 && this.hasMore && !this.loadingMore) {
            this.getMoreRecords();
        }
    }

    getBroadcastList(initialLoading = false, limit = 15): void {
        this.isModelLoaded = false;
        this.isLoading = true;
        this.filterModel.limit = limit;
        this.filterModel.offset = 0;
        this.totalRecords = 0;
        this.service.getList(this.groupId, this.filterModel)
            .subscribe({
                next: (response: Array<BroadcastListItemModel>) => {
                    this.model = new Array<BroadcastListItemModel>();
                    this.model = response;
                    this.totalRecords = response.length;
                    if (this.model.length > 0) {
                        this.model.forEach((x) => {
                            x.defaultUserMediaAppUrl = this.getMediaIconUrl(x.defaultUserMediaAppType);
                            x.showDefaultIcon = true;
                        });
                    }
                    if (initialLoading) {
                        this.hasBroadcastList = this.model.length > 0;
                    }

                    this.isModelLoaded = true;
                    this.isLoading = false;
                },
                error: (err) => {
                    this.isLoading = false;
                    this.isModelLoaded = true;
                    this.baseService.processErrorResponse(err);
                }
            });
    }


    generateUrl(defaultMediaAppType: string): string {
        return `./assets/images/media-types/${defaultMediaAppType}.svg`
    }

    getMediaIconUrl(mediaType: string): string {
        const media = this.medias.find(x => x.id == mediaType);
        if (media != undefined) {
            return media.iconUrl;
        }
        return '';
    }

    onSearch() {
        const filterKey = this.filterModel.filterKey.toLowerCase();
        this.model = this.model.filter(playlist =>
            playlist.name.toLowerCase().includes(filterKey)
        );
    }

    clearSearch() {
        this.filterModel.filterKey = '';
        this.getBroadcastList();
    }

    sortBroadcast() {
        if (this.sortOrder === 'ascending') {
            this.filterModel.sortBy = 'name';
            this.filterModel.sortDir = 'asc';
        } else if (this.sortOrder === 'descending') {
            this.filterModel.sortBy = 'name';
            this.filterModel.sortDir = 'desc';
        }

        this.getBroadcastList(false, this.totalRecords);
    }

    selectBroadcast(item: BroadcastListItemModel): void {
        this.closeDrawer.emit({ selectedBroadcast: item, updateValue: true });
    }

    cancel(): void {
        this.closeDrawer.emit({ selectedBroadcast: new BroadcastListItemModel(), updateValue: false });
    }

    onError(item: BroadcastListItemModel): void {
        item.urlError = true;
    }

    filterKeyChanged() {
        if (this.appUtils.isNullOrEmpty(this.filterModel.filterKey)) {
            this.getBroadcastList();
        }
    }

    close(): void {
        this.isDrawerVisisble = false;
    }

    openBroadcastAddDrawer(): void {
        this.showBroadcastAddDrawer = true;
        this.drawer.open();
    }

    closeBroadcastAddDrawer(event: any): void {
        this.drawer.close();
        this.showBroadcastAddDrawer = false;
        if (event.broadcastAdded) {
            this.newBroadcastId = event.broadcastId;
            this.getBroadcastList(true);
        }
    }

    handleReloadBroadcast(broadcastIds: Array<number>) {
        if (!broadcastIds || broadcastIds.length === 0) {
            console.log('No broadcast found to reload');
            return;
        }

        if (this.model.filter(x => broadcastIds.indexOf(x.id) !== -1).length === 0) {
            console.log('No broadcast loaded in current screen');
        }
        // reload broadcast
        this.getBroadcastList();
    }

    getMoreRecords() {
        if (this.loadingMore) { return; }
        this.isLoading = true;
        this.loadingMore = true;
        this.filterModel.limit = 5;
        this.filterModel.offset = this.totalRecords;
        this.service.getList(this.groupId, this.filterModel)
            .subscribe({
                next: (response: Array<BroadcastListItemModel>) => {
                    if (response && response.length > 0) {
                        response.forEach((x) => {
                            x.defaultUserMediaAppUrl = this.getMediaIconUrl(x.defaultUserMediaAppType);
                            x.showDefaultIcon = true;
                            this.model.push(x);
                        });
                        this.totalRecords += response.length;
                    }
                    else {
                        this.hasMore = false;
                    }
                    this.isLoading = false;
                    this.loadingMore = false;
                },
                error: (err) => {
                    this.isLoading = false;
                    this.loadingMore = false;
                    this.baseService.processErrorResponse(err);
                }
            });
    }


    ngOnDestroy(): void {
        this.isLoading = false;
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }
}