import { BroadcastRecurringModel } from "./broadcast-recurring.model";
import { MediaListItemModel } from "./media-list-item.model";
import { SelectListItemModel } from "./select-list-item.model";

export class BroadcastEditModel {
    public id: number;
    public userMediaAppId: number;
    public name: string;
    public startDateType: number;
    public startDate: any;
    public startTime: any;
    public endDateType: number;
    public endDate: any;
    public endTime: any;
    public timeZone: string;
    public isAutoDelete: boolean;
    public isRecurring: boolean;
    public isEnabled: boolean;

    public groupId: number;
    public folderId: number;
    public groupName: string;

    public displayIds: Array<number>;
    public displays: Array<SelectListItemModel>;

    public recurring: BroadcastRecurringModel;

    public startDateTime: any;
    public isStartDateValid: any;
    public resetStartDate: boolean;
    public endDateTime: any;
    public resetEndDate: boolean;
    public isEndDateValid: any;
    public prevStartDateTime: any;
    public userMediaApp: MediaListItemModel

    constructor() {
        this.timeZone = '';
        this.displayIds = new Array<number>();
        this.displays = new Array<SelectListItemModel>();

        this.isStartDateValid = false;
        this.isEndDateValid = false;
        this.resetStartDate = false;
        this.resetEndDate = false;
        this.isEnabled = true;
        this.isRecurring = false;

        this.recurring = new BroadcastRecurringModel();
    }
}