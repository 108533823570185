import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FeedbackModel } from '../models';
import { Observable } from 'rxjs';

@Injectable()
export class FeedbackService {
    private apiBaseUrl = environment.apiBaseUrl + 'api/feedback';

    constructor(private http: HttpClient){}

    add(model: FeedbackModel) {
        return this.http.post(`${this.apiBaseUrl}/add`, model);
    }
   
    exists(): Observable<any> {
        let typeId = 5;
        return this.http.get(`${this.apiBaseUrl}/exists?typeId=${typeId}`);
    }
}