import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-group-invitation-expired',
    templateUrl: './group-invitation-expired.component.html',
    styleUrls: ['./group-invitation-expired.component.scss']
})

export class GroupInvitationExpiredComponent implements OnInit {
    groupExists: boolean = true;
    linkOrInvitationExpired: boolean = false;
    constructor(private route: ActivatedRoute){
        this.route.queryParams.subscribe(params => {
            if(params['groupExists']){
                this.groupExists = params['groupExists'];
            }
            if(params['linkOrInvitationExpired']){
                this.linkOrInvitationExpired = params['linkOrInvitationExpired'];
            }
        });
    }

    ngOnInit() {
        
    }
}