import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { Guid } from 'guid-typescript';
import moment from 'moment';
import { NzDrawerComponent } from 'ng-zorro-antd/drawer';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Subscription } from 'rxjs';
import { DisplayListListItemModel, GroupDetailModel, MediaAppDetailModel, MediaAppListItemModel, SelectListItemModel, TimeZoneModel, UserMediaAppBulkUploadUpsertModel, UserMediaAppImageUpsertModel, UserMediaAppPdfUpsertModel, UserMediaAppUpsertModel, UserMediaAppVideoUpsertModel } from 'src/app/shared/models';
import { BaseService, DisplayListService, FolderService, GroupService, MediaAppService, StorageService, UserMediaAppService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { AppUtils, Constants } from 'src/helpers';

@Component({
    selector: 'app-user-media-app-bulk-upload-drawer',
    templateUrl: './user-media-app-bulk-upload.component.html',
    styleUrls: ['./user-media-app-bulk-upload.component.scss'],

})
export class UserMediaAppBulkUploadComponent implements OnInit, OnDestroy {
    @Output() closeDrawer: EventEmitter<{ mediaIds: Array<number>, mediaAdded: boolean }> = new EventEmitter<{ mediaIds: Array<number>, mediaAdded: boolean }>();
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @Input() mediaAppId: any;
    @Input() selectedGroupId: number;
    isLoading: boolean = false;
    isTimeZoneLoaded: boolean = false;
    timeZoneModel = new Array<TimeZoneModel>();
    folders = new Array<SelectListItemModel>();
    subscriptionPlans = new Array<SelectListItemModel>();
    groups = new Array<GroupDetailModel>();
    subscriptions = new Array<Subscription>();
    showPlaylists: boolean = false;
    showBroadcasts: boolean = false;
    showMedias: boolean = false;
    editDisplayList: boolean = false;
    editBroadcastList: boolean = true;
    visible: boolean = false;
    noMediaInPlaylist: boolean = false;
    imageUrlToPreview: string;
    displayLists = new Array<DisplayListListItemModel>();
    selectedDisplayLists = new Array<DisplayListListItemModel>();
    mediaAppModel = new MediaAppDetailModel();
    mediaAppDetails = new Array<MediaAppDetailModel>();
    selectedImageName: any;
    fileUploadRequest: any;
    userMediaAppId: number;
    isFileUploadInProgress = false;
    isScheduled: boolean = false;
    isEndDateValid: boolean = true;
    isEndTimeValid: boolean = true;
    isStartTimeEndTimeValid: boolean = true;
    minDateTime: any;
    selectedBroadcastIds = new Array<number>();
    selectedPlaylistIds = new Array<number>();
    scheduleStartTime: any;
    scheduleEndTime: any;
    showScheduleFields = false;
    isModelLoaded = false;
    groupId = null;
    isDisplaySelected = true;
    showGroupSelection: boolean;
    showDuration: boolean;
    selectedFolder: any;
    imageIconUrl: string;
    videoIconUrl: string;
    pdfIconUrl: string;
    model = new UserMediaAppBulkUploadUpsertModel();
    mediaApps: Array<MediaAppListItemModel>;
    originalUserMediaAppId: string;
    imageMediaAppId: number = 0;
    videoMediaAppId: number = 0;
    pdfMediaAppId: number = 0;
    acceptFileType: string;
    headerImage: string;
    maxVideoSize = '1 GB';
    maxVideoSizeInByte = 1073741824;
    mediaIds = new Array<number>();

    fileUploadPercent = 0;
    showProgressBar = false;
    isGroupLoaded: boolean = false;
    containerName: string;

    get constants() { return Constants; }

    constructor(private baseService: BaseService,
        private groupService: GroupService,
        private displaylistSevice: DisplayListService,
        private folderService: FolderService,
        private mediaAppService: MediaAppService,
        private userMediaAppService: UserMediaAppService,
        private storageService: StorageService,
        private appUtils: AppUtils
    ) {
        this.getDisplayLists();
        this.acceptFileType = 'image/png,image/jpg,image/jpeg,image/bmp,image/heic,image/gif,video/mp4,video/x-matroska,video/quicktime,application/pdf';

    }

    ngOnInit(): void {
        this.model.mediaAppId = this.mediaAppId;
        this.getMediaAppDetails();
        this.loadGroups();
        this.getMediaAppList();
    }

    getMediaAppDetails() {
        this.mediaAppService.get(this.model.mediaAppId)
            .subscribe({
                next: (response: MediaAppDetailModel) => {
                    this.mediaAppModel = response;
                    this.mediaAppModel.iconUrl = this.generateMediaIcon(this.mediaAppModel.typeString)

                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    generateMediaIcon(mediaAppType: string): string {
        return `./assets/images/media-types/icons/v2/${mediaAppType}.svg`;
    }

    openBulkUploadUserMediaAppModal(mediaApp: MediaAppDetailModel, imageMediaAppId: number, videoMediaAppId: number) {
        //TODO: Check the usage and implement accordingly.
        // this.modalContentTop = Constants.pageContentType.mediaAppBulkUploadTop + '_' + mediaApp.id;
        // this.modalContentBottom = Constants.pageContentType.mediaAppBulkUploadBottom + '_' + mediaApp.id;
        this.mediaAppModel = mediaApp;
        this.model.mediaAppId = mediaApp.id;
        this.imageMediaAppId = imageMediaAppId;
        this.videoMediaAppId = videoMediaAppId;
        const selectedGroupId = this.appUtils.getGroupId();

        if (selectedGroupId) {
            if (selectedGroupId !== -1) {
                this.model.groupId = selectedGroupId.toString();
                this.showGroupSelection = true;
            } else {
                this.showGroupSelection = false;
                this.model.groupId = '';
            }
        } else {
            this.model.groupId = -1;
            this.showGroupSelection = true;
        }

        // this.modalRefernce
        //     = this.modalService.open(this.bulkUploadUserMediaAppModal, {
        //         centered: true,
        //         keyboard: false,
        //         backdrop: 'static',
        //         size: 'lg'
        //     });


        this.getDisplayLists();
        this.getFolders();

        setTimeout(() => {
            if (this.model.groupId !== null) {
                this.model.groupName = this.subscriptionPlans.find(x => x.keyString === this.model.groupId.toString()).value;
            }
            //  this.appUtils.initControlLabels();
        });
    }

    loadGroups(): void {
        this.groupService.getList().subscribe({
            next: (response: Array<GroupDetailModel>) => {

                this.groups = new Array<GroupDetailModel>();
                this.groups = response;

                this.subscriptionPlans = new Array<SelectListItemModel>();
                let subscriptionPlan = new SelectListItemModel();
                subscriptionPlan.keyInt = -1;
                subscriptionPlan.value = `My Subscription (${this.appUtils.getUserName()})`;
                this.subscriptionPlans.push(subscriptionPlan);

                this.groups.forEach((group) => {
                    subscriptionPlan = new SelectListItemModel();
                    subscriptionPlan.keyInt = group.id;
                    subscriptionPlan.value = `${group.name} (${group.ownerName})`;
                    this.subscriptionPlans.push(subscriptionPlan);
                });

                if (this.selectedGroupId != undefined && this.selectedGroupId > 0) {
                    this.model.groupId = this.selectedGroupId;
                }
                else {
                    this.selectedGroupId = -1;
                    this.model.groupId = -1;
                }

                this.isGroupLoaded = true;
                this.isLoading = false;
                this.getFolders();
                this.getDisplayLists();
            },
            error: (error: any) => {
                this.baseService.processErrorResponse(error);
            },
        });
    }

    onGroupChanged() {
        this.model.folderId = null;
        this.getFolders();
        this.getDisplayLists();
    }

    onDisplaySelected() {
        // this.selectedDisplayLists = this.displayLists.filter(x => this.model.displayIds.indexOf(x.keyInt) !== -1);
    }

    //TODO: Get group id then send in the method parameter
    getDisplayLists(): void {
        if (!this.isGroupLoaded) {
            return;
        }
        this.displayLists = new Array<DisplayListListItemModel>();
        this.isLoading = true;
        this.displaylistSevice.getList(this.model.groupId)
            .subscribe({
                next: (response: Array<DisplayListListItemModel>) => {
                    this.displayLists = response;
                    this.isLoading = false;
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }

            });
    }

    removeDisplayList(displayList: DisplayListListItemModel): void {
        this.selectedDisplayLists.splice(this.selectedDisplayLists.indexOf(displayList), 1);
        // this.model.displayIds.splice(this.model.displayIds.indexOf(display.keyInt), 1);
    }

    cancel(): void {
        this.closeDrawer.emit({ mediaIds: new Array<number>(), mediaAdded: false });
    }

    scheduleTypeChanged(isDaily: boolean) {
        if (!AppUtils.isNullOrUndefined(this.model.endTime)) {
            const endTime = moment(this.model.endTime, 'hh:mm A').toString();
            this.model.endTime = this.appUtils.getFormattedTime(endTime, null);
        }
        this.model.isDaily = isDaily;
        this.model.startDate = null;
        this.model.endDate = null;
    }

    onScheduleClick() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.model.startTime = null;
        this.model.endTime = null;
        this.showScheduleFields = this.model.isScheduled;
    }

    getMediaAppList(): void {
        this.isLoading = true;
        this.mediaAppService.getList()
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.mediaAppDetails = response;

                        this.imageMediaAppId = this.mediaAppDetails.find(x => x.type == Constants.mediaAppType.image).id;
                        this.videoMediaAppId = this.mediaAppDetails.find(x => x.type == Constants.mediaAppType.video).id;
                        this.pdfMediaAppId = this.mediaAppDetails.find(x => x.type == Constants.mediaAppType.pdf).id;
                        this.imageIconUrl = this.generateMediaIconUrl(this.mediaAppDetails.find(x => x.type == Constants.mediaAppType.image).typeString);
                        this.videoIconUrl = this.generateMediaIconUrl(this.mediaAppDetails.find(x => x.type == Constants.mediaAppType.video).typeString);
                        this.pdfIconUrl = this.generateMediaIconUrl(this.mediaAppDetails.find(x => x.type == Constants.mediaAppType.pdf).typeString);

                        this.mediaApps = new Array<MediaAppListItemModel>();
                        response.forEach(x => {
                            let mediaApp = new MediaAppListItemModel();
                            mediaApp.id = x.typeString;
                            mediaApp.name = x.title;
                            mediaApp.iconUrl = x.iconUrl ?? this.generateMediaIconUrl(x.typeString);
                            mediaApp.previewUrl = x.previewUrl ?? this.generateMediaPreviewUrl(x.typeString);
                            this.mediaApps.push(mediaApp);
                        });
                        this.imageUrlToPreview = this.getMediaForPreview();
                    }
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            })
    }

    generateMediaIconUrl(mediaType: string): string {
        return `./assets/images/media-types/icons/${mediaType}.svg`;
    }

    generateMediaPreviewUrl(mediaType: string): string {
        return `./assets/images/media-types/preview/${mediaType}.svg`;
    }

    getMediaForPreview(): string {
        return `./assets/images/media-types/preview/WebPage.svg`;
    }


    getFolders() {
        this.isLoading = true;
        const requestsubscription =
            this.folderService.getList(this.model.groupId, this.model.folderId)
                .subscribe({
                    next: (response: Array<SelectListItemModel>) => {
                        this.folders = response;
                        this.isLoading = false;
                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);
                    }
                });

        this.subscriptions.push(requestsubscription);
    }

    onSchedule() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.scheduleStartTime = null;
        this.scheduleEndTime = null;
    }

    // scheduleTypeChanged(isDaily: boolean) {
    //     this.model.isDaily = isDaily;
    //     this.model.startDate = null;
    //     this.model.endDate = null;
    // }

    disableStartDate = (current: Date): boolean => {
        return current && current < new Date(new Date().setHours(0, 0, 0, 0));
    };

    disableEndDate = (current: Date): boolean => {
        if (!this.model.startDate) {
            return current && current < new Date(new Date().setHours(0, 0, 0, 0));
        }
        return current && current < new Date(this.model.startDate);
    };

    isTimeValid(time: any): void {
        const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
        const endTime = moment(time, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    isStartTimeValid(time: any): void {
        const startTime = moment(time, 'hh:mm A').set({ s: 0 });
        const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    validateScheduleDetails(): boolean {
        if (!this.model.isScheduled) {
            this.model.startTime = null;
            this.model.endTime = null;
            this.model.startDate = null;
            this.model.endDate = null;
            return true;
        }

        if (this.scheduleStartTime && this.scheduleEndTime) {
            if (!this.isStartTimeEndTimeValid) {
                return false;
            }

            const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
            const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
            if (
                !moment(endTime).isAfter(moment(startTime)) &&
                startTime.format('a') === endTime.format('a')
            ) {
                this.isEndTimeValid = false;
                return false;
            }
            this.model.startTime = this.appUtils.getFormattedTime(
                startTime.toString(),
                null
            );
            this.model.endTime = this.appUtils.getFormattedTime(
                endTime.toString(),
                null
            );

            if (this.model.isDaily) {
                this.model.startDate = null;
                this.model.endDate = null;
                return true;
            }
            this.model.startDate = this.appUtils.getFormattedDate(
                this.model.startDate,
                null
            );
            this.model.endDate = this.appUtils.getFormattedDate(
                this.model.endDate,
                null
            );
            if (moment(this.model.endDate).isBefore(this.model.startDate)) {
                this.isEndDateValid = false;
                return false;
            }
            this.isEndDateValid = true;
            return true;
        } else {
            return false;
        }
    }

    onPlaylistUrlError(item: DisplayListListItemModel): void {
        item.urlError = true;
    }

    // removePlaylist(index: number): void {
    //     const deletedList = this.selectedPlaylists.splice(index, 1);
    //     this.selectedPlaylists = this.selectedPlaylists.filter(x => x.id !== deletedList[0].id);
    //     this.model.displayLists = this.model.displayLists.filter(x => x.keyInt !== deletedList[0].id);
    //     this.selectedPlayListIds = this.selectedPlayListIds.filter(x => x !== deletedList[0].id);
    // }

    close(): void {
        this.visible = false;
    }

    checkMediaStatus() {
        if (!this.model.startTime || !this.model.endTime) {
            this.model.isScheduled = false;
            return;
        }
        this.model.isScheduled = true;
    }

    getDuration(id: number, displayListId: number) {
        this.isLoading = true;
        this.userMediaAppService.getDuration(id, displayListId)
            .subscribe({
                next: (response: number) => {
                    this.isLoading = false;
                    this.model.lengthInSeconds = response.toString();
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    removeUserMediaApp(index: number) {
        this.model.userMediaApps.splice(index, 1);
    }

    showErrorMessage(index: number) {
        this.baseService.processErrorResponse(this.model.userMediaApps[index].errorMessage)
    }

    beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean | Observable<boolean> => {
        this.onFileSelected({ file: { originFileObj: file } }, true);
        return false;
    };


    onFileSelected(event: any, isAntUpload = false) {
        let uploadFiles: any;
        let uploadedFiles = new Array<any>();

        if (!isAntUpload) {
            uploadedFiles = event.target.files;
        }
        else {
            uploadFiles = event.file?.originFileObj
            uploadedFiles.push(uploadFiles);
        }

        if (!uploadedFiles
            || uploadedFiles.length === 0) {
            return;
        }

        if (uploadedFiles.length > 100 || uploadedFiles.length + this.model.userMediaApps.length > 100) {
            this.baseService.error('Maximum 100 files allowed.');
            return;
        }

        if (this.model.id) {
            if (this.model.userMediaApps.length == 0) {
                let userMediaApp = new UserMediaAppUpsertModel();
                userMediaApp.id = this.model.id;
                userMediaApp.type = this.model.type;
                userMediaApp.title = this.model.title;
                userMediaApp.mediaAppId = this.model.mediaAppId;
                userMediaApp.title = this.model.title;
                userMediaApp.description = this.model.description;
                this.model.userMediaApps.push(userMediaApp);
            }
            this.model.userMediaApps[0].status = this.constants.mediaUploadStatus.pending;

          

            if (uploadedFiles[0].type.startsWith('image')) {
                if (uploadedFiles[0].type !== 'image/jpg'
                    && uploadedFiles[0].type !== 'image/jpeg'
                    && uploadedFiles[0].type !== 'image/png'
                    && uploadedFiles[0].type !== 'image/bmp'
                    && uploadedFiles[0].type !== 'image/heic'
                    && uploadedFiles[0].type !== 'image/gif') {
                    this.baseService.error('Please upload image of JPG/JPEG, PNG, BPM, HEIC, GIF format only.');
                    return;
                }

                if (uploadedFiles[0].size > 20971520) {
                    this.baseService.error('Image can not be more than 20 MB');
                    return;
                }

                this.model.userMediaApps[0].mediaAppId = this.imageMediaAppId;

                if (!this.model.userMediaApps[0].image) {
                    this.model.userMediaApps[0].image = new UserMediaAppImageUpsertModel();
                    this.model.userMediaApps[0].image.userMediaAppId = this.model.id;
                    this.model.userMediaApps[0].image.id = this.originalUserMediaAppId;
                }

                this.model.userMediaApps[0].image.file = uploadedFiles[0];
                this.model.userMediaApps[0].image.originalFileName = this.model.userMediaApps[0].image.file.name;
                this.model.userMediaApps[0].image.size = this.appUtils.formatFileSize(uploadedFiles[0].size);
            }

            if (uploadedFiles[0].type.startsWith('video')) {
                const extension = uploadedFiles[0].name.split('.').pop().toUpperCase();
                if (uploadedFiles[0].type !== 'video/mp4'
                    && uploadedFiles[0].type !== 'video/x-matroska'
                    && uploadedFiles[0].type !== 'video/quicktime'
                    && extension !== 'HEVC') {
                    this.baseService.error(`We currently support MP4, MKV, MOV & HEVC video formats.If you'd like us to consider additional formats, just ask!`);
                    return;
                }

                if (uploadedFiles[0].size > this.maxVideoSizeInByte) {
                    this.baseService.error(`Video can not be more than ${this.maxVideoSize}`);
                    return;
                }

                this.model.userMediaApps[0].mediaAppId = this.videoMediaAppId;

                if (!this.model.userMediaApps[0].video) {
                    this.model.userMediaApps[0].video = new UserMediaAppVideoUpsertModel();
                    this.model.userMediaApps[0].video.userMediaAppId = this.model.id;
                    this.model.userMediaApps[0].video.id = this.originalUserMediaAppId;
                }

                this.model.userMediaApps[0].video.file = uploadedFiles[0];
                this.model.userMediaApps[0].video.originalFileName = this.model.userMediaApps[0].video.file.name;
                this.model.userMediaApps[0].video.size = this.appUtils.formatFileSize(uploadedFiles[0].size);
            }

            if (uploadedFiles[0].type.startsWith('application/pdf')) {
                if (uploadedFiles[0].type !== 'application/pdf') {
                    this.baseService.error('Please upload PDF file only');
                    return;
                }

                if (uploadedFiles[0].size > 20971520) {
                    this.baseService.error('Pdf can not be more than 20 MB');
                    event.target.files[0].val = null;
                    return;
                }

                this.model.userMediaApps[0].mediaAppId = this.pdfMediaAppId;

                if (!this.model.userMediaApps[0].pdf) {
                    this.model.userMediaApps[0].pdf = new UserMediaAppPdfUpsertModel();
                    this.model.userMediaApps[0].pdf.userMediaAppId = this.model.id;
                    this.model.userMediaApps[0].pdf.id = this.originalUserMediaAppId;
                }

                this.model.userMediaApps[0].pdf.file = uploadedFiles[0];
                this.model.userMediaApps[0].pdf.originalFileName = this.model.userMediaApps[0].pdf.file.name;
                this.model.userMediaApps[0].pdf.size = this.appUtils.formatFileSize(uploadedFiles[0].size);
            }

            return;
        }

        for (let i = 0; i < uploadedFiles.length; i++) {
            const uploadedFile = uploadedFiles[i];
            let userMediaApp = new UserMediaAppUpsertModel();
            userMediaApp.status = this.constants.mediaUploadStatus.pending;
            if (uploadedFile.type.startsWith('image')) {
                if (uploadedFile.type !== 'image/jpg'
                    && uploadedFile.type !== 'image/jpeg'
                    && uploadedFile.type !== 'image/png'
                    && uploadedFile.type !== 'image/bmp'
                    && uploadedFile.type !== 'image/heic'
                    && uploadedFile.type !== 'image/gif') {
                    this.baseService.error('Please upload image of JPG/JPEG, PNG, BPM, HEIC, GIF format only.');
                    continue;
                }

                if (uploadedFile.size > 20971520) {
                    this.baseService.error('Image can not be more than 20 MB');
                    continue;
                }

                userMediaApp.mediaAppId = this.imageMediaAppId;
                userMediaApp.image = new UserMediaAppImageUpsertModel();

                userMediaApp.image.file = uploadedFile;
                userMediaApp.image.originalFileName = userMediaApp.image.file.name;
                userMediaApp.image.size = this.appUtils.formatFileSize(uploadedFile.size);

                this.model.userMediaApps.push(userMediaApp);
            }
            else if (uploadedFile.type.startsWith('video')) {
                const extension = uploadedFile.name.split('.').pop().toUpperCase();
                if (uploadedFile.type !== 'video/mp4'
                    && uploadedFile.type !== 'video/x-matroska'
                    && uploadedFile.type !== 'video/quicktime'
                    && extension !== 'HEVC') {
                    this.baseService.error(`We currently support MP4, MKV, MOV & HEVC video formats. If you'd like us to consider additional formats, just ask!`);
                    continue;
                }

                if (uploadedFile.size > this.maxVideoSizeInByte) {
                    this.baseService.error(`Video can not be more than ${this.maxVideoSize}`);
                    continue;
                }

                userMediaApp.mediaAppId = this.videoMediaAppId;
                userMediaApp.video = new UserMediaAppVideoUpsertModel();
                userMediaApp.video.file = uploadedFile;
                userMediaApp.video.originalFileName = userMediaApp.video.file.name;
                userMediaApp.video.size = this.appUtils.formatFileSize(uploadedFile.size);
                this.model.userMediaApps.push(userMediaApp);
            }
            else {
                if (uploadedFile.type !== 'application/pdf') {
                    this.baseService.error('Please upload PDF file only');
                    uploadedFile[i].val = null;
                    continue;
                }

                if (uploadedFile.size > 20971520) {
                    this.baseService.error('Pdf can not be more than 20 MB');
                    uploadedFile[i].val = null;
                    continue;
                }

                userMediaApp.mediaAppId = this.pdfMediaAppId;
                userMediaApp.pdf = new UserMediaAppPdfUpsertModel();
                userMediaApp.pdf.file = uploadedFile;
                userMediaApp.pdf.originalFileName = userMediaApp.pdf.file.name;
                userMediaApp.pdf.size = this.appUtils.formatFileSize(uploadedFile.size);
                this.model.userMediaApps.push(userMediaApp);

            }
        }
    }

    getAzureSasToken(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storageService.getAzureSasToken().subscribe({
                next: async (data) => {
                    resolve(data);
                },
                error: error => {
                    this.baseService.error('Unable to upload image file please try again.');
                    reject(new Error(error));
                }
            });
        });
    }

    async uploadVideo(videoItem: UserMediaAppVideoUpsertModel, sasToken: any): Promise<any> {
        videoItem.fileUploadPercent = 1;

        const blockSize = this.appUtils.getBlockSize(videoItem.file.size);

        return new Promise((resolve, reject) => {

            const CloudConfig = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: this.containerName
            };
            const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(videoItem.file.name)}`;
            const tempFileName = `${environment.azureContainers.userMediaAppVideo}/${fileName}`;
            const blobUrl = `${environment.azureContainerBaseUrl}/${CloudConfig.containerName}/${tempFileName}`;

            const blobServiceClientUrl = `${environment.azureContainerBaseUrl}?${sasToken}`;
            const blobServiceClient = new BlobServiceClient(blobServiceClientUrl);
            const containerClient = blobServiceClient.getContainerClient(
                CloudConfig.containerName
            );
            const blockBlobClient = containerClient.getBlockBlobClient(tempFileName);

            const options = {
                blockSize: blockSize,
                maxSingleShotSize: 1024 * 32,
                onProgress: (progress) => {
                    videoItem.fileUploadPercent = Math.round(
                        (progress.loadedBytes / videoItem.file.size) * 100
                    );
                }
            };

            blockBlobClient
                .uploadData(videoItem.file, options)
                .then(() => {
                    Constants.interceptAuthToken = true;
                    videoItem.fileName = fileName;
                    videoItem.fileUrl = blobUrl;
                    this.isFileUploadInProgress = false;
                    resolve(true);
                })
                .catch((err) => {
                    Constants.interceptAuthToken = true;
                    this.isFileUploadInProgress = false;
                    this.baseService.error('video file upload timeout.');
                    reject(new Error(err));
                });
        });
    }

    async uploadImage(imageItem: UserMediaAppImageUpsertModel, sasToken: any): Promise<any> {
        imageItem.fileUploadPercent = 1;
        return new Promise((resolve, reject) => {

            const CloudConfig = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: this.containerName
            };
            const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(imageItem.file.name)}`;
            const tempFileName = `${environment.azureContainers.userMediaAppImage}/${fileName}`;
            const blobUrl = `${environment.azureContainerBaseUrl}/${CloudConfig.containerName}/${tempFileName}`;
            const blobServiceClientUrl = `${environment.azureContainerBaseUrl}?${sasToken}`;
            const blobServiceClient = new BlobServiceClient(blobServiceClientUrl);
            const containerClient = blobServiceClient.getContainerClient(
                CloudConfig.containerName
            );
            const blockBlobClient = containerClient.getBlockBlobClient(tempFileName);

            blockBlobClient
                .uploadData(imageItem.file, {
                    blockSize: 262144,
                    onProgress: (progress) => {
                        imageItem.fileUploadPercent = Math.round(
                            (progress.loadedBytes / imageItem.file.size) * 100
                        );
                    },
                })
                .then(() => {
                    Constants.interceptAuthToken = true;
                    imageItem.fileName = fileName;
                    imageItem.fileUrl = blobUrl;
                    this.isFileUploadInProgress = false;
                    resolve(true);
                })
                .catch((err) => {
                    Constants.interceptAuthToken = true;
                    this.isFileUploadInProgress = false;
                    this.baseService.error('Image file upload timeout.');
                    reject(new Error(err));
                });
        });
    }

    async uploadPdf(pdfItem: UserMediaAppPdfUpsertModel, sasToken: any): Promise<any> {
        pdfItem.fileUploadPercent = 1;
        return new Promise((resolve, reject) => {

            const CloudConfig = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: this.containerName
            };
            const fileName = `${Guid.create()}.${this.appUtils.getFileExtension(pdfItem.file.name)}`;
            const tempFileName = `${environment.azureContainers.userMediaAppPdf}/${fileName}`;
            const blobUrl = `${environment.azureContainerBaseUrl}/${CloudConfig.containerName}/${tempFileName}`;
            const blobServiceClientUrl = `${environment.azureContainerBaseUrl}?${sasToken}`;
            const blobServiceClient = new BlobServiceClient(blobServiceClientUrl);
            const containerClient = blobServiceClient.getContainerClient(
                CloudConfig.containerName
            );
            const blockBlobClient = containerClient.getBlockBlobClient(tempFileName);

            blockBlobClient
                .uploadData(pdfItem.file, {
                    blockSize: 262144,
                    onProgress: (progress) => {
                        pdfItem.fileUploadPercent = Math.round(
                            (progress.loadedBytes / pdfItem.file.size) * 100
                        );
                    },
                })
                .then(() => {
                    Constants.interceptAuthToken = true;
                    pdfItem.fileName = fileName;
                    pdfItem.fileUrl = blobUrl;
                    this.isFileUploadInProgress = false;
                    this.isLoading = false;
                    resolve(true);
                })
                .catch((err) => {
                    Constants.interceptAuthToken = true;
                    this.isFileUploadInProgress = false;
                    this.baseService.error('Pdf file upload timeout.');
                    this.isLoading = false;
                    reject(new Error(err));
                });
        });
    }

    async submit(): Promise<void> {
        this.isLoading = true;
        if (this.model.groupId == '' || this.model.groupId == -1) {
            this.model.groupId = null;
        }

        this.model.isScheduled = this.isScheduled;
        if (!this.validateScheduleDetails()) {
            this.isScheduled = true;
            this.isLoading = false;
            return;
        }

        this.model.displayLists = new Array<SelectListItemModel>();
        if (this.selectedDisplayLists && this.selectedDisplayLists.length > 0) {
            this.selectedDisplayLists.forEach((item) => {
                let selectedDisplayListModel = new SelectListItemModel();
                selectedDisplayListModel.keyInt = item.id;
                selectedDisplayListModel.value = item.name;
                this.model.displayLists.push(selectedDisplayListModel);
            });
            this.model.lengthInSeconds = (this.selectedDisplayLists.length === 1 || this.showDuration)
                ? this.model.lengthInSeconds : '0';
        }

        if (this.selectedFolder && this.selectedFolder.length > 0) {
            this.model.folderId = this.selectedFolder[0].keyInt;
        } else {
            this.model.folderId = null;
        }

        if (this.model.userMediaApps.length === 0) {
            this.baseService.error('Please select at least one media.');
            return;
        }
        
        await this.getContainerNameToUpload().then(value => {
            if (!value) {
                return;
            }
            this.containerName = value;
        });

        let userMediaApp = new UserMediaAppUpsertModel();
        userMediaApp.description = this.model.description;
        userMediaApp.groupId = this.model.groupId;
        userMediaApp.groupName = this.model.groupName;
        userMediaApp.groupOwnerId = this.model.groupOwnerId;
        userMediaApp.folderId = this.model.folderId;
        userMediaApp.startDate = this.model.startDate;
        userMediaApp.endDate = this.model.endDate;
        userMediaApp.startTime = this.model.startTime;
        userMediaApp.endTime = this.model.endTime;
        userMediaApp.displayLists = this.model.displayLists;
        userMediaApp.broadcastIds = this.model.broadcastIds;

        this.model.mediaAppId = this.mediaAppId;

        let hasAnyError = false;
        this.fileUploadPercent = 0;
        this.showProgressBar = true;
        this.isLoading = true;
        this.baseService.info('Thanks for your patience while we get things ready for you.');

        for (let i = 0; i < this.model.userMediaApps.length; i++) {
            if (this.model.userMediaApps[i].id && this.model.userMediaApps[i].id > 0) {
                this.fileUploadPercent = Math.trunc((100 / this.model.userMediaApps.length) * (i + 1));
                continue;
            }

            try {
                this.model.userMediaApps[i].status = this.constants.mediaUploadStatus.inProgress;
                let sasToken = await this.getAzureSasToken();
                if (this.model.userMediaApps[i].image != null) {
                    await this.uploadImage(this.model.userMediaApps[i].image, sasToken);
                    userMediaApp.mediaAppId = this.imageMediaAppId;
                    if (this.model.userMediaApps[i].image.file.type === 'image/heic' || !this.model.hasRotated) {
                        this.model.userMediaApps[i].image.rotationDegree = null;
                    } else {
                        this.model.userMediaApps[i].image.rotationDegree = this.model.rotationDegree;
                    }
                    userMediaApp.image = this.model.userMediaApps[i].image;
                    userMediaApp.image.isEnabled = this.model.isEnabled;
                    userMediaApp.title = this.model.prefix ? this.model.prefix + ' ' + userMediaApp.image.originalFileName : userMediaApp.image.originalFileName;
                    userMediaApp.video = null;
                    userMediaApp.pdf = null;
                } else if (this.model.userMediaApps[i].video != null) {
                    await this.uploadVideo(this.model.userMediaApps[i].video, sasToken);
                    userMediaApp.mediaAppId = this.videoMediaAppId;
                    userMediaApp.video = this.model.userMediaApps[i].video;
                    userMediaApp.video.isEnabled = this.model.isEnabled;
                    userMediaApp.title = this.model.prefix ? this.model.prefix + ' ' + userMediaApp.video.originalFileName : userMediaApp.video.originalFileName;
                    userMediaApp.image = null;
                    userMediaApp.pdf = null;
                }
                else {
                    await this.uploadPdf(this.model.userMediaApps[i].pdf, sasToken);
                    userMediaApp.mediaAppId = this.pdfMediaAppId;
                    userMediaApp.pdf = this.model.userMediaApps[i].pdf;
                    userMediaApp.pdf.pageChangeDuration = !this.model.pageChangeDuration || this.model.pageChangeDuration === 0 ? 10 : this.model.pageChangeDuration;
                    userMediaApp.pdf.isEnabled = this.model.isEnabled;
                    userMediaApp.title = this.model.prefix ? this.model.prefix + ' ' + userMediaApp.pdf.originalFileName : userMediaApp.pdf.originalFileName;
                    userMediaApp.image = null;
                    userMediaApp.video = null;
                }

                await this.addUserMediaApp(i, userMediaApp);
            } catch (e) {
                hasAnyError = true;
            }

            this.fileUploadPercent = Math.trunc((100 / this.model.userMediaApps.length) * (i + 1));
        }

        this.showProgressBar = false;
        this.isLoading = false;

        if (!hasAnyError) {
            let message = `You finished uploading your media! You can now add it to playlists and broadcasts. Videos will automatically play once optimized. Optimization typically takes a few minutes, depending upon video size.`;
            this.closeDrawer.emit({ mediaIds: this.mediaIds, mediaAdded: true });
            this.baseService.success(message);
        }
    }

    addUserMediaApp(i: number, userMediaApp: UserMediaAppUpsertModel): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userMediaAppService.add(userMediaApp).subscribe({
                next: (res: number) => {
                    this.model.userMediaApps[i].id = res;
                    this.model.userMediaApps[i].status = this.constants.mediaUploadStatus.completed;
                    this.mediaIds.push(res);
                    resolve(true);
                },
                error: (error: any) => {
                    this.model.userMediaApps[i].hasError = true;
                    this.model.userMediaApps[i].errorMessage = error;
                    this.model.userMediaApps[i].status = this.constants.mediaUploadStatus.rejected;
                    reject(new Error(error));
                }
            });
        });
    }

    getContainerNameToUpload(): Promise<any> {
        return new Promise((resolve, reject) => {
            let container = '';
            if (this.model.groupId === null || this.model.groupId === -1) {
                container = this.appUtils.getUserId();
            } else {
                const selectedGroup = this.groups.find((e) => e.id == this.model.groupId);
                if (!selectedGroup) {
                    reject(false);
                    return;
                }
                container = selectedGroup.ownerId;
            }

            this.isLoading = true;
            this.storageService.createContainerIfNotExists(container).subscribe({
                next: () => {
                    this.isLoading = false;
                    resolve(container);
                },
                error: error => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error.error);
                    reject(false);
                }
            })
        });
    }

    ngOnDestroy() {
        this.isModelLoaded = false;
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }
}
