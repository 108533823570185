import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import moment from "moment";
import { Subscription } from "rxjs";
import { DisplayListListItemModel, GroupDetailModel, MediaAppDetailModel, MediaAppListItemModel, SelectListItemModel, UserMediaAppCanvaUpsertModel, UserMediaAppUpsertModel } from "src/app/shared/models";
import { BaseService, DisplayListService, FolderService, GroupService, MediaAppService, UserMediaAppService } from "src/app/shared/services";
import { AppUtils, Constants } from "src/helpers";
import { ListenerService } from "src/services";

@Component({
    selector: 'app-user-media-app-canva-drawer',
    templateUrl: './user-media-app-canva.component.html',
    styleUrls: ['./user-media-app-canva.component.scss']
})
export class UserMediaAppCanvaComponent implements OnInit {
    @Output() closeDrawer: EventEmitter<{ mediaId: number, mediaAdded: boolean }> = new EventEmitter<{ mediaId: number, mediaAdded: boolean }>();
    @Input() mediaAppId: any;
    @Input() selectedGroupId: number;

    mediaAppModel = new MediaAppDetailModel();
    model = new UserMediaAppUpsertModel();
    selectedDisplayLists = new Array<SelectListItemModel>();
    displayLists = new Array<SelectListItemModel>();
    subscriptionPlans = new Array<SelectListItemModel>();
    selectedFolder = new Array<SelectListItemModel>();
    folders = new Array<SelectListItemModel>();
    groups = new Array<GroupDetailModel>();
    subscriptions = new Array<Subscription>();
    mediaApps = new Array<MediaAppListItemModel>();
    isLoading = false;
    showScheduleFields = false;
    scheduleStartTime: any;
    scheduleEndTime: any;
    isScheduled: boolean = false;
    isEndDateValid = true;
    isEndTimeValid = true;
    imageUrlToPreview: string;
    userMediaAppId: number;
    isRedirectToCanva = false;
    showDuration = false;
    isStartTimeEndTimeValid = true;
    isGroupLoaded: boolean = false;

    get constants() { return Constants; }

    constructor(
        private baseService: BaseService,
        public appUtils: AppUtils,
        private groupService: GroupService,
        private displayListService: DisplayListService,
        private userMediaAppService: UserMediaAppService,
        private folderService: FolderService,
        private mediaAppService: MediaAppService,
        private messageService: ListenerService
    ) {
        this.model = new UserMediaAppUpsertModel();
        this.model.canva = new UserMediaAppCanvaUpsertModel();

        this.subscriptionPlans = new Array<SelectListItemModel>();
        this.folders = new Array<SelectListItemModel>();
        this.groups = new Array<GroupDetailModel>();
        this.mediaApps = new Array<MediaAppListItemModel>();

        const canvaDesignPublished = this.messageService
            .listenCanvaDesignPublished.subscribe(data => {
                this.onCanvaDesignPublished(data);
            });

        this.subscriptions.push(canvaDesignPublished);
    }

    ngOnInit(): void {
        this.model.startDate = new Date();
        this.model.endDate = new Date();
        this.model.mediaAppId = this.mediaAppId;
        this.getMediaAppDetails();
        this.loadGroups();
    }

    getMediaAppDetails() {
        this.mediaAppService.get(this.model.mediaAppId)
            .subscribe({
                next: (response: MediaAppDetailModel) => {
                    this.mediaAppModel = response;
                    this.mediaAppModel.iconUrl = this.generateMediaIcon(this.mediaAppModel.typeString);
                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    generateMediaIcon(mediaAppType: string): string {
        return `./assets/images/media-types/icons/v2/${mediaAppType}.svg`;
    }

    getMediaAppList(): void {
        this.isLoading = true;
        this.mediaAppService.getList().subscribe({
            next: (response: any) => {
                if (response) {
                    this.mediaApps = new Array<MediaAppListItemModel>();
                    response.forEach((x) => {
                        let mediaApp = new MediaAppListItemModel();
                        mediaApp.id = x.typeString;
                        mediaApp.name = x.title;
                        mediaApp.iconUrl =
                            x.iconUrl ?? this.generateMediaIconUrl(x.typeString);
                        mediaApp.previewUrl =
                            x.previewUrl ?? this.generateMediaPreviewUrl(x.typeString);
                        this.mediaApps.push(mediaApp);
                    });
                }
                this.imageUrlToPreview = this.getMediaForPreview();
                this.isLoading = false;
            },
            error: (error) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            },
        });
    }

    generateMediaIconUrl(mediaType: string): string {
        return `./assets/images/media-types/icons/${mediaType}.svg`;
    }

    generateMediaPreviewUrl(mediaType: string): string {
        return `./assets/images/media-types/preview/${mediaType}.svg`;
    }

    getMediaForPreview(): string {
        return `./assets/images/media-types/preview/Powerpoint0365.svg`;
    }

    updateSelectedDisplayList() {
        if (this.displayLists.length === 0) {
            return;
        }

        const selectedDisplayLists = new Array<SelectListItemModel>();
        this.model.displayLists.forEach((displayList) => {
            selectedDisplayLists.push(
                this.displayLists.find(
                    (x) => Number(x.keyInt) === Number(displayList.keyInt)
                )
            );
        });

        if (selectedDisplayLists && selectedDisplayLists.length > 0) {
            this.selectedDisplayLists = selectedDisplayLists;
        }
    }

    removeDisplay(item: SelectListItemModel) {
        const tempArr = [];
        this.selectedDisplayLists.forEach((displayList) => {
            tempArr.push(displayList);
        });
        const itemIndex = tempArr.indexOf(item);
        tempArr.splice(itemIndex, 1);
        this.selectedDisplayLists = tempArr;
    }

    onGroupChange() {
        this.getFolders();
    }

    validateScheduleDetails(): boolean {
        if (!this.model.isScheduled) {
            this.model.startTime = null;
            this.model.endTime = null;
            this.model.startDate = null;
            this.model.endDate = null;
            return true;
        }

        if (this.scheduleStartTime && this.scheduleEndTime) {
            if (!this.isStartTimeEndTimeValid) {
                return false;
            }

            const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
            const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
            if (
                !moment(endTime).isAfter(moment(startTime)) &&
                startTime.format('a') === endTime.format('a')
            ) {
                this.isEndTimeValid = false;
                return false;
            }
            this.model.startTime = this.appUtils.getFormattedTime(
                startTime.toString(),
                null
            );
            this.model.endTime = this.appUtils.getFormattedTime(
                endTime.toString(),
                null
            );

            if (this.model.isDaily) {
                this.model.startDate = null;
                this.model.endDate = null;
                return true;
            }
            this.model.startDate = this.appUtils.getFormattedDate(
                this.model.startDate,
                null
            );
            this.model.endDate = this.appUtils.getFormattedDate(
                this.model.endDate,
                null
            );
            if (moment(this.model.endDate).isBefore(this.model.startDate)) {
                this.isEndDateValid = false;
                return false;
            }
            this.isEndDateValid = true;
            return true;
        } else {
            return false;
        }
    }

    onSchedule() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.scheduleStartTime = null;
        this.scheduleEndTime = null;
    }

    scheduleTypeChanged(isDaily: boolean) {
        this.model.isDaily = isDaily;
        this.model.startDate = null;
        this.model.endDate = null;
    }

    disableStartDate = (current: Date): boolean => {
        return current && current < new Date(new Date().setHours(0, 0, 0, 0));
    }

    disableEndDate = (current: Date): boolean => {
        if (!this.model.startDate) {
            return current && current < new Date(new Date().setHours(0, 0, 0, 0));
        }
        return current && current < new Date(this.model.startDate);
    }

    isStartTimeValid(time: any): void {
        const startTime = moment(time, 'hh:mm A').set({ s: 0 });
        const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    isTimeValid(time: any): void {
        const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
        const endTime = moment(time, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    loadDisplayLists() {
        if(!this.isGroupLoaded){
            return;
        }
        this.displayLists = new Array<SelectListItemModel>();
        this.isLoading = true;
        const requestSubscription = this.displayListService
            .getSelectListItems(this.model.groupId)
            .subscribe({
                next: (data: Array<any>) => {
                    this.isLoading = false;
                    this.displayLists = data;
                    // this.updateSelectedDisplayList();
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestSubscription);
    }

    loadGroups(): void {
        this.isLoading = true;
        this.groupService.getList().subscribe({
            next: (response: Array<GroupDetailModel>) => {
                let selectListItem = new SelectListItemModel();
                selectListItem.keyString = '-1';
                selectListItem.keyInt = -1;
                selectListItem.value = `My Subscription (${this.appUtils.getUserName()})`;

                this.subscriptionPlans.push(selectListItem);

                Object.assign(this.groups, response);

                this.groups.forEach((group) => {
                    selectListItem = new SelectListItemModel();
                    selectListItem.keyInt = group.id;
                    selectListItem.keyString = group.id.toString();
                    selectListItem.value = `${group.name} (${group.ownerName})`;
                    this.subscriptionPlans.push(selectListItem);
                });

                if (this.selectedGroupId != undefined && this.selectedGroupId > 0) {
                    this.model.groupId = this.selectedGroupId;
                }
                else {
                    this.selectedGroupId = -1;
                    this.model.groupId = -1;
                }

                this.isLoading = false;
                this.isGroupLoaded = true;
                this.getFolders();
                this.loadDisplayLists();
            },
            error: (error) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            },
        });
    }

    getFolders() {
        this.isLoading = true;
        if (!this.model.folderId) {
            this.model.folderId = null;
        }
        const requestsubscription = this.folderService
            .getList(this.model.groupId, this.model.folderId)
            .subscribe({
                next: (response: Array<SelectListItemModel>) => {
                    this.folders = response;
                    // this.updateSelectedFolder();
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestsubscription);
    }

    updateSelectedFolder() {
        if (this.folders.length === 0 || !this.model.folderId) {
            this.model.folderId = null;
            this.isLoading = false;
            return;
        }

        const selectedFolder = this.folders.filter(
            (x) => Number(x.keyInt) === Number(this.model.folderId)
        );

        if (selectedFolder) {
            this.selectedFolder = selectedFolder;
        }
    }

    submitAndRedirectToCanva(f: NgForm): void {
        if (!f.valid) {
            this.baseService.error("Please enter required values.");
            this.isRedirectToCanva = false;
            return
        }
        this.isRedirectToCanva = true;
    }

    redirectToCanva() {
        if (!this.model.title
            && !this.model.canva
            && this.model.canva.canvaDesignId) {
            this.baseService.processErrorResponse('Please enter the media app title to continue.');
            return;
        }

        if (!this.model.canva
            || this.appUtils.isNullOrEmpty(this.model.canva.canvaDesignId)) {
            const identificationToken = btoa(this.userMediaAppId.toString()).toString();
            const title = `___${identificationToken}___${this.model.title}`;
            let height = '720';
            let width = '1280';
            window.open(this.appUtils.getCanvaDesignCreateUrl(title, height, width), 'blank');
        } else {
            window.open(this.appUtils.getCanvaDesignEditUrl(this.model.canva.canvaDesignId), '_blank');
        }
    }

    cancel(): void {
        this.closeDrawer.emit({ mediaId: 0, mediaAdded: false });
    }

    onCanvaDesignPublished(canvaResponse: any) {
        if (this.userMediaAppId === canvaResponse.UserMediaAppId) {
            this.baseService.success('Your Media was added successfully.')
            this.closeDrawer.emit({ mediaId: this.userMediaAppId, mediaAdded: true });
        }
    }

    submit(): void {
        if (this.model.groupId == null || this.model.groupId == -1) {
            this.model.groupId = null;
        }

        if ((!this.model.canva || !this.model.canva.canvaDesignId) && !this.isRedirectToCanva) {
            let errorMessage = 'Oops! Please save your design from Canva to Display NOW Manager.<br><br>';
            errorMessage += 'You can find this in Canva via the “Share” button. Display NOW Manager will appear alongside other apps.';
            this.baseService.error(errorMessage);
            this.isLoading = false;
            return;
        }

        this.model.isScheduled = this.isScheduled;
        if (!this.validateScheduleDetails()) {
            this.isScheduled = true;
            this.isLoading = false;
            return;
        }

        this.model.displayLists = new Array<SelectListItemModel>();

        if (this.selectedDisplayLists != null && this.selectedDisplayLists.length > 0) {
            this.selectedDisplayLists.forEach(item => {
                let selectedDisplayListModel = new SelectListItemModel();
                selectedDisplayListModel.keyInt = item.keyInt;
                selectedDisplayListModel.value = item.value;

                this.model.displayLists.push(selectedDisplayListModel);
            });
            this.model.lengthInSeconds = (this.selectedDisplayLists.length === 1 || this.showDuration)
                ? this.model.lengthInSeconds : '0';
        }

        if (this.selectedFolder && this.selectedFolder.length > 0) {
            this.model.folderId = this.selectedFolder[0].keyInt;
        } else {
            this.model.folderId = null;
        }

        this.isLoading = true;

        this.userMediaAppService.add(this.model).subscribe({
            next: (res: number) => {
                this.isLoading = false;
                if (this.isRedirectToCanva) {
                    this.userMediaAppId = res;
                    this.redirectToCanva();
                }
            },
            error: (error: any) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            }
        })
    }
}