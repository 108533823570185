import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CountryService {

    private apiEndPoint = environment.apiBaseUrl + 'api/country/';

    constructor(private http: HttpClient) { }

    getSelectListItems() {
        return this.http.get(this.apiEndPoint + 'get-select-list-items');
    }
}
