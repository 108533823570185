import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { CardDetailModel, UpdateSubscriptionModel, CardEditDetailModel } from "src/models";

@Injectable({
    providedIn: 'root',
})

export class SubscriptionService {

    constructor(private http: HttpClient) { }

    private apiEndPoint = environment.apiBaseUrl + 'api/subscription/';

    getDetailBySubscriptionId(subscriptionId: string) {
        return this.http.get(this.apiEndPoint + 'get-detail-by-subscription-id/' + subscriptionId);
    }

    getPlans() {
        return this.http.get(this.apiEndPoint + `get-plans`);
    }

    getCardDetails() {
        return this.http.get(this.apiEndPoint + 'get-card');
    }

    getUpdateEstimate(planId: string, quantity: number, groupId: any) {
        return this.http.get(this.apiEndPoint + `get-update-estimate/${planId}/${quantity}/${groupId}`);
    }

    updateSubscription(model: UpdateSubscriptionModel) {
        return this.http.post(this.apiEndPoint + 'update-subscription', model);
    }

    addCardDetails(model: CardDetailModel) {
        return this.http.post(this.apiEndPoint + 'update-card', model);
    }

    updateCardDetails(model: CardEditDetailModel) {
        return this.http.post(this.apiEndPoint + 'update-card', model);
    }

    getPlansWithSubscribedPlan(subscribedPlanId: string, periodUnit: string) {
        return this.http.get(this.apiEndPoint + `get-plans/${subscribedPlanId}/${periodUnit}`);
    }

    getDetailByUserId(userId: string) {
        return this.http.get(this.apiEndPoint + 'get-detail-by-user-id/' + userId);
    }

    getDetailByGroupId(groupId: any) {
        return this.http.get(this.apiEndPoint + 'get-detail-by-group-id/' + groupId);
    }

    getPlanDetail(planId: string) {
        return this.http.get(this.apiEndPoint + 'get-plan/' + planId);
    }
}