import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppUtils } from './app.utils';
import { Constants } from './app.constants';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private appUtils: AppUtils) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.headers.has('Authorization')) {
            return next.handle(request);
        }

        // add authorization header with jwt token if available
        const nonAuthUrl = Constants.nonAuthUrl.filter(x => request.url.indexOf(x) > -1);
        if (nonAuthUrl.length == 0) {
            const token = this.appUtils.getAuthToken();
            if (token && Constants.interceptAuthToken) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }
        }
        return next.handle(request);
    }
}
