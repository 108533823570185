import { SelectListItemModel } from "src/models";
import { UserMediaAppUpsertModel } from "./user-media-app-upsert.model";

export class UserMediaAppBulkUploadUpsertModel
{
    public id: number;
    public mediaAppId: number;
    public type: number;
    public prefix: string;
    public description: string;
    public groupId: any;
    public groupName: string;
    public groupOwnerId: string;
    public lengthInSeconds: string;
    public folderId: any;
    public startDate: any;
    public startTime: any;
    public endDate: any;
    public endTime: any;
    public isDaily: boolean;
    public isScheduled: boolean;
    public isEnabled: boolean;
    public hasRotated: boolean;
    public rotationDegree: number;
    public title: string;
    public updatedOn: string;
    public pageChangeDuration: number;

    public displayLists: Array<SelectListItemModel>;
    broadcastIds: Array<number>;
    public userMediaApps: Array<UserMediaAppUpsertModel>;

    public broadcasts: Array<SelectListItemModel>;


    constructor() {
        this.displayLists = new Array<SelectListItemModel>();
        this.broadcastIds = new Array<number>();
        this.userMediaApps = Array<UserMediaAppUpsertModel>();
        this.lengthInSeconds = '10';
        this.isDaily = true;
        this.startDate = '';
        this.startTime = '';
        this.endDate = '';
        this.endTime = '';
        this.isScheduled = false;
        this.isEnabled = true;
    }
}