import { Component, Inject, OnInit } from "@angular/core";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { FeedbackModel } from "src/app/shared/models";
import { BaseService, FeedbackService } from "src/app/shared/services";

@Component({
    selector: 'app-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent {
    isLoading = false;
    model = new FeedbackModel();
    rating: any;

    constructor(private feedbackService: FeedbackService,
        private baseService: BaseService,
        private modal: NzModalRef,
        @Inject(NZ_MODAL_DATA) private modalData: any) { }

    cancel() {
        this.modal.destroy(false);
    }

    proceed() {
        this.modal.destroy(true);
    }

    submit() {
        this.isLoading = true;
        this.model.feedbackTypeId = 5;
        this.feedbackService.add(this.model).subscribe({
            next: () => {
                this.isLoading = false;
                this.baseService.success("Thankyou for submitting your feedback.");
                this.modal.destroy(true);
            },
            error: (error: any) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            }
        })
    }

    trimComment() {
        this.model.comment = this.model.comment.trimStart();
    }
}