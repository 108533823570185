import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class FeedbackTypeService {
    private apiBaseUrl = environment.apiBaseUrl + 'api/feedbackType';

    constructor(private http: HttpClient){}

    getSelectListItems(): Observable<any> {
        return this.http.get(`${this.apiBaseUrl}/select-list-items`);
    }

}