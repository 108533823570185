import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'youtubeView' })
export class YoutubeView implements PipeTransform {
    transform(value: number) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = '/\.0+$|(\.\d*[1-9])0+$/';
        let view = lookup.slice().reverse().find(function (item) {
            return value >= item.value;
        });
        return view ? (value / view.value).toFixed(1).replace(rx, "$1") + view.symbol : "0";
    }
}

@Pipe({
    name: 'youtubeDateAgo',
    pure: true
})
export class YoutubeDateAgoPipe implements PipeTransform {

    transform(value: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29)
                return 'Just now';
            const intervals = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago';
                    } else {
                        return counter + ' ' + i + 's ago';
                    }
            }
        }
        return value;
    }
}

@Pipe({ name: 'formattedDateTime' })
export class FormattedDateTime implements PipeTransform {
    transform(value: string) {
        if (value) {
            return moment(value).format('MM/DD/yyyy hh:mm A');
        }
        return '';
    }
}


