import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable()

export class PlayerTypeService {
    private apiBaseUrl = environment.apiBaseUrl + 'playerType';

    constructor(private http: HttpClient){}

    getList(){
        return this.http.get(`${this.apiBaseUrl}/list`);
    }

}