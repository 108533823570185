export class MediaAppDetailModel {
    public id: number;
    public title: string;
    public description: string;
    public type: number;
    public amount: number;
    public iconClass: string;
    public customButtonText: string;
    public customButtonLink: string;
    public iconUrl: string;
    public previewUrl: string;
    public typeString: string;
}