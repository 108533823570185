<section>
    <nz-spin [nzSpinning]="isLoading">
        <form #form="ngForm" nz-form (ngSubmit)="submit()">
            <div class="drawer-form-header-empty"></div>
            <div nz-row class="drawer-form-header">
                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                    <div class="heading-fs-18 mb-0">
                        <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
                            (click)="cancel()"></span>
                        New Media
                    </div>
                </div>
                <div nz-col nzSpan="12" class="text-right disp-web">
                    <button nz-button class="mr-3" (click)="cancel()">Cancel</button>
                    <button nz-button [disabled]="form.invalid" nzType="primary" type="submit">Save</button>
                </div>
                <div nz-col nzSpan="24">
                    <nz-divider></nz-divider>
                </div>
            </div>

            <div nz-row class="mb-3">
                <div nz-col nzSpan="24">
                    <div class="media-type-header">
                        <div nz-row>
                            <div nz-col nzLg="12" nzMd="12" nzXs="12" nzSm="12" class="content-center">
                                <div class="align-items-center">
                                    <img class="media-type-header-img-wrapper" [src]="mediaAppModel.iconUrl"
                                        alt="media-type">

                                    <span class="ml-2 fs-16 fw-bold">{{mediaAppModel.title}}</span>
                                </div>
                            </div>
                            <div nz-col nzLg="12" nzMd="12" nzXs="12" nzSm="12" class="media-type-header-content">
                                <app-content-text-view
                                    [contentType]="constants.pageContentType.mediaAppVimeoTop"></app-content-text-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div nz-row class="mobOne-action-m-b">
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0"
                                [nzValidateStatus]="mediaName.invalid && (mediaName.dirty || mediaName.touched) ? 'error' : ''">
                                <nz-form-label [nzRequired]="true">Media name</nz-form-label>
                                <nz-input-group>
                                    <input nz-input required placeholder="Enter your media name" name="mediaName"
                                        #mediaName="ngModel" [(ngModel)]="model.title" [minLength]="1" [maxLength]="100"
                                        appAlphaNumericsLevelThree />
                                </nz-input-group>
                                <div *ngIf="form.submitted || mediaName?.touched || mediaName?.dirty"
                                    class="validation-error-message">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="mediaName?.errors"></span>
                                    <span *ngIf="mediaName?.errors?.['required']" class="ml-1"> Media
                                        name is required. </span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-col nzSpan="24">
                    <div nz-row class="mt-2">
                        <div nz-col nzSpan="24">
                            Please select your desired Vimeo video.
                            <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                                nzTooltipTitle="Sign in with your Vimeo account to select and stream a video directly from your Vimeo library. You need a paid Vimeo account for this to work. Alternatively you can download the video and upload it directly to Manager." class="label-icon"></span>
                        </div>
                    </div>
                    <button class="mt-3" nz-button nzType="primary" (click)="selectVimeoVideo()" type="button"
                        *ngIf="selectedVideo == null || selectedVideo == undefined" [disabled]="model.vimeo.url">
                        <div class="btn-with-google">
                            <img src="./assets/images/media-types/icons/v2/Vimeo.svg" alt="vimeo-btn">
                        </div>
                        <span *ngIf="!model.vimeo.embeddedUrl && !accessToken">Sign In With Vimeo</span>
                        <span *ngIf="!model.vimeo.embeddedUrl && accessToken">Pick From Vimeo</span>
                    </button>
                    <nz-card class="mt-3" class="list-card" *ngIf="selectedVideo != null && selectedVideo.name">
                        <div nz-row [nzGutter]="[16]">
                            <div nz-col nzSpan="20">
                                <div class="d-flex">
                                    <div class="mr-3 align-items-center">
                                        <img [src]="mediaIcon" alt="list-icon" class="thumbnail">
                                    </div>
                                    <span>{{selectedVideo.name}}</span>
                                </div>
                            </div>
                            <div nz-col nzSpan="4" class="align-items-center justify-content-end">
                                <span nz-icon nzType="delete" nzTheme="outline" class="cursor-pointer ml-2"
                                    (click)="removeVideo()"></span>
                            </div>
                        </div>
                    </nz-card>
                    <div nz-row class="mt-2">
                        <div nz-col nzSpan="24" *ngIf="selectedVideo != null && selectedVideo.name">
                            <button type="button" nz-button nzType="primary" (click)="selectVimeoVideo()">
                                <span nz-icon nzType="reload" nzTheme="outline"></span>
                                Change Video
                            </button>
                        </div>
                    </div>
                    <div nz-col nzSpan="24"
                        *ngIf="(selectedVideo == null || selectedVideo == undefined) && !model.vimeo.embeddedUrl">
                        <nz-divider nzPlain nzText="Or"></nz-divider>
                    </div>

                    <div nz-row class="mb-3" *ngIf="!model.vimeo.authorizedBy || !model.vimeo.embeddedUrl">
                        <div nz-col nzSpan="24">
                            <div class="input-container">
                                <nz-form-item>
                                    <nz-form-control class="mb-0"
                                        [nzValidateStatus]="url.invalid && (url.dirty || url.touched) ? 'error' : ''">
                                        <nz-form-label [nzRequired]="true">Add the Embed URL from Vimeo
                                            without signing in
                                            <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                                                nzTooltipTitle="Paste the embed URL of a Vimeo video here to display it without signing in. Vimeo requires the embedded videos be hosted on a paid account."
                                                class="label-icon"></span>
                                        </nz-form-label>
                                        <nz-input-group>
                                            <input nz-input required placeholder="Enter URL"
                                                [(ngModel)]="model.vimeo.url" name="url" #url="ngModel" [minLength]="1"
                                                [maxLength]="1000" autocomplete="off"
                                                [pattern]="appUtils.urlRegexPattern"
                                                [disabled]="model.vimeo.embeddedUrl" (ngModelChange)="updateUrl()" />
                                        </nz-input-group>
                                        <div *ngIf="form.submitted || url?.touched || url?.dirty"
                                            class="validation-error-message">
                                            <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                class="validation-icon mt-1" *ngIf="url?.errors"></span>
                                            <span *ngIf="url?.errors?.['required']" class="ml-1"> URL is
                                                required </span>
                                            <span *ngIf="url?.errors?.['pattern']" class="ml-1"> Invalid
                                                URL </span>
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div nz-row class="mt-3">
                        <div nz-col nzSpan="24" class="align-items-center">
                            <label for="" class="mb-0 mr-3">Loop this video?</label>
                            <span><nz-switch [(ngModel)]="model.vimeo.loopEnabled" name="loopvideo"
                                    #loopVideo="ngModel"> </nz-switch>
                            </span>
                        </div>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mt-3">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Playlist(s)
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                                        nzTooltipTitle="Select Playlist" class="label-icon"></span>
                                </nz-form-label>
                                <nz-select name="displayList" [(ngModel)]="selectedDisplayLists" [nzShowArrow]="true"
                                    nzPlaceHolder="Select Playlist" nzMode="multiple" [nzMaxTagCount]="0"
                                    [nzMaxTagPlaceholder]="maxTagPlaceholder">
                                    <nz-option *ngFor="let item of displayLists" [nzValue]="item"
                                        nzLabel="{{item.value}}"></nz-option>
                                    <nz-option *ngIf="displayLists.length == 0" nzDisabled
                                        nzLabel="No Playlist Added."></nz-option>
                                </nz-select>
                                <ng-template #maxTagPlaceholder let-selected>
                                    {{ selected.length }} Item selected
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mt-2" *ngIf="selectedDisplayLists.length > 0">
                    <div class="selected-chip-container">
                        <nz-tag nzMode="closeable" class="selected-tag" (click)="removeDisplayList(item)"
                            *ngFor="let item of selectedDisplayLists">
                            <span class="selected-tag-content">{{item.value}}</span></nz-tag>
                    </div>
                </div>

                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Group
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip nzTooltipTitle="Groups allow you to share things within manager with other people."></span>
                                </nz-form-label>
                                <nz-select nzPlaceHolder="Select group" name="groupId" #groupId="ngModel"
                                    [(ngModel)]="model.groupId" (ngModelChange)="onGroupChange()" [nzDisabled]="true">
                                    <nz-option *ngFor="let item of subscriptionPlans" [nzValue]="item.keyInt"
                                        nzLabel="{{item.value}}"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Folder
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip nzTooltipTitle="Organize items in Manager with folders."></span>
                                </nz-form-label>
                                <nz-select nzPlaceHolder="Select folder" name="folderId" [(ngModel)]="model.folderId">
                                    <nz-option *ngFor="let item of folders" [nzValue]="item.keyInt"
                                        nzLabel="{{item.value}}"></nz-option>
                                    <nz-option *ngIf="folders.length == 0" nzLabel="No Folder Added" nzValue="disabled"
                                        nzDisabled></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mb-3">
                    <div nz-row>
                        <div nz-col nzSpan="24">
                            <div class="align-items-center">
                                <label class="mr-3" for="" class="fs-16 mb-0 fw-bold">Schedule</label>
                                <span class="ml-3"><nz-switch [(ngModel)]="isScheduled" (ngModelChange)="onSchedule()"
                                        name="schedule" #schedule="ngModel"></nz-switch></span>
                            </div>
                            <div nz-row *ngIf="isScheduled">
                                <div nz-col nzSpan="24">
                                    <nz-divider></nz-divider>
                                </div>
                            </div>
                            <div nz-row class="mt-2 ml-2" *ngIf="isScheduled">
                                <div nz-col nzSpan="24">
                                    <nz-radio-group [(ngModel)]="model.isDaily" name="isDaily" #isDaily="ngModel"
                                        (ngModelChange)="scheduleTypeChanged($event)">
                                        <label nz-radio [nzValue]="true">Daily</label>
                                        <label nz-radio [nzValue]="false">Date range</label>
                                    </nz-radio-group>
                                </div>
                            </div>

                            <div nz-row [nzGutter]="[16]" class="mt-2" *ngIf="isScheduled">
                                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                    <div class="input-container">
                                        <nz-form-item>
                                            <nz-form-control class="mt-3 mb-0">
                                                <nz-form-label>Start time</nz-form-label>
                                                <nz-time-picker [nzUse12Hours]="true" [nzAllowEmpty]="false"
                                                    (ngModelChange)="isStartTimeValid($event)" class="cursor-pointer"
                                                    [(ngModel)]="scheduleStartTime" name="startTime"
                                                    #startTime="ngModel" nzFormat="hh:mm a" nzPlaceHolder="Select"
                                                    required>
                                                </nz-time-picker>
                                                <div *ngIf="form.submitted || startTime?.touched"
                                                    class="validation-error-message">
                                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                        class="validation-icon mt-1" *ngIf="startTime?.errors"></span>
                                                    <span *ngIf="startTime?.errors?.['required']" class="ml-1">Start
                                                        time is
                                                        required</span>
                                                </div>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                    <div class="input-container">
                                        <nz-form-item>
                                            <nz-form-control class="mt-3 mb-0">
                                                <nz-form-label>End time</nz-form-label>
                                                <nz-time-picker [nzUse12Hours]="true" class="cursor-pointer"
                                                    (ngModelChange)="isTimeValid($event)" [nzAllowEmpty]="false"
                                                    [(ngModel)]="scheduleEndTime" name="endTime" #endTime="ngModel"
                                                    nzFormat="hh:mm a" nzPlaceHolder="Select" required>
                                                </nz-time-picker>
                                                <div *ngIf="form.submitted || endTime?.touched"
                                                    class="validation-error-message">
                                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                        class="validation-icon mt-1"
                                                        *ngIf="(endTime?.errors) || (!endTime?.errors?.['required'] && !isEndTimeValid)"></span>
                                                    <span *ngIf="endTime?.errors?.['required']" class="ml-1">End
                                                        time is required</span>
                                                    <span *ngIf="!endTime?.errors?.['required'] && !isEndTimeValid"
                                                        class="ml-1">Please select any future time</span>
                                                </div>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                            <div nz-row [nzGutter]="[16]" *ngIf="isScheduled && !model.isDaily">
                                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                    <div class="input-container">
                                        <nz-form-item>
                                            <nz-form-control class="mt-3 mb-0">
                                                <nz-form-label>Start date</nz-form-label>
                                                <nz-date-picker nzFormat="MM/dd/YYYY" name="startDate"
                                                    [nzAllowClear]="false" [(ngModel)]="model.startDate"
                                                    #startDate="ngModel" [nzDisabledDate]="disableStartDate"
                                                    nzPlaceHolder="Select" class="cursor-pointer"></nz-date-picker>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                    <div class="input-container">
                                        <nz-form-item>
                                            <nz-form-control class="mt-3 mb-0">
                                                <nz-form-label>End date</nz-form-label>
                                                <nz-date-picker nzFormat="MM/dd/YYYY" name="endDate"
                                                    [nzAllowClear]="false" [(ngModel)]="model.endDate"
                                                    #endDate="ngModel" [nzDisabledDate]="disableEndDate"
                                                    nzPlaceHolder="Select" class="cursor-pointer"></nz-date-picker>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mt-2 mb-3">
                    <div class="align-items-center">
                        <label class="mr-3" for="" class="fs-16 mb-0 fw-bold">Show this media</label>
                        <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon" nz-tooltip
                            nzTooltipTitle="Show or Hide Media"></span>
                        <span class="ml-3"><nz-switch name="isEnabled"
                                [(ngModel)]="model.vimeo.isEnabled"></nz-switch></span>
                    </div>
                </div>

                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0">
                                <nz-form-label>Notes</nz-form-label>
                                <nz-textarea-count [nzMaxCharacterCount]="100">
                                    <textarea rows="2" nz-input placeHolder="Enter Notes" name="description"
                                        #description="ngModel" [(ngModel)]="model.description"
                                        [maxlength]="100"></textarea>
                                </nz-textarea-count>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>

            <div nz-row class="disp-mob mobOne-action-bar">
                <div nz-col nzSpan="24" class="text-right">
                    <nz-card>
                        <div nz-row [nzGutter]="[16]">
                            <div nz-col nzSpan="12">
                                <button nz-button class="btn-full" type="button" (click)="cancel()">Cancel</button>
                            </div>
                            <div nz-col nzSpan="12">
                                <button nz-button class="btn-full" nzType="primary"
                                    [disabled]="form.invalid">Save</button>
                            </div>
                        </div>
                    </nz-card>
                </div>
            </div>
        </form>

        <nz-drawer #drawer [nzClosable]="false" [nzVisible]="visible" nzPlacement="right" class="custom-drawer">
            <ng-container *nzDrawerContent>
                <app-vimeo-video (closeDrawer)="closeVimeoDrawer($event)" *ngIf="searchVideo"
                    [accessToken]="accessToken"></app-vimeo-video>
            </ng-container>
        </nz-drawer>
    </nz-spin>
</section>