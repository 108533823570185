<div (dndDrop)="onDrop($event, medias)" dndDropzone dndEffectAllowed="all">
    <div dndPlaceholderRef> </div>

    <nz-spin [nzSpinning]="isLoading">
        <div *ngFor="let item of medias;let i= index;" [dndDraggable]="item" dndEffectAllowed="all"
            (dndMoved)="onDragged(item, medias)">
            <nz-card class="list-card mt-2">
                <nz-collapse [nzBordered]="false" class="custom-collapse">
                    <nz-collapse-panel [nzHeader]="title" [(nzActive)]="item.isActive" [nzShowArrow]="false"
                        (nzActiveChange)="onCollapseChange($event, item)">
                        <form #form="ngForm" nz-form (ngSubmit)="form.form.valid" >
                            <ng-template #title>
                                <div nz-row [nzGutter]="[16]">
                                    <div nz-col nzXs="11" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
                                        <div class="d-flex align-items-center cursor-pointer">
                                            <div class="cursor-move"><img src="./assets/icons/re-order.svg" alt="icon"
                                                    class="mr-2"></div>
                                            <div class="mr-2"
                                                [ngClass]="{'list-img-wrapper': !item.urlError, 'list-default-wrapper': item.urlError}">
                                                <img *ngIf="!item.urlError" [src]="item.iconUrl" alt="list-icon"
                                                    (error)="onMediaUrlError(item)"
                                                    [ngClass]="{'hidden': item.urlError}">
                                            </div>
                                            <div class="text-ellipsis">{{item.title}}</div>
                                        </div>
                                    </div>

                                    <div nz-col nzXs="3" nzSm="6" nzMd="6" nzLg="4" nzXl="6" class="align-items-center"
                                    *ngIf="item.videoEncodingStatus && item.videoEncodingStatus !== 3">
                                    <span nz-icon [nzType]="'loading'" class="icon-primary" nz-tooltip
                                        nzTooltipTitle="Video optimization in progress. We'll notify you when it's complete."></span>
                                         &nbsp;<span>Optimizing</span>
                                    </div>

                                    <div nz-col nzXs="3" nzSm="6" nzMd="6" nzLg="4" nzXl="6" class="align-items-center"
                                        *ngIf="!item.videoEncodingStatus || item.videoEncodingStatus === 3">
                                        <div *ngIf="!item.isActive">
                                            <div class="d-flex">
                                                <span nz-icon nzType="check-circle" nzTheme="fill"
                                                    *ngIf="item.status === 1 && !item.isScheduled"
                                                    class="status-icon-success mr-2"></span>
                                                <span *ngIf="item.status === 1 && !item.isScheduled"
                                                    class="disp-web">Active</span>
                                            </div>
                                            <div class="d-flex">
                                                <span nz-icon nzType="close-circle" nzTheme="fill"
                                                    *ngIf="item.status === 2" class="status-icon-danger mr-2"></span>
                                                <span *ngIf="item.status === 2" class="disp-web">In-active</span>
                                            </div>
                                            <div class="d-flex">
                                                <span nz-icon nzType="check-circle" nzTheme="fill"
                                                    *ngIf="item.status === 1 && item.isScheduled"
                                                    class="status-icon-primary mr-2"></span>
                                                <span *ngIf="item.status === 1 && item.isScheduled"
                                                    class="disp-web">Scheduled</span>
                                            </div>
                                            <div class="d-flex">
                                                <span nz-icon nzType="clock-circle" nzTheme="fill"
                                                    *ngIf="item.status === 4" class="status-icon-primary mr-2"></span>
                                                <span *ngIf="item.status === 4" class="disp-web">Scheduled</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col nzSpan="2" class="align-items-center">
                                        <span *ngIf="!item.isActive">{{item.lengthInSeconds}}s</span>
                                    </div>

                                    <div class="d-d-action-btn">
                                        <div *ngIf="!item.isActive">
                                            <div class="d-flex">
                                                <div class="pointer-events-all"><img src="./assets/icons/edit.svg"
                                                        alt="list-icon" class="mr-2 cursor-pointer filter_darkmode"></div>
                                                <span nz-icon nzType="delete" nzTheme="outline"
                                                    (click)="removeMedia(i, item)"
                                                    class="cursor-pointer pointer-events-all ml-2"></span>
                                            </div>
                                        </div>
                                        <div *ngIf="item.isActive">
                                            <span nz-icon nzType="check" nzTheme="outline"
                                                class="mr-3 pointer-events-all" (click)="confirmUpdateMedia($event, item)"></span>
                                            <span nz-icon nzType="close" nzTheme="outline" class="pointer-events-all"
                                                (click)="close($event, item)"></span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <div nz-row [nzGutter]="[16]" class="mt-3 mb-3" *ngIf="isModelLoaded">
                                <div nz-col nzSpan="12">
                                    <div class="input-container">
                                        <nz-form-item>
                                            <nz-form-control class="mb-0">
                                                <nz-form-label>Media status</nz-form-label>
                                                <i nz-icon nzType="check-circle" nzTheme="fill"
                                                    class="form-field-prefix-icon status-icon-success"
                                                    *ngIf="model.isEnabled"></i>
                                                <i nz-icon nzType="close-circle" nzTheme="fill"
                                                    class="form-field-prefix-icon status-icon-danger"
                                                    *ngIf="!model.isEnabled"></i>
                                                <nz-select class="header-input" name="status"
                                                    [(ngModel)]="model.isEnabled">
                                                    <nz-option [nzValue]="true" nzLabel="Enabled"></nz-option>
                                                    <nz-option [nzValue]="false" nzLabel="Hidden"></nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <div nz-col nzSpan="12">
                                    <div class="input-container">
                                        <nz-form-item>
                                            <nz-form-control class="mb-0">
                                                <nz-form-label [nzRequired]="true">Display time</nz-form-label>
                                                <nz-input-group>
                                                    <input nz-input placeholder="Enter your display time"
                                                        name="displayTime" #displayTime="ngModel"
                                                        [(ngModel)]="item.lengthInSeconds" />
                                                </nz-input-group>
                                                <div *ngIf="form.submitted || displayTime?.touched || displayTime?.dirty"
                                                    class="validation-error-message">
                                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                        class="validation-icon mt-1" *ngIf="displayTime?.errors"></span>
                                                    <span *ngIf="displayTime?.errors?.['required']" class="ml-1"> How
                                                        long
                                                        do
                                                        you want
                                                        this media to show on this playlist? You must enter time in
                                                        between
                                                        1 to
                                                        21600 seconds. </span>
                                                </div>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                            <div nz-row *ngIf="isModelLoaded">
                                <div nz-col nzSpan="24">
                                    <div class="align-items-center">
                                        <label class="mr-3" for="" class="fs-16 mb-0 fw-bold">Schedule</label>
                                        <span class="ml-3"><nz-switch [(ngModel)]="isScheduled" name="schedule"
                                                (ngModelChange)="onSchedule()" #schedule="ngModel"></nz-switch></span>
                                    </div>
                                    <div nz-row class="mt-3 mb-3 ml-2" *ngIf="isScheduled">
                                        <div nz-col nzSpan="24">
                                            <nz-radio-group [(ngModel)]="model.isDaily" name="isDaily"
                                                #isDaily="ngModel" (ngModelChange)="scheduleTypeChanged($event)">
                                                <label nz-radio [nzValue]="true">Daily</label>
                                                <label nz-radio [nzValue]="false">Date
                                                    Range</label>
                                            </nz-radio-group>
                                        </div>
                                    </div>

                                    <div nz-row [nzGutter]="[16]" class="mt-2" *ngIf="isScheduled">
                                        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                            <div class="input-container">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <nz-form-label> Start Time </nz-form-label>
                                                        <nz-time-picker [nzUse12Hours]="true" [nzAllowEmpty]="false"
                                                            (ngModelChange)="isStartTimeValid($event)"
                                                            class="cursor-pointer" [(ngModel)]="scheduleStartTime"
                                                            name="startTime" #startTime="ngModel" nzFormat="hh:mm a"
                                                            nzPlaceHolder="Select" required>
                                                        </nz-time-picker>
                                                        <div *ngIf="form.submitted || startTime?.touched"
                                                            class="validation-error-message">
                                                            <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                                class="validation-icon mt-1"
                                                                *ngIf="startTime?.errors"></span>
                                                            <span *ngIf="startTime?.errors?.['required']"
                                                                class="ml-1">Start
                                                                time is
                                                                required</span>
                                                        </div>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                            <div class="input-container">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <nz-form-label> End Time </nz-form-label>
                                                        <nz-time-picker [nzUse12Hours]="true" class="cursor-pointer"
                                                            (ngModelChange)="isTimeValid($event)" [nzAllowEmpty]="false"
                                                            [(ngModel)]="scheduleEndTime" name="endTime"
                                                            #endTime="ngModel" nzFormat="hh:mm a" nzPlaceHolder="Select"
                                                            required>
                                                        </nz-time-picker>
                                                        <div *ngIf="form.submitted || endTime?.touched"
                                                            class="validation-error-message">
                                                            <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                                class="validation-icon mt-1"
                                                                *ngIf="(endTime?.errors) || (!endTime?.errors?.['required'] && !isEndTimeValid)"></span>
                                                            <span *ngIf="endTime?.errors?.['required']" class="ml-1">End
                                                                time is required</span>
                                                            <span
                                                                *ngIf="!endTime?.errors?.['required'] && !isEndTimeValid"
                                                                class="ml-1">Please select any future time</span>
                                                        </div>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-row [nzGutter]="[16]" *ngIf="isScheduled && !model.isDaily">
                                        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                            <div class="input-container">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <nz-form-label> Start Date</nz-form-label>
                                                        <nz-date-picker nzFormat="MM/dd/YYYY" name="startDate"
                                                            [nzAllowClear]="false" [(ngModel)]="model.startDate"
                                                            #startDate="ngModel" [nzDisabledDate]="disableStartDate"
                                                            nzPlaceHolder="Select" required
                                                            class="cursor-pointer"></nz-date-picker>
                                                        <div *ngIf="form.submitted || startDate?.touched"
                                                            class="validation-error-message">
                                                            <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                                class="validation-icon mt-1"
                                                                *ngIf="startDate?.errors"></span>
                                                            <span *ngIf="startDate?.errors?.['required']"
                                                                class="ml-1">Start
                                                                date is
                                                                required</span>
                                                        </div>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                            <div class="input-container">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <nz-form-label> End Date</nz-form-label>
                                                        <nz-date-picker nzFormat="MM/dd/YYYY" name="endDate"
                                                            [nzAllowClear]="false" [(ngModel)]="model.endDate"
                                                            #endDate="ngModel" [nzDisabledDate]="disableEndDate"
                                                            nzPlaceHolder="Select" required
                                                            class="cursor-pointer"></nz-date-picker>
                                                        <div *ngIf="form.submitted || endDate?.touched"
                                                            class="validation-error-message">
                                                            <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                                class="validation-icon mt-1"
                                                                *ngIf="endDate?.errors"></span>
                                                            <span *ngIf="endDate?.errors?.['required']"
                                                                class="ml-1">End
                                                                date is
                                                                required</span>
                                                        </div>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </nz-collapse-panel>
                </nz-collapse>
            </nz-card>
        </div>
    </nz-spin>
</div>