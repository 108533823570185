export class YoutubeVideoModel {
    type: string;
    videoId: string;
    title: string;
    thumbnails: Array<string>;
    publishTime: string;
    viewCount:number;
    likeCount:number;
    dislikeCount:number;

    channelId: string;
    channelTitle: string;
    channelThumbnail: string;

    constructor() {
        this.thumbnails = new Array<string>();
    }
}
