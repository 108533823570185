import { SelectListItemModel } from 'src/models';
import {MediaModel} from './media.model';

export class DisplayListModel {
    id: number;
    name: string;
    description: string;
    groupId: number;
    groupName: string;
    folderId: number;
    displayIds: Array<number>;
    transitionType: string;
    userMediaApps: Array<MediaModel>;
    public displays: Array<SelectListItemModel>;

    constructor(){
        this.userMediaApps = new Array<MediaModel>();
        this.displayIds = new Array<number>();
    }
}