<section>
    <nz-spin [nzSpinning]="isLoading">
        <div nz-row>
            <div nz-col nzSpan="24">
                Let us know what you like or don't like below. This will help us understand how to make even more better
                than ever.
            </div>
        </div>

        <div nz-row class="mt-3">
            <div nz-col nzSpan="24">
                If you're having technical issues or have found a bug <span><a href="https://displaynow.io/contact"
                        target="blank" class="text-underline">contact our support
                        team</a>.</span>
            </div>
        </div>

        <form #f="ngForm" nz-form (ngSubmit)="f.form.valid && submit()" name="form" noValidate>
            <div nz-row class="mt-3">
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Select feedback</nz-form-label>
                                <nz-select nzPlaceHolder="Select Feedback" [(ngModel)]="model.feedbackTypeId" 
                                    name="feedbackType" #feedbackType="ngModel" (ngModelChange)="onFeedbackTypeChange()" required>
                                    <nz-option *ngFor="let feedbackType of feedbackTypes"
                                        nzLabel="{{feedbackType.value}}" [nzValue]="feedbackType.keyInt"></nz-option>
                                </nz-select>
                                <div *ngIf="f.submitted || feedbackType?.touched || feedbackType?.dirty"
                                    class="validation-error-message d-flex align-items-start">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="feedbackType?.errors"></span>
                                    <span class="validation-error-message m-t-2"
                                        *ngIf="feedbackType.errors?.['required']">
                                        Feedback type is required.
                                    </span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label *ngIf="model.feedbackTypeId == 4 || model.feedbackTypeId == null">Let us know what you'd like to improve</nz-form-label>
                                <nz-form-label *ngIf="model.feedbackTypeId == 1 || model.feedbackTypeId == 3">Let us know what's on your mind</nz-form-label>
                                <nz-form-label *ngIf="model.feedbackTypeId == 2">Describe the bug or issue</nz-form-label>
                                <nz-textarea-count [nzMaxCharacterCount]="1000">
                                    <textarea rows="2" nz-input placeholder="Enter here" [(ngModel)]="model.comment" [maxLength]="1000"
                                        name="comment" #comment="ngModel" required></textarea>
                                </nz-textarea-count>
                                <div *ngIf="f.submitted || comment?.touched || comment?.dirty"
                                    class="validation-error-message d-flex align-items-start">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="comment?.errors"></span>
                                    <span class="validation-error-message m-t-2" *ngIf="comment.errors?.['required']">
                                        Comment is required.
                                    </span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-col nzSpan="24" *ngIf="model.attachmentRequired">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Attachment</nz-form-label>
                                <nz-input-group [nzSuffix]="suffixIcon">
                                    <input nz-input [(ngModel)]="selectedAttachment" name="attachmentName" placeholder="Upload image"
                                    #fileUploadElem  />
                                </nz-input-group>
                                <input nz-input type="file" (change)="onFileSelected($event)" [(ngModel)]="attachmentName"
                                        name="attachment" #attachment="ngModel" #fileInput class="d-none" accept=".png, .jpg" />
                                <ng-template #suffixIcon>
                                    <span nz-icon nzType="delete" nzTheme="outline" 
                                     class="cursor-pointer" *ngIf="attachmentName" (click)="deleteAttachment()"></span>
                                    <span nz-icon nzType="upload" nzTheme="outline" 
                                        (click)="fileInput.click()" class="cursor-pointer"></span>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-col nzSpan="24" class="ml-2">
                    <label nz-checkbox  class="text-gray"> <span>I'd like to help improve Duke Technologies products by joining the Product Research Group</span></label>
                </div>
            </div>

            <div nz-row class="mt-3" *ngIf="model.feedbackTypeId == 4">
                <div nz-col nzSpan="24">
                    <label nz-checkbox [(ngModel)]="model.willingToJoinPrg" name="joinPrg" #JoinPrg="ngModel">
                        <span class="text-muted">I'd like to help improve Duke Technologies products by joining
                            the Product Research Group</span>
                    </label>
                </div>
            </div>

            <div nz-col nzSpan="24" class="text-right mb-2 mt-2">
                <button nz-button class="mr-2" type="button" (click)="cancel()">Cancel</button>
                <button nz-button nzType="primary">Submit</button>
            </div>
          
        </form>
    </nz-spin>
</section>