export class BroadcastListItemModel {
    id: number;
    name: string;
    isEnabled: boolean;
    defaultUserMediaAppType: string;
    defaultUserMediaAppUrl: string;
    broadcastStatusName: string;
    urlError: boolean;
    showDefaultIcon: boolean;
    isSelected: boolean;
    status: number;
    broadcastStatus: any;

    constructor(){
        this.urlError = false;
        this.isSelected = false;
    }
}