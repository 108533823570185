import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FilterModel } from "src/models";

@Injectable()
export class MediaAppService {
    private apiEndPoint = environment.apiBaseUrl + 'api/mediaApp';

    constructor(private http: HttpClient) { }

    get(id: number): Observable<any> {
        return this.http.get(`${this.apiEndPoint}/` + id);
    }

    getList(): Observable<any> {
        return this.http.get(`${this.apiEndPoint}/list`);
    }

    getPagedResult(model: FilterModel) {
        return this.http.get(this.apiEndPoint + '/paged-result?' + model.toQueryString());
    }
}