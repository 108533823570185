export class UserMediaAppImageUpsertModel{
    public id: string;
    public userMediaAppId: number;
    public fileName: string;
    public originalFileName: string;
    public fileUrl: string;
    public lengthInSeconds: string;
    public isEnabled: boolean;
    public displayOrder: number;
    public size: string;

    public file: File;
    public isUploadingImage: boolean;
    public fileUploadPercent: number;
    public isFileUploaded: boolean;
    public hasRotated: boolean;
    public rotationDegree: number;

    public isDefaultImage: boolean;

    constructor() {
        this.isEnabled = true;
        this.isUploadingImage = true;
        this.fileUploadPercent = 0;
        this.originalFileName = '';
        this.displayOrder = 0;
        this.isDefaultImage = false;
    }
}