<section>
    <div nz-row class="mb-4">
        <div nz-col nzSpan="12">
            <div class="heading-fs-18 mb-0">Vimeo videos</div>
        </div>
        <div nz-col nzSpan="12" class="text-right">
            <button nz-button type="button" (click)="cancel()">Cancel</button>
        </div>
        <div nz-col nzSpan="24">
            <nz-divider></nz-divider>
        </div>
    </div>
    <div nz-row class="mb-2">
        <div nz-col nzSpan="24">
            <form nz-form>
                <div class="input-container mt-2">
                    <nz-form-item class="mb-0">
                        <nz-form-control class="header-input">
                            <nz-form-label>Search Video
                                <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                    nz-tooltip nzTooltipTitle="Search vimeo videos"></span>
                            </nz-form-label>
                            <nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="suffixIcon">
                                <input nz-input [(ngModel)]="vimeo.searchText" name="searchText" #searchText="ngModel"
                                    placeholder="Search here" (keydown.enter)="searchVimeoVideos()" />
                            </nz-input-group>
                            <ng-template #suffixIcon>
                                <span nz-icon nzType="close" nzTheme="outline" (click)="clearSearch()" class="cursor-pointer" *ngIf="vimeo.searchText"></span>
                            </ng-template>
                            <ng-template #prefixIcon>
                                <span nz-icon nzType="search" nzTheme="outline" class="cursor-pointer"
                                    (click)="searchVimeoVideos()"></span>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                </div>
            </form>
        </div>
    </div>

    <nz-spin [nzSpinning]="isLoading">
        <div nz-row class="mb-4" *ngFor="let video of vimeoVideos">
            <div class="content-row cursor-pointer list-item" (click)="selectVideo(video)">
                <div class="image-container">
                    <img [src]="video.pictures.base_link" alt="thumbnail">
                </div>
                <div class="content-wrapper">
                    <div>
                        {{video.name}}
                    </div>
                    <!-- <span class="text-muted">{{video.channelTitle}}</span>
                    <div class="fs-12 fw-bold">{{video.viewCount}} Views | {{video.publishTime |
                        youtubeDateAgo}}</div> -->
                </div>
            </div>
        </div>
        <div *ngIf="isModelLoaded && vimeoVideos.length == 0" class="text-center">
            <span class="text-muted fs-14">No video match your search.</span>
        </div>
    </nz-spin>
</section>