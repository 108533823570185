export class MediaListItemModel{
    id: number;
    typeString: string;
    title: string;
    iconUrl: string;
    mediaAppType:string;
    mediaAppTitle: string;
    status: number;
    isSelected: boolean;
    urlError: boolean;
    videoEncodingStatus:number;
    isScheduled: boolean;

    constructor(){
        this.isSelected = false;
        this.isScheduled = false;
    }
}