<section>
    <nz-spin [nzSpinning]="isLoading">
        <form nz-form #form="ngForm" (ngSubmit)="submit()">
            <div class="drawer-form-header-empty"></div>
            <div nz-row class="drawer-form-header">
                <div nz-col nzSpan="12">
                    <div class="heading-fs-18 mb-0">Edit Playlist</div>

                </div>
                <div nz-col nzSpan="12" class="text-right">
                    <button nz-button class="mr-3" type="button" (click)="cancel()">Cancel</button>
                    <button nz-button nzType="primary" type="submit" [disabled]="form.invalid">Update</button>
                </div>
                <div nz-col nzSpan="24">
                    <nz-divider></nz-divider>
                </div>
            </div>

            <div nz-row >
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control
                                [nzValidateStatus]="playlistName.invalid && (playlistName.dirty || playlistName.touched) ? 'error' : ''">
                                <nz-form-label [nzRequired]="true">Playlist name</nz-form-label>
                                <nz-input-group>
                                    <input nz-input required placeholder="Give your playlist a name"
                                        [(ngModel)]="model.name" [minlength]="1" [maxlength]="100" name="playlistName"
                                        #playlistName="ngModel" appAlphaNumericsLevelThree />
                                </nz-input-group>

                                <div *ngIf="form.submitted || playlistName?.touched || playlistName?.dirty"
                                    class="validation-error-message">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="playlistName?.errors"></span>
                                    <span *ngIf="playlistName?.errors?.['required']" class="ml-1"> Playlist
                                        name is required</span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mt-1">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Group
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip nzTooltipTitle="Select Group"></span>
                                </nz-form-label>
                                <nz-select [(ngModel)]="model.groupId" nzPlaceHolder="Select group" name="groupName"
                                    #groupName="ngModel" [nzDisabled]="true">
                                    <nz-option [nzValue]="item.keyInt" [nzLabel]="item.value"
                                        *ngFor="let item of subscriptionPlans"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Folder</nz-form-label>
                                <nz-select [(ngModel)]="model.folderId" nzPlaceHolder="Select folder" name="folder"
                                    #folderName="ngModel">
                                    <nz-option [nzValue]="item.keyInt" [nzLabel]="item.value"
                                        *ngFor="let item of folders"></nz-option>
                                    <nz-option nzValue="disabled" *ngIf="folders.length == 0" nzLabel="No Folder added"
                                        nzDisabled>No Folder added</nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-col nzSpan="24" class="mb-3">
                    <div class="d-flex">
                        <div class="w-100">
                            <div class="input-container">
                                <nz-form-item>
                                    <nz-form-control class="mb-0">
                                        <nz-form-label>
                                            <span class="text-ellipsis">Screen(s)
                                            </span>
                                            <span nz-icon nzType="exclamation-circle" nzTheme="outline"
                                                class="label-icon" nz-tooltip nzTooltipTitle="Select multiple screens"></span>
                                        </nz-form-label>
                                        <nz-select [(ngModel)]="model.displayIds" nzPlaceHolder="Select screens"
                                            [nzShowArrow]="true" name="displayList" nzMode="multiple"
                                            (ngModelChange)="onDisplaySelect($event)"
                                            (nzSelect)="onDisplaySelect($event)"
                                            (nzDeselect)="onDisplayDeSelect($event)"
                                            (nzSelectAll)="onDisplaySelectAll($event)"
                                            (nzDeselectAll)="onDisplayDeSelectAll($event)" [nzMaxTagCount]="0"
                                            [nzMaxTagPlaceholder]="maxTagPlaceholder">
                                            <nz-option [nzValue]="item.keyInt" nzLabel="{{item.value}}"
                                                *ngFor="let item of displays"></nz-option>
                                            <nz-option *ngIf="displays.length == 0" nzValue="disabled"
                                                nzLabel="No Screen Added" nzDisabled></nz-option>
                                        </nz-select>
                                        <ng-template #maxTagPlaceholder let-selected>
                                            <span *ngIf="selectedDisplays.length > 0">{{
                                                selectedDisplays.length }} <span *ngIf="selectedDisplays.length == 1">
                                                    Item
                                                </span> <span *ngIf="selectedDisplays.length > 1"> Items
                                                </span> selected
                                            </span>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <!-- <div class="pt-3 ml-3 justify-content-end">
                            <button nz-button type="button" (click)="openDisplayDrawer()" class="mt-2 form-field-btn" [disabled]="displays.length > 0">
                                <span nz-icon nzType="plus" nzTheme="outline"></span>Add New
                            </button>
                        </div> -->
                    </div>
                    <div nz-row class="mt-2 mb-2" *ngIf="selectedDisplays.length > 0">
                        <div nz-col nzSpan="24">
                            <div class="selected-chip-container">
                                <nz-tag *ngFor="let display of selectedDisplays" (nzOnClose)="removeDisplay(display)"
                                    nzMode="closeable" class="selected-tag "> <span
                                        class="selected-tag-content">{{display.value}}</span></nz-tag>
                            </div>
                        </div>
                    </div>
                </div>

                <div nz-col nzSpan="24">
                    <div class="d-flex">
                        <div class="w-100">
                            <div class="input-container">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-form-label>
                                            <span class="text-ellipsis">What media would you like to show?</span>
                                            <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                            nz-tooltip nzTooltipTitle="Add media to a playlist to show it in an ordered loop."></span>
                                        </nz-form-label>
                                        <nz-select nzMode="multiple" name="userMedia" nzPlaceHolder="Please select"
                                            [nzShowArrow]="true" [(ngModel)]="selectedUserMediaAppIds"
                                            (ngModelChange)="onUserMediaAppSelect($event)"
                                            (nzSelect)="onUserMediaAppSelect($event)"
                                            (nzDeselect)="onUserMediaAppDeSelect($event)"
                                            (nzSelectAll)="onUserMediaAppSelectAll($event)"
                                            (nzDeselectAll)="onUserMediaAppDeSelectAll($event)" [nzMaxTagCount]="0"
                                            [nzMaxTagPlaceholder]="maxMediaTagPlaceholder">
                                            <nz-option *ngFor="let option of userMediaApps" [nzLabel]="option.title"
                                                [nzValue]="option.id">
                                            </nz-option>
                                            <nz-option *ngIf="userMediaApps.length == 0" nzValue="disabled"
                                                nzLabel="No Media Added" nzDisabled></nz-option>
                                        </nz-select>
                                        <ng-template #maxMediaTagPlaceholder let-selected>
                                            <span *ngIf="selectedUserMediaApps.length > 0">{{
                                                selectedUserMediaApps.length }} <span
                                                    *ngIf="selectedUserMediaApps.length == 1"> Item
                                                </span> <span *ngIf="selectedUserMediaApps.length > 1"> Items
                                                </span> selected
                                            </span>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <div class="mt-2 ml-3 align-items-center justify-content-end disp-web-button"
                            *ngIf="editDisplaylistFromScreen">
                            <button nz-button type="button" (click)="openMediaStoreDarwer()" class="form-field-btn">
                                <span nz-icon nzType="plus" nzTheme="outline"></span>Add New
                            </button>
                        </div>
                    </div>
                </div>

                <div nz-col nzSpan="24" class="disp-mob">
                    <button nz-button type="button" (click)="openMediaStoreDarwer()" class="form-field-btn">
                        <span nz-icon nzType="plus" nzTheme="outline"></span>Add New
                    </button>
                </div>
                <div nz-col nzSpan="24" class="mb-3" *ngIf="selectedUserMediaAppSummaryListItems.length > 0">
                    <app-media-edit [selectedMedias]="selectedUserMediaAppSummaryListItems"
                        (deleteMedia)="deleteMedia($event)"></app-media-edit>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Transition between media</nz-form-label>
                                <nz-select [(ngModel)]="model.transitionType" nzPlaceHolder="Select a folder"
                                    name="transitionType" #transitionType="ngModel">
                                    <nz-option [nzValue]="transitionType.keyString" [nzLabel]="transitionType.value"
                                        aria-selected="true" *ngFor="let transitionType of transitionTypes"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
        </form>
    </nz-spin>
    <nz-drawer #drawer [nzClosable]="false" nzPlacement="right" [nzVisible]="isDrawerVisible" (nzOnClose)="close()"
        class="custom-drawer">
        <ng-container *nzDrawerContent>
            <app-media-store (closeDrawer)="closeMediaStoreDrawer($event)" *ngIf="showMediaDrawer" [selectedGroupId]="model.groupId"></app-media-store>
        </ng-container>
    </nz-drawer>

</section>