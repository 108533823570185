import { Component, EventEmitter, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { UserMediaAppYouTubeUpsertModel, YoutubeVideoModel } from "src/app/shared/models";
import { BaseService, UserMediaAppService } from "src/app/shared/services";

@Component({
    selector: 'app-youtube-search-video-drawer',
    templateUrl: './youtube-search-video.component.html'
})
export class YoutubeSearchVideoDrawerComponent {
    @Output() closeDrawer: EventEmitter<{ selectedVideo: YoutubeVideoModel, updateValue: boolean }> = new EventEmitter<{ selectedVideo: YoutubeVideoModel, updateValue: boolean }>();
    @Output() close: EventEmitter<any> = new EventEmitter();
    youtube = new UserMediaAppYouTubeUpsertModel();
    youtubeVideos = new Array<YoutubeVideoModel>();
    nextPageToken: string;
    showYouTubeView: boolean;
    isLoading: boolean = false;
    subscriptions = new Array<Subscription>();

    constructor(private userMediaAppService: UserMediaAppService,
        private baseService: BaseService) {
    }

    searchYoutubeVideos(isLoadMore: boolean = false) {
        if (!this.youtube.searchText || this.youtube.searchText.length < 3) {
            this.youtubeVideos = new Array<YoutubeVideoModel>();
            return;
        }
        this.isLoading = true;
        const requestSubscription =
            this.userMediaAppService.getYoutubeVideos(this.youtube.searchText, this.nextPageToken)
                .subscribe({
                    next: (data: any) => {
                        this.isLoading = false;
                        if (!isLoadMore) {
                            this.youtubeVideos = new Array<YoutubeVideoModel>();
                        }
                        this.nextPageToken = data.nextPageToken;
                        data.items.forEach(item => {
                            const resultItem = new YoutubeVideoModel();
                            resultItem.type = item.id.kind;
                            resultItem.videoId = item.id.videoId;
                            resultItem.title = item.snippet.title;
                            resultItem.channelId = item.snippet.channelId;
                            resultItem.channelTitle = item.snippet.channelTitle;
                            resultItem.publishTime = item.snippet.publishTime;
                            resultItem.thumbnails.push(item.snippet.thumbnails.default.url);
                            resultItem.thumbnails.push(item.snippet.thumbnails.high.url);
                            resultItem.thumbnails.push(item.snippet.thumbnails.medium.url);
                            this.youtubeVideos.push(resultItem);
                        });
                        this.youtubeVideos.forEach(video => {
                            this.getVideoStatistics(video);
                            this.getChannelsSnippet(video);
                        });
                    },
                    error: (error: any) => {
                        this.isLoading = false;
                        this.baseService.error(error.error.error.message);
                    }
                });

        this.subscriptions.push(requestSubscription);
    }

    getVideoStatistics(video: YoutubeVideoModel) {
        this.userMediaAppService.getYoutubeVideoStatistics(video.videoId)
            .subscribe({
                next: (res: any) => {
                    if (res.items) {
                        if (res.items[0]) {
                            video.viewCount = res.items[0].statistics.viewCount;
                            video.likeCount = res.items[0].statistics.likeCount;
                            video.dislikeCount = res.items[0].statistics.dislikeCount;
                        }
                    }
                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    getChannelsSnippet(video: YoutubeVideoModel) {
        this.userMediaAppService.getYoutubeChannelsSnippet(video.channelId)
            .subscribe(
                (res: any) => {
                    if (res.items) {
                        if (res.items[0]) {
                            video.channelThumbnail = res.items[0].snippet.thumbnails.default.url;
                        }
                    }
                });
    }

    selectVideo(item: YoutubeVideoModel): void {
        this.closeDrawer.emit({ selectedVideo: item, updateValue: true });
    }

    clearSearch(): void {
        this.youtube.searchText = '';
    }

    cancel(): void {
        this.close.emit();
    }
}