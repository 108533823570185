<section>
    <div class="toolbar">
        <img class="brand-logo" src="./assets/images/logodark.png" alt="">
    </div>
    <div class="text-container mt-5" *ngIf="linkOrInvitationExpired">
        <div nz-col nzSpan="24" class="text-center">
            <div>
                <img class="lightmode_img" src="./assets/images/dashboard-icons/no-screen.svg" alt="">
                <img class="darkmode_img" src="./assets/images/dashboard-icons/no-screen-darkmode.svg" alt="">
            </div>
            <div class="mt-3 heading-fs-18">It seems like the links is expired or the request has been cancelled.</div>
            <div class="mt-4">
                <button nz-button nzType="primary" [routerLink]="['/group/manage']">Go to the groups</button>
            </div>
        </div>
    </div>
    <div class="text-container mt-5" *ngIf="!groupExists">
        <div nz-col nzSpan="24" class="text-center">
            <div>
                <img class="lightmode_img" src="./assets/images/dashboard-icons/no-screen.svg" alt="">
                <img class="darkmode_img" src="./assets/images/dashboard-icons/no-screen-darkmode.svg" alt="">
            </div>
            <div class="mt-3 heading-fs-18">It seems like the group has been deleted by the owner</div>

            <div class="mt-4">
                <button nz-button nzType="primary" [routerLink]="['/group/manage']">Go to the groups</button>
            </div>
        </div>
    </div>
</section>