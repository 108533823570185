import { Injectable } from "@angular/core";
import { FeedbackResult, IPasswordStrengthMeterService } from "angular-password-strength-meter";

@Injectable()
export class CustomPsmServiceService extends IPasswordStrengthMeterService {
    score(password: string): number {
        // Calculate score based on password characteristics (same logic as before)
        let score = 0;

        // Minimum length (add 1 point for each additional character)
        score += Math.max(password.length - 8, 0);

        // Character types (add points for different types)
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSymbols = /[!@#$%^&*()_+\-=\[\]{};':",./<>?|\\]/.test(password);
        score += (hasUppercase ? 1 : 0) + (hasLowercase ? 1 : 0) + (hasNumbers ? 1 : 0) + (hasSymbols ? 1 : 0);

        // Repeated characters (deduct points for consecutive repetitions)
        const repeatPenalty = password.split('').reduce((acc, char, index, chars) => {
            if (index > 0 && chars[index] === chars[index - 1]) {
                return acc - 1; // Deduct 1 for each repetition
            }
            return acc;
        }, 0);
        score += repeatPenalty;

        // Enforce a minimum score of 0 and a maximum of 4
        return Math.max(0, Math.min(score, 4));
    }

    // Implement empty methods (stubs) for unused functionality:
    scoreWithFeedback(password: string): FeedbackResult {
        return { score: this.score(password), feedback: { warning: '', suggestions: [] } };
    }

    scoreAsync(password: string): Promise<number> {
        return Promise.resolve(this.score(password));
    }

    scoreWithFeedbackAsync(password: string): Promise<FeedbackResult> {
        return Promise.resolve(this.scoreWithFeedback(password));
    }
}