import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, viewChild } from '@angular/core';
import { AppUtils } from 'src/helpers';
import { NzDrawerComponent } from 'ng-zorro-antd/drawer';
import { DisplayListUserMediaAppModel, FilterModel, ListItemsFilterModel, UserMediaAppEditModel, UserMediaAppSummaryModel } from 'src/app/shared/models';
import { BaseService, DisplayListService, UserMediaAppService } from 'src/app/shared/services';
import { toNumber } from 'ng-zorro-antd/core/util';

@Component({
    selector: 'app-media-list-multi-select',
    templateUrl: './media-list-multi-select.component.html',
    styleUrls: ['./media-list-multi-select.component.scss']
})
export class MediaListMultiSelectComponent implements OnChanges, OnInit {
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
    @Input() medias: Array<UserMediaAppSummaryModel> = new Array<UserMediaAppSummaryModel>();
    @Input() selectedMediaList: Array<UserMediaAppSummaryModel> = new Array<UserMediaAppSummaryModel>();
    @Input() selectedMediaIds: Array<number> = new Array<number>();
    @Input() addMediaToPlaylist: boolean;
    @Input() selectedPlaylistId: number;
    @Input() groupId: number;
    @Output() closeDrawer: EventEmitter<{ selectedMedia: Array<UserMediaAppSummaryModel>, updateValue: boolean }> = new EventEmitter<{ selectedMedia: Array<UserMediaAppSummaryModel>, updateValue: boolean }>();

    isLoading = false;
    isModelLoaded = false;
    model: Array<UserMediaAppSummaryModel>;
    filterKey: string;
    sortOrder: string;
    allMediaSelected = false;
    isMediaSelected: boolean;
    hasMediaList: boolean = true;
    direction: string;
    isDrawerVisible = false;
    showMediaDrawer = false;
    newMediaId = 0;
    hasMore = true;
    loadingMore: boolean;
    totalRecords: number;
    recordsToSkip: number;
    pageSize: number;
    filterModel = new FilterModel();

    constructor(
        private service: UserMediaAppService,
        private baseService: BaseService,
        private displayListService: DisplayListService,
        public appUtils: AppUtils
    ) {
        this.model = new Array<UserMediaAppSummaryModel>();
        this.filterModel.sortBy = 'Title';
        this.filterModel.sortDir = 'asc';
    }

    ngOnChanges(changes: SimpleChanges): void { }

    ngOnInit() {
        this.getMedia(true);
    }

    ngAfterViewInit() {
        this.scrollContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }

    onScroll(event: any) {
        const element = this.scrollContainer.nativeElement;
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight * 0.8 && this.hasMore && !this.loadingMore) {
            this.getMoreRecords();
        }
    }

    getMedia(initialLoading = false, limit = 15) {
        this.isLoading = true;
        this.isModelLoaded = false;
        this.filterModel.offset = 0;
        this.filterModel.limit = limit;
        this.totalRecords = 0;
        this.service.getList(this.groupId, this.filterModel).subscribe({
            next: (response: any) => {
                this.model = response;
                this.totalRecords = response.length;
                if (this.model.length > 0) {
                    this.model.forEach((x) => {
                        x.iconUrl = this.generateMediaIconUrl(x.mediaAppType);
                        x.showDefaultIcon = true;
                        x.lengthInSeconds = x.lengthInSeconds == 0 ? 10 : x.lengthInSeconds;
                    });
                }

                if (initialLoading) {
                    this.hasMediaList = this.model.length > 0;
                }

                this.selectMedia();
                this.isModelLoaded = true;
                this.isLoading = false;
            },
            error: (error: any) => {
                this.isLoading = false;
                this.isModelLoaded = true;
                this.baseService.processErrorResponse(error);
            },
        });
    }

    getMoreRecords() {
        if (this.isLoading) { return; }
        this.filterModel.limit = 5;
        this.filterModel.offset = this.totalRecords;
        this.loadingMore = true;
        this.isLoading = true;
        this.service.getList(this.groupId, this.filterModel).subscribe({
            next: (response: any) => {
                if (response && response.length > 0) {
                    response.forEach((x) => {
                        x.iconUrl = this.generateMediaIconUrl(x.mediaAppType);
                        x.showDefaultIcon = true;
                        if (this.allMediaSelected) {
                            x.isSelected = true;
                        }
                        this.model.push(x);
                    });
                    this.totalRecords += response.length;
                } else {
                    this.hasMore = false;
                }
                this.selectMedia();
                this.isLoading = false;
                this.loadingMore = false;
                this.isModelLoaded = true;
            },
            error: (error: any) => {
                this.isLoading = false;
                this.isModelLoaded = true;
                this.loadingMore = false;
                this.baseService.processErrorResponse(error);
            },
        });
    }

    generateMediaIconUrl(mediaAppType: string): string {
        return `./assets/images/media-types/icons/v2/${mediaAppType}.svg`;
    }

    selectAllMedia(event: any) {
        if (event) {
            this.model.forEach(x => {
                x.isSelected = true;
                this.isMediaSelected = true;
            });
        }
        else {
            this.model.forEach(x => {
                x.isSelected = false;
                this.isMediaSelected = false;
            });
        }
    }

    toggleMedia() {
        if (this.model.every(x => x.isSelected)) {
            this.allMediaSelected = true;
        }
        if (!this.model.every(x => x.isSelected)) {
            this.allMediaSelected = false;
        }

        if (this.model.some(x => x.isSelected)) {
            this.isMediaSelected = true;
        }
        else {
            this.isMediaSelected = false;
        }
    }

    submit() { }

    cancel(): void {
        this.closeDrawer.emit({ selectedMedia: new Array<UserMediaAppSummaryModel>(), updateValue: false });
    }

    onSearch() {
        if (this.filterModel.filterKey) {
            this.getMedia();
        }
    }

    clearSearch() {
        this.filterModel.filterKey = '';
        this.getMedia();
    }

    filterKeyChanged() {
        if (this.appUtils.isNullOrEmpty(this.filterModel.filterKey)) {
            this.getMedia();
        }
    }

    sortMedia() {
        if (this.sortOrder === 'ascending') {
            this.filterModel.sortBy = 'Title';
            this.filterModel.sortDir = 'asc';
        } else if (this.sortOrder === 'descending') {
            this.filterModel.sortBy = 'Title';
            this.filterModel.sortDir = 'desc';
        }

        this.getMedia(false, this.totalRecords);
    }

    sendMedia(): void {
        let mediaIdsToRemove = new Array<number>();
        this.selectedMediaList.forEach((x => {
            let media = this.model.filter(y => y.id == x.id);
            if (media && media.length > 0) {
                if (!media[0].isSelected) {
                    mediaIdsToRemove.push(media[0].id);
                }
            }
        }));

        this.selectedMediaList = this.selectedMediaList.filter(x => !mediaIdsToRemove.includes(x.id));

        var selectedMedia = this.model.filter(x => x.isSelected);
        selectedMedia.forEach((x => {
            let exists = this.selectedMediaList.some(y => y.id == x.id);
            if (!exists) {
                this.selectedMediaList.push(x);
            }
        }));

        this.closeDrawer.emit({ selectedMedia: this.selectedMediaList, updateValue: true });

    }

    selectMedia() {
        if (this.selectedMediaList != null && this.selectedMediaList.length > 0) {
            this.model.forEach(x => {
                const media = this.selectedMediaList.find(y => y.id == x.id);
                if (media) {
                    x.isSelected = true;
                    x.lengthInSeconds = media.lengthInSeconds == 0 ? 10 : toNumber(media.lengthInSeconds);
                    x.status = media.status;
                    x.isEnabled = media.isEnabled;
                    x.isActive = media.isActive;
                    x.isScheduled = media.isScheduled;
                    this.isMediaSelected = true;
                }
            });
          
        }

        if (this.model.length > 0 && this.model.every(x => x.isSelected)) {
            this.allMediaSelected = true;
        }else {
            this.allMediaSelected = false;
        }
    }

    addMediasToPlaylist() {
        this.isLoading = true;
        let selectedMedia = this.model.filter(x => x.isSelected);
        let playlist = new UserMediaAppEditModel();
        playlist.id = this.selectedPlaylistId;
        playlist.userMediaApps = new Array<DisplayListUserMediaAppModel>();

        selectedMedia.forEach((x, index) => {
            let userMediaApp = new DisplayListUserMediaAppModel();
            userMediaApp.userMediaAppId = x.id;
            userMediaApp.lengthInSeconds = 10;
            userMediaApp.displayOrder = index + 1;

            playlist.userMediaApps.push(userMediaApp);
        });
        this.displayListService.editUserMedia(playlist)
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.closeDrawer.emit({ selectedMedia: selectedMedia, updateValue: true });
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    openMediaStoreDrawer() {
        this.isDrawerVisible = true;
        this.showMediaDrawer = true;
        this.drawer.open();
    }

    closeMediaStoreDrawer(event: any) {
        if (event.mediaAdded) {
            this.newMediaId = event.mediaId;
        }
        this.drawer.close();
        this.isDrawerVisible = false;
        this.showMediaDrawer = false;
        this.getMedia(true);
    }

    close() {
        this.isDrawerVisible = false;
    }
}
