export class MediaAppListItemModel {
    id: string;
    name: string;
    iconUrl: string;
    previewUrl: string;
    status: string;
    type: number;
    isSelected: boolean;

    constructor() { 
        this.isSelected = false;
    }
}