export class MediaAppDetailModel {
    id: number;
    title: string;
    description: string;
    type: number;
    amount: number;
    iconUrl: string;
    typeString: string;
    previewUrl: string;
    showInManager: boolean;
    urlError:any;
}