import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class FolderService {
    private apiBaseUrl = environment.apiBaseUrl + 'api/folder';

    constructor(private http: HttpClient) {}

    getList(groupId?: any, id?: number): Observable<any> {
        if (groupId == undefined || groupId == '' || groupId == -1) {
            groupId = null;
        }
        if (id == undefined) {
            id = null;
        }
        
        return this.http.get(`${this.apiBaseUrl}/get-select-list-items/${groupId}/${id}`);
    }

    
}