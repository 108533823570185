import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { DisplayListService } from "../../services/display-list.service";
import { DisplayListListItemModel, FilterModel, ListItemsFilterModel, MediaAppListItemModel } from "../../models";
import { BaseService } from "../../services";
import { AppUtils } from "src/helpers";
import { NzDrawerComponent } from "ng-zorro-antd/drawer";

@Component({
    selector: 'app-display-list-list',
    templateUrl: './display-list-list.component.html',
    styleUrls: ['./display-list-list.component.scss']
})

export class PlaylistComponent implements OnInit, AfterViewInit {
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
    @Input() groupId: number;
    @Input() addFromDisplay: boolean;
    @Input() medias: Array<MediaAppListItemModel> = new Array<MediaAppListItemModel>();
    @Output() closeDrawer: EventEmitter<{ selectedDisplayList: DisplayListListItemModel, updateValue: boolean }> = new EventEmitter<{ selectedDisplayList: DisplayListListItemModel, updateValue: boolean }>();
    isModelLoaded: boolean;
    filterKey: string;
    model: Array<DisplayListListItemModel>;
    isLoading = true;
    sortOrder: string;
    hasDisplayList: boolean = true;
    addDisplayList: boolean;
    displayListId: number;
    totalRecords: number = 0;
    recordsToSkip: number;
    pageSize: number;
    loadingMore = false;
    hasMore = true;
    page = 1;
    filterModel = new FilterModel();

    constructor(private service: DisplayListService,
        private baseService: BaseService,
        private appUtils: AppUtils
    ) {
        this.filterModel.sortBy = 'name';
        this.filterModel.sortDir = 'asc';
    }

    ngOnInit(): void {
        this.getList(true);

    }

    ngAfterViewInit() {
        this.scrollContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }

    trackById(index: number, item: any): number {
        return item.id;
    }

    onScroll(event: any) {
        const element = this.scrollContainer.nativeElement;
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight * 0.8 && this.hasMore && !this.loadingMore) {
            this.getMoreRecords();
        }
    }

    getList(initialLoading = false, limit = 15) {
        this.isModelLoaded = false;
        this.isLoading = true;
        this.filterModel.limit = limit;
        this.filterModel.offset = 0;
        this.totalRecords = 0;
        this.service.getList(this.groupId, this.filterModel)
            .subscribe({
                next: (response: Array<DisplayListListItemModel>) => {
                    this.model = response;
                    this.totalRecords = response.length;
                    if (this.model.length > 0) {
                        this.model.forEach((x) => {
                            x.defaultUserMediaAppUrl = this.getMediaIconUrl(x.defaultUserMediaAppType);
                            x.showDefaultIcon = true;
                        });
                    }

                    if (initialLoading) {
                        this.hasDisplayList = this.model.length > 0;
                    }
                    this.isModelLoaded = true;
                    this.isLoading = false;
                },
                error: (error) => {
                    this.baseService.processErrorResponse(error);
                    this.isLoading = false;
                }
            });
    }

    getMediaIconUrl(mediaType: string): string {
        const media = this.medias.find(x => x.id == mediaType);
        if (media != undefined) {
            return media.iconUrl;

        }
        return '';
    }

    openDisplaylistUpsertDrawer() {
        this.addDisplayList = true;
        this.drawer.open();
    }

    close() {

    }

    closeDisplayUpsertDrawer(event: any): void {
        if (event.displayListAdded) {
            this.displayListId = event.displayListId;
            this.getList(true);
        }
        this.drawer.close();
        this.addDisplayList = false;
    }

    onSearch() {
        if (this.filterModel.filterKey) {
            this.getList();
        }
    }

    clearSearch() {
        this.filterModel.filterKey = '';
        this.getList();
    }

    selectPlaylist(item: DisplayListListItemModel): void {

        this.closeDrawer.emit({ selectedDisplayList: item, updateValue: true });
    }

    cancel(): void {
        this.closeDrawer.emit({ selectedDisplayList: new DisplayListListItemModel(), updateValue: false });
    }

    sortPlaylist() {
        if (this.sortOrder === 'ascending') {
            this.filterModel.sortBy = 'name';
            this.filterModel.sortDir = 'asc';
        } else if (this.sortOrder === 'descending') {
            this.filterModel.sortBy = 'name';
            this.filterModel.sortDir = 'desc';
        }
        this.getList(false, this.totalRecords);
    }

    filterKeyChanged() {
        if (this.appUtils.isNullOrEmpty(this.filterModel.filterKey)) {
            this.getList();
        }
    }

    onError(item: DisplayListListItemModel): void {
        item.urlError = true;
    }

    closeMediaStoreDrawer(event: any) {

    }

    getMoreRecords() {
        if (this.loadingMore) { return; }
        this.filterModel.limit = 5;
        this.filterModel.offset = this.totalRecords;
        this.loadingMore = true;
        this.isLoading = true;
        this.service.getList(this.groupId, this.filterModel)
            .subscribe({
                next: (response: Array<DisplayListListItemModel>) => {
                    if (response && response.length > 0) {
                        response.forEach((x) => {
                            x.defaultUserMediaAppUrl = this.getMediaIconUrl(x.defaultUserMediaAppType);
                            x.showDefaultIcon = true;
                            this.model.push(x);
                        });
                        this.totalRecords += response.length;
                    }
                    else {
                        this.hasMore = false;
                    }
                    this.loadingMore = false;
                    this.isLoading = false;
                },
                error: (error) => {
                    this.loadingMore = false;
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }


}