import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
    selector: '[appNumbersOnly]'
})

export class NumbersOnlyDirective {
    regexStr = '^[0-9]*$';

    constructor(private el: ElementRef) { }

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        return new RegExp(this.regexStr).test(event.key);
    }

    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('drop', ['$event']) blockDrop(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('blur', ['$event']) blockBlur(event: KeyboardEvent) {
        this.validateFields(event);
    }

    validateFields(event: any) {
        setTimeout(() => {
            event.preventDefault();
            const validtedValue = this.el.nativeElement.value.replace(/[^0-9]/g, '');
            this.el.nativeElement.value = validtedValue.toString().trim();
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }, 100);
    }
}

// START AnythingButWhiteSpaceDirective

@Directive({
    selector: '[appAnythingButWhiteSpace]'
})

export class AnythingButWhiteSpaceDirective {
    regexStr = '[^\ ]';

    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        return new RegExp(this.regexStr).test(event.key);
    }

    @HostListener('copy', ['$event']) blockCopy(event: KeyboardEvent) {
        event.preventDefault();
    }

    @HostListener('drop', ['$event']) blockDrop(event: KeyboardEvent) {
        event.preventDefault();
    }

    @HostListener('paste', ['$event']) trimOnPaste() {
        setTimeout(() => {
            this.el.nativeElement.value = this.el.nativeElement.value.toString().trim();
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }, 100);
    }
}

// END AnythingButWhiteSpaceDirective

// START: AlphaNumericsLevelOneDirective

@Directive({
    selector: '[appAlphaNumericsLevelOne]'
})

export class AlphaNumericsLevelOneDirective {
    regexStr = '^[a-zA-Z0-9-_.& ]*$';

    constructor(private el: ElementRef) { }

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        return new RegExp(this.regexStr).test(event.key);
    }

    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('drop', ['$event']) blockDrop(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('blur', ['$event']) blockBlur(event: KeyboardEvent) {
        this.validateFields(event);
    }

    validateFields(event: any) {
        setTimeout(() => {
            event.preventDefault();
            const validtedValue = this.el.nativeElement.value.replace(/[^a-zA-Z0-9-_.& ]/g, '');
            this.el.nativeElement.value = validtedValue.toString().trim();
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }, 100);
    }
}

// START: AlphaNumericsLevelTwoDirective

@Directive({
    selector: '[appAlphaNumericsLevelTwo]'
})

export class AlphaNumericsLevelTwoDirective {
    regexStr = '^[a-zA-Z0-9-_./ ]*$';

    constructor(private el: ElementRef) { }

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        return new RegExp(this.regexStr).test(event.key);
    }

    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('drop', ['$event']) blockDrop(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('blur', ['$event']) blockBlur(event: KeyboardEvent) {
        this.validateFields(event);
    }

    validateFields(event: any) {
        setTimeout(() => {
            event.preventDefault();
            const validtedValue = this.el.nativeElement.value.replace(/[^a-zA-Z0-9-_./ ]/g, '');
            this.el.nativeElement.value = validtedValue.toString().trim();
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }, 100);
    }
}

// END: AlphaNumericsLevelTwoDirective

// START: AlphaNumericsLevelThreeDirective

@Directive({
    selector: '[appAlphaNumericsLevelThree]'
})

export class AlphaNumericsLevelThreeDirective {
    regexStr = '^[a-zA-Z0-9-_. ]*$';

    constructor(private el: ElementRef) { }

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        return new RegExp(this.regexStr).test(event.key);
    }

    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('drop', ['$event']) blockDrop(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('blur', ['$event']) blockBlur(event: KeyboardEvent) {
        this.validateFields(event);
    }

    validateFields(event: any) {
        setTimeout(() => {
            event.preventDefault();
            const validtedValue = this.el.nativeElement.value.replace(/[^a-zA-Z0-9-_. ]/g, '');
            this.el.nativeElement.value = validtedValue.toString().trim();
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }, 100);
    }
}

// END: AlphaNumericsLevelThreeDirective

// START: AlphaNumericsLevelFourDirective

@Directive({
    selector: '[appAlphaNumericsLevelFour]'
})

export class AlphaNumericsLevelFourDirective {
    regexStr = '^[a-zA-Z0-9_ ]*$';

    constructor(private el: ElementRef) { }

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        return new RegExp(this.regexStr).test(event.key);
    }

    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('drop', ['$event']) blockDrop(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('blur', ['$event']) blockBlur(event: KeyboardEvent) {
        this.validateFields(event);
    }

    validateFields(event: any) {
        setTimeout(() => {
            event.preventDefault();
            const validtedValue = this.el.nativeElement.value.replace(/[^a-zA-Z0-9_ ]/g, '');
            this.el.nativeElement.value = validtedValue.toString().trim();
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }, 100);
    }
}

// END: AlphaNumericsLevelFourDirective

@Directive({
    selector: '[appAlphabatesOnly]'
})

export class AlphabatesOnlyDirective {
    regexStr = '^[a-zA-Z]*$';

    constructor(private el: ElementRef) { }

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        return new RegExp(this.regexStr).test(event.key);
    }

    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('drop', ['$event']) blockDrop(event: KeyboardEvent) {
        this.validateFields(event);
    }

    @HostListener('blur', ['$event']) blockBlur(event: KeyboardEvent) {
        this.validateFields(event);
    }

    validateFields(event: any) {
        setTimeout(() => {
            event.preventDefault();
            const validtedValue = this.el.nativeElement.value.replace(/[^a-zA-Z]/g, '');
            this.el.nativeElement.value = validtedValue.toString().trim();
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }, 100);
    }
}