export class UserMediaAppYouTubeUpsertModel {
    public id: string;
    public userMediaAppId: number;
    public type: string;
    public videoId: string;
    public videoTitle: string;
    public isEnabled: boolean;
    public displayOrder: number;

    public searchText: string;
    public url: string;

    constructor() {
        this.isEnabled = true;
        this.type = 'video';
        this.displayOrder = 0;
    }
}
