import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml, SafeResourceUrl } from "@angular/platform-browser";
import { PageContentDetailModel } from "src/models";

@Component({
    selector: 'app-help-video-view',
    templateUrl: './help-video-view.component.html'
})

export class HelpVideoViewComponent implements OnInit, OnChanges{
    @Input() model;
    helpVideo: SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
    ) { }

    ngOnChanges() {
        this.helpVideo = this.sanitizeHtml(this.model.content);
    }

    ngOnInit(): void {
        this.helpVideo = this.sanitizeHtml(this.model.content);
    }

    sanitizeHtml(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustHtml(url);
    }

}