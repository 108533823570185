<section>
  <nz-spin [nzSpinning]="isLoading">
    <form #form="ngForm" nz-form (ngSubmit)="submit()">
      <div class="drawer-form-header-empty"></div>
      <div nz-row class="drawer-form-header">
        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
          <div class="heading-fs-18 mb-0">
            <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
              (click)="cancel()"></span>
            New Broadcast
          </div>
        </div>
        <div nz-col nzSpan="12" class="text-right disp-web">
          <button nz-button class="mr-3" (click)="cancel()">Cancel</button>
          <button nz-button nzType="primary" [disabled]="form.invalid">Save</button>
        </div>
        <div nz-col nzSpan="24">
          <nz-divider></nz-divider>
        </div>
      </div>
      <div class="mobOne-action-m-b">
        <div nz-row class="mb-2">
          <div nz-col nzSpan="24">
            <div class="input-container">
              <nz-form-item>
                <nz-form-control>
                  <nz-form-label [nzRequired]="true">Broadcast name</nz-form-label>
                  <nz-input-group>
                    <input nz-input placeholder="Give your broadcast a name" [(ngModel)]="model.name"
                      #broadcastName="ngModel" maxlength="100" name="broadcastName" autocomplete="off"
                      appAlphaNumericsLevelThree required />
                  </nz-input-group>
                  <div *ngIf="form.submitted || broadcastName?.touched || broadcastName?.dirty"
                    class="validation-error-message">
                    <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                      *ngIf="broadcastName?.errors"></span>
                    <span *ngIf="broadcastName?.errors?.['required']" class="ml-1">
                      Broadcast
                      name is required. </span>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-col nzSpan="24">
            <div class="input-container">
              <nz-form-item>
                <nz-form-control class="cursor-pointer mb-0">
                  <nz-form-label [nzRequired]="true">Screen(s)
                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon-v1"
                    nz-tooltip nzTooltipTitle="Select one or more screens where you want this broadcast to be displayed."></span>
                  </nz-form-label>
                  <nz-select nzPlaceHolder="Select Screen" nzMode="multiple" [nzMaxTagCount]="0"
                    [nzMaxTagPlaceholder]="tagPlaceHolder" [(ngModel)]="model.displayIds" name="screens"
                    [nzShowArrow]="true" #screens="ngModel"
                    (ngModelChange)="onDisplaySelected(); isDisplaySelected = true;">
                    <nz-option [nzValue]="item.keyInt" nzLabel="{{item.value}}"
                      *ngFor="let item of displays"></nz-option>
                    <nz-option *ngIf="displays.length == 0" nzLabel="No Screen added" nzValue="disabled"
                      nzDisabled></nz-option>
                  </nz-select>
                  <ng-template #tagPlaceHolder let-selectedList>
                    <span *ngIf="selectedDisplays.length > 0">{{ selectedDisplays.length }} <span
                        *ngIf="selectedDisplays.length == 1"> Item
                      </span> <span *ngIf="selectedDisplays.length > 1"> Items
                      </span> selected
                    </span>
                  </ng-template>
                  <div *ngIf="form.submitted && !isDisplaySelected"
                    class="validation-error-message d-flex align-items-start">
                    <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"></span>
                    <span class="ml-1">
                      Please select at least one screen to broadcast media.
                    </span>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-col nzSpan="24" *ngIf="selectedDisplays.length > 0">
            <div class="selected-chip-container">
              <nz-tag *ngFor="let display of selectedDisplays" (nzOnClose)="removeDisplay(display)" nzMode="closeable"
                class="selected-tag "> <span class="selected-tag-content">{{display.value}}</span></nz-tag>
            </div>
          </div>
          <div nz-col nzSpan="24" class="mt-2">
            <nz-divider></nz-divider>
          </div>
        </div>

        <div nz-col nzSpan="24" class="mb-1">
          <label for="" [class.required]="true"><span class="fw-bold">Start date</span>
            <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip nzTooltipTitle="Set the start date and time for when this broadcast will go live."
              class="label-icon-v2"></span>
          </label>
        </div>
        <div nz-row [nzGutter]="[16]">
          <div nz-col nzSpan="8">
            <div class="input-container">
              <nz-form-item>
                <nz-form-control>
                  <nz-form-label>Date type</nz-form-label>
                  <nz-select nzPlaceHolder="Date Type" [(ngModel)]="model.startDateType" #startDateType="ngModel"
                    (ngModelChange)="onStartDateTypeChanged()" name="startDateType">
                    <nz-option [nzValue]="startDateType.keyInt" nzLabel="{{startDateType.value}}"
                      *ngFor="let startDateType of startDateTypes"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-col nzSpan="8" *ngIf="model.startDateType != 2">
            <div class="input-container">
              <nz-form-item>
                <nz-form-control>
                  <nz-form-label>Select date</nz-form-label>
                  <nz-date-picker nzFormat="MM/dd/YYYY" name="startDate" [nzAllowClear]="false"
                    [(ngModel)]="model.startDateTime" #startDate="ngModel" [nzDisabledDate]="disableStartDate"
                    nzPlaceHolder="Select" (ngModelChange)="isEndDateValid()" class="cursor-pointer"></nz-date-picker>
                  <div *ngIf="form.submitted || startDate?.touched || startDate?.dirty"
                    class="validation-error-message">
                    <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                      *ngIf="startDate?.errors"></span>
                    <span *ngIf="startDate?.errors?.['required']" class="ml-1">Start date is required</span>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-col nzSpan="8" *ngIf="model.startDateType != 2">
            <div class="input-container">
              <nz-form-item>
                <nz-form-control>
                  <nz-form-label>Start time</nz-form-label>
                  <nz-time-picker [nzUse12Hours]="true" [nzAllowEmpty]="false" class="cursor-pointer"
                    [(ngModel)]="model.startDateTime" name="startTime" #startTime="ngModel" nzFormat="hh:mm a"
                    nzPlaceHolder="Select" required (ngModelChange)="isEndDateValid()">
                  </nz-time-picker>
                  <div *ngIf="form.submitted || startTime?.touched || startTime?.dirty"
                    class="validation-error-message">
                    <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                      *ngIf="startTime?.errors"></span>
                    <span *ngIf="startTime?.errors?.['required']" class="ml-1">Start time is required</span>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="24" class="mb-1">
          <label for="" [class.required]="true"><span class="fw-bold">End date</span>
            <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip nzTooltipTitle="Specify the end date and time for the broadcast."
              class="label-icon"></span>
          </label>
        </div>
        <div nz-row [nzGutter]="[16]" class="mb-2">
          <div nz-col nzSpan="8">
            <div class="input-container">
              <nz-form-item>
                <nz-form-control>
                  <nz-form-label>Date type</nz-form-label>
                  <nz-select nzPlaceHolder="Date Type" [(ngModel)]="model.endDateType" #endDateType="ngModel"
                    (ngModelChange)="onEndDateTypeChanged()" name="endDateType">
                    <nz-option [nzValue]="endDateType.keyInt" nzLabel="{{endDateType.value}}"
                      *ngFor="let endDateType of endDateTypes"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-col nzSpan="8" *ngIf="model.endDateType != 2">
            <div class="input-container">
              <nz-form-item>
                <nz-form-control>
                  <nz-form-label>Select date</nz-form-label>
                  <nz-date-picker nzFormat="MM/dd/YYYY" name="endDate" [nzAllowClear]="false"
                    [(ngModel)]="model.endDateTime" #endDate="ngModel" [nzDisabledDate]="disableEndDate"
                    nzPlaceHolder="Select" (ngModelChange)=" isEndDateValid()" class="cursor-pointer"></nz-date-picker>
                  <div *ngIf="form.submitted || endDate?.touched || endDate?.dirty" class="validation-error-message">
                    <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                      *ngIf="endDate?.errors"></span>
                    <span *ngIf="endDate?.errors?.['required']" class="ml-1">End
                      date is required</span>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-col nzSpan="8" *ngIf="model.endDateType != 2">
            <div class="input-container">
              <nz-form-item>
                <nz-form-control>
                  <nz-form-label>End time</nz-form-label>
                  <nz-time-picker [nzUse12Hours]="true" class="cursor-pointer" [nzAllowEmpty]="false"
                    [(ngModel)]="model.endDateTime" name="endTime" #endTime="ngModel" nzFormat="hh:mm a"
                    nzPlaceHolder="Select" required (ngModelChange)="isEndDateValid()">
                  </nz-time-picker>
                  <div *ngIf="form.submitted || endTime?.touched || endTime?.dirty"
                    class="validation-error-message d-flex align-items-start">
                    <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                      *ngIf="endTime?.errors || !isEndDateTimeValid"></span>
                    <span *ngIf="endTime?.errors?.['required']" class="ml-1">End
                      time is required</span>
                    <span *ngIf="endTime.valid && !isEndDateTimeValid" class="ml-1 m-t-1">Please select
                      any future date time.</span>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-col nzSpan="24">
            <nz-divider class="mt-0"></nz-divider>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <div class="d-flex">
              <div class="w-100 mr-3">
                <div class="input-container">
                  <nz-form-item>
                    <nz-form-control>
                      <nz-form-label>Media
                        <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                        nz-tooltip nzTooltipTitle="Select the media you want to include in this broadcast."></span>
                      </nz-form-label>
                      <nz-select [(ngModel)]="model.userMediaAppId" name="userMediaAppId" #userMediaAppId="ngModel"
                        nzPlaceHolder="Select media" required>
                        <nz-option [nzValue]="item.keyInt" nzLabel="{{ item.value }}"
                          *ngFor="let item of userMediaApps"></nz-option>
                        <nz-option *ngIf="userMediaApps.length == 0" nzLabel="No Media added" nzValue="disabled"
                          nzDisabled></nz-option>
                      </nz-select>
                      <div *ngIf="form.submitted || userMediaAppId?.touched || userMediaAppId?.dirty"
                        class="validation-error-message">
                        <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                          *ngIf="userMediaAppId?.errors"></span>
                        <span *ngIf="userMediaAppId?.errors?.['required']" class="ml-1">Media is required</span>
                      </div>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div class="pt-3 justify-content-end">
                <button nz-button type="button" (click)="openMediaStoreDrawer()" class="mt-2 form-field-btn">
                  <span nz-icon nzType="plus" nzTheme="outline"></span>Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="24">
          <div class="input-container">
            <nz-form-item>
              <nz-form-control>
                <nz-form-label>Time zone</nz-form-label>
                <nz-select name="timeZone" [(ngModel)]="model.timeZone" #timeZone="ngModel"
                  nzPlaceHolder="Select timzone">
                  <nz-option [nzValue]="item.standardName" nzLabel="{{ item.displayName }}"
                    *ngFor="let item of timeZones"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSpan="24">
          <div class="input-container">
            <nz-form-item>
              <nz-form-control>
                <nz-form-label>Group</nz-form-label>
                <nz-select [(ngModel)]="selectedGroupId" nzPlaceHolder="Groups allow you to share things within manager with other people." name="group"
                  (ngModelChange)="onGroupChange($event)" [disabled]="disableGroupSelection">
                  <nz-option [nzValue]="item.keyInt" nzLabel="{{item.value}}"
                    *ngFor="let item of subscriptionPlans"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSpan="24">
          <div class="input-container">
            <nz-form-item>
              <nz-form-control>
                <nz-form-label>Folder
                  <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon" nz-tooltip
                    nzTooltipTitle="Organize items in Manager with folders."></span>
                </nz-form-label>
                <nz-select [(ngModel)]="model.folderId" nzPlaceHolder="Select a folder" name="folder">
                  <nz-option [nzValue]="item.keyInt" nzLabel="{{ item.value }}"
                    *ngFor="let item of folders"></nz-option>
                  <nz-option *ngIf="!folders || folders.length == 0" nzValue="disabled" nzLabel="No Folder added"
                    nzDisabled></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row class="mt-2 mb-4" *ngIf="model.startDateType == 1 && model.endDateType == 1">
          <div nz-col nzSpan="24">
            <label class="mr-3" for="recurrence" class="fs-16 fw-bold">Recurrence</label>
            <span class="ml-3"><nz-switch [(ngModel)]="model.isRecurring" name="showRecurrenceField"
                #showRecurrenceField="ngModel" (ngModelChange)="onRecurrenceClick($event)"></nz-switch></span>
          </div>
        </div>

        <div *ngIf="model.isRecurring">
          <div nz-row [nzGutter]="[16]">
            <div nz-col nzSpan="8">
              <div class="input-container">
                <nz-form-item>
                  <nz-form-control>
                    <nz-form-label>Repeat every:</nz-form-label>
                    <nz-input-group>
                      <input name="separationCount" [(ngModel)]="model.recurring.separationCount"
                        #separationCount="ngModel" nz-input placeHolder="Enter number"
                        [appCustomMin]="model.recurring.recurringType === 5 ? 5 : 1" appNumbersOnly required />
                    </nz-input-group>
                    <div *ngIf="form.submitted || separationCount?.touched || separationCount?.dirty"
                      class="validation-error-message d-flex align-items-start">
                      <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                        *ngIf="separationCount?.errors || !isSeparationCountValid"></span>
                      <span *ngIf="separationCount?.errors?.['required']" class="ml-1 m-t-1">
                        Repeat time is required.</span>
                      <span *ngIf="!separationCount?.errors?.['required'] && separationCount?.errors?.['appCustomMin']"
                        class="ml-1 m-t-1">
                        Repeat time can not be less than
                        <span *ngIf="model.recurring.recurringType === 5">
                          5 minutes</span>
                        <span *ngIf="model.recurring.recurringType !== 5">
                          1
                          <span *ngIf="model.recurring.recurringType === 1">
                            day</span>
                          <span *ngIf="model.recurring.recurringType === 2">
                            week</span>
                          <span *ngIf="model.recurring.recurringType === 3">
                            month</span>
                          <span *ngIf="model.recurring.recurringType === 4">
                            year</span>
                          <span *ngIf="model.recurring.recurringType === 6">
                            hour</span>
                        </span></span>
                      <span class="ml-1 m-t-1" *ngIf="separationCount.valid && !isSeparationCountValid">
                        Can not be less than play time.
                      </span>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-col nzSpan="16">
              <div class="input-container">
                <nz-form-item>
                  <nz-form-control>
                    <nz-form-label>
                      <span class="text-ellipsis">How often do you want this
                        broadcast to
                        repeat?</span>
                      <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon" nz-tooltip
                      nzTooltipTitle="Select the interval at which you want the broadcast to repeat. You can choose from options like minutes, hours, or days."></span>
                    </nz-form-label>
                    <nz-select name="recurringType" nzPlaceHolder="Select" [(ngModel)]="model.recurring.recurringType"
                      #recurringType="ngModel" (ngModelChange)="onRepeatTypeChange()" required>
                      <nz-option [nzValue]="recurrenceType.keyInt" nzLabel="{{recurrenceType.value}}"
                        *ngFor="let recurrenceType of recurrenceTypes"></nz-option>
                    </nz-select>
                    <div *ngIf="form.submitted || recurringType?.touched || recurringType?.dirty"
                      class="validation-error-message d-flex align-items-start">
                      <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                        *ngIf="recurringType?.errors"></span>
                      <span class="validation-error-message m-t-2" *ngIf="recurringType.errors?.['required']">
                        Repeat type is required.
                      </span>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div nz-row *ngIf="model.recurring.recurringType === 5">
            <div nz-col nzSpan="24" class="preview-content note-content recurrence-message">
              <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="mr-2"></span>
              <app-content-text-view [contentType]="constants.pageContentType.broadcastMinutelyRepeatInfoMessage">
              </app-content-text-view>
            </div>
          </div>

          <div nz-row [nzGutter]="[16]" *ngIf="model.recurring.recurringType == constants.recurrenceType.monthly">
            <div nz-col nzXs="12" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
              <div class="input-container">
                <nz-form-item>
                  <nz-form-control>
                    <nz-form-label>Monthly on day</nz-form-label>
                    <nz-select name="recurringMonthDay" [(ngModel)]="model.recurring.days[0]"
                      #recurringMonthDay="ngModel" required nzPlaceHolder="Select day">
                      <nz-option [nzValue]="day.keyInt" nzLabel="{{day.value}}"
                        *ngFor="let day of monthDays"></nz-option>
                    </nz-select>
                    <div *ngIf="form.submitted || recurringMonthDay?.touched || recurringMonthDay?.dirty"
                      class="validation-error-message d-flex align-items-start">
                      <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                        *ngIf="recurringMonthDay?.errors"></span>
                      <span class="validation-error-message m-t-2" *ngIf="recurringMonthDay.errors?.['required']">
                        Day is required.
                      </span>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div nz-row [nzGutter]="[16]" class="mb-3"
            *ngIf="model.recurring.recurringType == constants.recurrenceType.weekly">
            <div nz-col nzSpan="24" class="mb-1">Week</div>
            <div nz-col nzSpan="24">
              <button type="button" *ngFor="let day of weekDays" [ngClass]="day.isSelected ? 'week-btn-selected' : ''"
                (click)="day.isSelected = !day.isSelected;" nz-button nzType="default"
                class="week-btn">{{day.value}}</button>
            </div>
          </div>

          <div class="fw-bold mt-3 mb-3">End Date</div>

          <div nz-col nzSpan="24" class="mb-3">
            <nz-radio-group name="isAfterOccurrence" [(ngModel)]="model.recurring.isAfterOccurrence"
              #isAfterOccurrence="ngModel">
              <label nz-radio [nzValue]="false">On</label>
              <label nz-radio [nzValue]="true">After</label>
            </nz-radio-group>
          </div>

          <div nz-row [nzGutter]="[16]">
            <div nz-col nzSpan="16" *ngIf="!model.recurring.isAfterOccurrence">
              <div class="input-container">
                <nz-form-item>
                  <nz-form-control>
                    <nz-form-label [nzRequired]="true">Select
                      Date</nz-form-label>
                    <nz-date-picker name="recurringEndDate" [(ngModel)]="model.recurring.endDate"
                      #recurringEndDate="ngModel" nzFormat="MM/dd/YYYY" [nzAllowClear]="false"
                      [nzDisabledDate]="disableRecurringEndDate" nzPlaceHolder="Select" required></nz-date-picker>
                    <div *ngIf="form.submitted || recurringEndDate?.touched || recurringEndDate?.dirty"
                      class="validation-error-message">
                      <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                        *ngIf="recurringEndDate?.errors"></span>
                      <span *ngIf="recurringEndDate?.errors?.['required']" class="ml-1">
                        End date is required.</span>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-col nz-col nzXs="12" nzSm="8" nzMd="8" nzLg="8" nzXl="8" *ngIf="model.recurring.isAfterOccurrence">
              <div class="input-container">
                <nz-form-item>
                  <nz-form-control>
                    <nz-form-label [nzRequired]="true">Occurrences</nz-form-label>
                    <nz-input-group>
                      <input nz-input [(ngModel)]="model.recurring.maxNumOfOccurrence" #maxNumOfOccurrence="ngModel"
                        name="maxNumOfOccurrence" appNumbersOnly required />
                    </nz-input-group>
                    <div *ngIf="form.submitted || maxNumOfOccurrence?.touched || maxNumOfOccurrence?.dirty"
                      class="validation-error-message">
                      <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                        *ngIf="maxNumOfOccurrence?.errors"></span>
                      <span *ngIf="maxNumOfOccurrence?.errors?.['required']" class="ml-1">
                        Occurrence is required.</span>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
        <div nz-row class="mt-2 mb-4">
          <div nz-col nzSpan="24">
            <label class="mr-3" for="" class="fs-16 fw-bold">Show this broadcast</label>
            <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
              nzTooltipTitle="Toggle this option to make the broadcast active or inactive." class="label-icon"></span>
            <span class="ml-3"><nz-switch name="isEnabled" [(ngModel)]="model.isEnabled"
                #isEnabled="ngModel"></nz-switch></span>
          </div>

          <div nz-col nzSpan="24" class="mt-4">
            <label nz-checkbox [(ngModel)]="model.isAutoDelete" name="isAutoDelete" #isAutoDelete="ngModel">Auto delete
              this broadcast after it ends</label>
          </div>
        </div>
      </div>
      <div nz-row class="disp-mob mobOne-action-bar">
        <div nz-col nzSpan="24" class="text-right">
          <nz-card>
            <div nz-row [nzGutter]="[16]">
              <div nz-col nzSpan="12">
                <button nz-button class="btn-full" type="button" (click)="cancel()">Cancel</button>
              </div>
              <div nz-col nzSpan="12">
                <button nz-button class="btn-full" nzType="primary" [disabled]="form.invalid">Save</button>
              </div>
            </div>
          </nz-card>
        </div>
      </div>
    </form>
    <nz-drawer #drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" nzPlacement="right" (nzOnClose)="close()"
      [nzMask]="false" class="custom-drawer">
      <ng-container *nzDrawerContent>
        <app-media-store (closeDrawer)="closeMediaStoreDrawer()" [selectedGroupId]="selectedGroupId"></app-media-store>
      </ng-container>
    </nz-drawer>
  </nz-spin>
</section>