import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SelectListItemModel } from "src/models";
import { DisplayChangeStatusModel } from "../models";

@Injectable()
export class DisplayService {
    private apiEndPoint = environment.apiBaseUrl + 'api/display/';

    constructor(private http: HttpClient) { }

    getSelectListItems(groupId?: any): Observable<Array<SelectListItemModel>> {
        if (!groupId || groupId === -1) {
            groupId = null;
        }
        return this.http.get<Array<SelectListItemModel>>(`${this.apiEndPoint}get-select-list-items/${groupId}`);
    }

    getDisplayCount(includeGroupDisplays: boolean) {
        return this.http.get<number>(this.apiEndPoint + 'count/' + includeGroupDisplays);
    }

    getDisplayCountByUserOwnership(userId: string) {
        return this.http.get<number>(this.apiEndPoint + 'count-by-user-ownership/' + userId);
    }

    exists(groupId?: number): Observable<any> {
        if (!groupId || groupId === -1) {
            groupId = null;
        }
        return this.http.get(`${this.apiEndPoint}exists/${groupId}`);
    }

    add(model: any): Observable<any> {
        return this.http.post(`${this.apiEndPoint}add`, model);
    }

    changeStatus(model: DisplayChangeStatusModel): Observable<any> {
        return this.http.post(this.apiEndPoint + 'change-status', model);
    }
}