import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AppUtils } from "src/helpers";
import { BroadcastListItemModel, FilterModel, ListItemsFilterModel, MediaAppListItemModel } from "src/app/shared/models";
import { BaseService, BroadcastService } from "src/app/shared/services";
import { NzDrawerComponent } from "ng-zorro-antd/drawer";
import { ListenerService } from "src/services";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-broadcast-multi-select',
    templateUrl: './broadcast-multi-select.component.html',
    styleUrls: ['./broadcast-multi-select.component.scss']
})

export class BroadcastMultiSelectComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
    @Input() medias: Array<MediaAppListItemModel> = new Array<MediaAppListItemModel>();
    @Input() selectedGroupId: number;
    @Input() selectedBroadcastIds = new Array<number>();
    @Input() selectedBroadcasts: Array<BroadcastListItemModel> = new Array<BroadcastListItemModel>();
    @Output() closeDrawer: EventEmitter<{ selectedBroadcasts: Array<BroadcastListItemModel>, updateValue: boolean }>
        = new EventEmitter<{ selectedBroadcasts: Array<BroadcastListItemModel>, updateValue: boolean }>();
    isModelLoaded: boolean;
    groupId: number;
    isLoading = true;
    filterKey: string;
    model: Array<BroadcastListItemModel>;
    hasBroadcastList: boolean = true;
    sortOrder: string;
    showBroadcastAddDrawer = false;
    isDrawerVisisble = false;
    allBroadcastSelected = false;
    isBroadcastSelected: boolean;
    subscriptions = new Array<Subscription>();
    totalRecords: number = 0;
    recordsToSkip: number;
    pageSize: number;
    loadingMore = false;
    hasMore = true;
    newBroadcastId = 0;
    filterModel = new FilterModel();

    constructor(private baseService: BaseService,
        private appUtils: AppUtils,
        private service: BroadcastService,
        private messageService: ListenerService
    ) {
        this.model = new Array<BroadcastListItemModel>();
        const reloadBroadcastDataSubscription = this.messageService
            .listenReloadBroadcastData.subscribe((broadcastIds: Array<number>) => {
                this.handleReloadBroadcast(broadcastIds);
            });

        this.subscriptions.push(reloadBroadcastDataSubscription);

        if (this.selectedGroupId == undefined) {
            this.groupId == null;
        }
        else {
            this.groupId = this.selectedGroupId;
        }
        this.filterModel.sortBy = 'name';
        this.filterModel.sortDir = 'asc';
    }

    ngOnInit(): void {
        this.getBroadcastList(true);
    }

    ngOnChanges() {
        if (this.selectedGroupId == undefined) {
            this.groupId == null;
        }
        else {
            this.groupId = this.selectedGroupId;
        }
    }


    selectBroadcasts() {
        if (this.selectedBroadcasts && this.selectedBroadcasts.length > 0) {
            this.model.forEach(x => {
                const broadcast = this.selectedBroadcasts.find(y => y.id == x.id);
                if (broadcast) {
                    x.isSelected = true;
                    this.isBroadcastSelected = true;
                }
            });

            if (this.model.length > 0 && this.model.every(x => x.isSelected)) {
                this.allBroadcastSelected = true;
            }
            else {
                this.allBroadcastSelected = false;
            }
        }
    }

    ngAfterViewInit() {
        this.scrollContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }

    getBroadcastList(initialLoading = false, limit = 15): void {
        this.isModelLoaded = false;
        this.isLoading = true;
        this.filterModel.limit = limit;
        this.filterModel.offset = 0;
        this.totalRecords = 0;
        this.service.getList(this.groupId, this.filterModel)
            .subscribe({
                next: (response: Array<BroadcastListItemModel>) => {
                    this.model = new Array<BroadcastListItemModel>();
                    this.model = response;
                    this.totalRecords = response.length;
                    if (this.model.length > 0) {
                        this.model.forEach((x) => {
                            x.defaultUserMediaAppUrl = this.getMediaIconUrl(x.defaultUserMediaAppType);
                            x.showDefaultIcon = true;
                        });
                    }
                    if (initialLoading) {
                        this.hasBroadcastList = this.model.length > 0;
                    }
                    this.selectBroadcasts();

                    this.isModelLoaded = true;
                    this.isLoading = false;
                },
                error: (err) => {
                    this.isLoading = false;
                    this.isModelLoaded = true;
                    this.baseService.processErrorResponse(err);
                }
            });
    }

    onScroll(event: any) {
        const element = this.scrollContainer.nativeElement;
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight * 0.8 && this.hasMore && !this.loadingMore) {
            this.getMoreRecords();
        }
    }

    generateUrl(defaultMediaAppType: string): string {
        return `./assets/images/media-types/${defaultMediaAppType}.svg`
    }

    getMediaIconUrl(mediaType: string): string {
        const media = this.medias.find(x => x.id == mediaType);
        if (media != undefined) {
            return media.iconUrl;
        }
        return '';
    }

    onSearch() {
        if (this.filterModel.filterKey) {
            this.getBroadcastList();
        }
    }

    clearSearch() {
        this.filterModel.filterKey = '';
        this.getBroadcastList();
    }

    sortBroadcast() {
        if (this.sortOrder === 'ascending') {
            this.filterModel.sortBy = 'name';
            this.filterModel.sortDir = 'asc';
        } else if (this.sortOrder === 'descending') {
            this.filterModel.sortBy = 'name';
            this.filterModel.sortDir = 'desc';
        }

        this.getBroadcastList(false, this.totalRecords);
    }

    cancel(): void {
        this.closeDrawer.emit({ selectedBroadcasts: new Array<BroadcastListItemModel>(), updateValue: false });
    }

    onError(item: BroadcastListItemModel): void {
        item.urlError = true;
    }

    filterKeyChanged() {
        if (this.appUtils.isNullOrEmpty(this.filterModel.filterKey)) {
            this.getBroadcastList();
        }
    }

    close(): void {
        this.isDrawerVisisble = false;
    }

    openBroadcastAddDrawer(): void {
        this.showBroadcastAddDrawer = true;
        this.drawer.open();
    }

    closeBroadcastAddDrawer(event: any): void {
        this.drawer.close();
        this.showBroadcastAddDrawer = false;
        if (event.broadcastAdded) {
            this.newBroadcastId = event.broadcastId;
            this.getBroadcastList(true);
        }
        this.getBroadcastList(true);
    }

    selectAllBroadcast(event: any): void {

        if (event) {
            this.model.forEach(x => {
                x.isSelected = true;
                this.isBroadcastSelected = true;
            });
        }
        else {
            this.model.forEach(x => {
                x.isSelected = false;
                this.isBroadcastSelected = false;
            });
        }
    }

    submit() {

    }

    toggleBroadcast() {
        if (this.model.every(x => x.isSelected)) {
            this.allBroadcastSelected = true;
        }
        if (!this.model.every(x => x.isSelected)) {
            this.allBroadcastSelected = false;
        }

        if (this.model.some(x => x.isSelected)) {
            this.isBroadcastSelected = true;
        }
        else {
            this.isBroadcastSelected = false;
        }
    }

    sendBroadcast(): void {
        let broadcastIdstoRemove = new Array<number>();
        this.selectedBroadcasts.forEach((x => {
            let broadcast = this.model.filter(y => y.id == x.id);
            if (broadcast && broadcast.length > 0) {
                if (!broadcast[0].isSelected) {
                    broadcastIdstoRemove.push(broadcast[0].id);
                }
            }
        }));

        this.selectedBroadcasts = this.selectedBroadcasts.filter(x => !broadcastIdstoRemove.includes(x.id));

        var selectedPlaylist = this.model.filter(x => x.isSelected);
        selectedPlaylist.forEach((x => {
            let exists = this.selectedBroadcasts.some(y => y.id == x.id);
            if (!exists) {
                this.selectedBroadcasts.push(x);
            }
        }));

        this.closeDrawer.emit({ selectedBroadcasts: this.selectedBroadcasts, updateValue: true });

    }

    handleReloadBroadcast(broadcastIds: Array<number>) {
        if (!broadcastIds || broadcastIds.length === 0) {
            console.log('No broadcast found to reload');
            return;
        }

        if (this.model.filter(x => broadcastIds.indexOf(x.id) !== -1).length === 0) {
            console.log('No broadcast loaded in current screen');
        }
        // reload broadcast
        this.getBroadcastList();
    }

    getMoreRecords() {
        if (this.loadingMore) { return; }
        this.isLoading = true;
        this.loadingMore = true;
        this.filterModel.limit = 5;
        this.filterModel.offset = this.totalRecords; 
        this.service.getList(this.groupId, this.filterModel)
            .subscribe({
                next: (response: Array<BroadcastListItemModel>) => {
                    if (response && response.length > 0) {
                        response.forEach((x) => {
                            x.defaultUserMediaAppUrl = this.getMediaIconUrl(x.defaultUserMediaAppType);
                            x.showDefaultIcon = true;
                            if (this.allBroadcastSelected) {
                                x.isSelected = true;
                            }
                            this.model.push(x);
                        });
                        this.totalRecords += response.length;
                    }
                    else {
                        this.hasMore = false;
                    }
                    this.selectBroadcasts();
                    this.loadingMore = false;
                    this.isLoading = false;
                },
                error: (err) => {
                    this.isLoading = false;
                    this.loadingMore = false;
                    this.baseService.processErrorResponse(err);
                }
            });
    }

    ngOnDestroy(): void {
        this.isLoading = false;
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }
}