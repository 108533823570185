export class DisplayListListItemModel{
    id: number;
    defaultUserMediaAppUrl: string;
    name: string;
    userMediaAppCount: number;
    defaultUserMediaAppType: string;
    urlError: boolean;
    medias: Array<number>;
    showDefaultIcon: boolean;
    isSelected: boolean;
}