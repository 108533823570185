import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appCustomMax][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: CustomMaxDirective, multi: true },
  ],
})
export class CustomMaxDirective implements Validator {
  @Input() appCustomMax: number;

  validate(c: FormControl): { [key: string]: any } {
    const v = c.value;
    return v > this.appCustomMax ? { appCustomMax: true } : null;
  }
}

@Directive({
    selector: '[appCustomMin][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CustomMinDirective, multi: true }]
})

export class CustomMinDirective implements Validator {
    @Input() appCustomMin: number;

    validate(c: FormControl): { [key: string]: any } {
        const v = c.value;
        return (v < this.appCustomMin) ? { 'appCustomMin': true } : null;
    }
}
