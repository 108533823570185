<section>
    <div nz-row>
        <div nz-col nzSpan="24">
            {{message}}
        </div>
    </div>
    <div nz-row class="mt-3 mb-2">
        <div nz-col nzSpan="24" class="text-right">
            <button nz-button nzType="default" (click)="cancel()" class="mr-3">Cancel
            </button>
            <button nz-button nzType="primary" (click)="proceed()">{{submitButtonText}}
            </button>
        </div>
    </div>
</section>