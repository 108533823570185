import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import moment from "moment";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { AppUtils, Constants } from "src/helpers";
import { GroupDetailModel, MediaAppDetailModel, MediaAppListItemModel, SelectListItemModel, UserMediaAppGoogleSlideUpsertModel } from "src/app/shared/models";
import { UserMediaAppUpsertModel } from "src/app/shared/models/user-media-app/user-media-app-upsert.model";
import { BaseService, DisplayListService, FolderService, GroupService, MediaAppService, UserMediaAppService } from "src/app/shared/services";

declare let gapi: any;
declare let google: any;
@Component({
    selector: 'app-user-media-app-google-slide-drawer',
    templateUrl: './user-media-app-google-slide.component.html',
    styleUrls: ['./user-media-app-google-slide.component.scss']
})

export class UserMediaAppGoogleSlideComponent implements OnInit {
    @Input() mediaAppId: any;
    @Output() closeDrawer: EventEmitter<{ mediaId: number, mediaAdded: boolean }> = new EventEmitter<{ mediaId: number, mediaAdded: boolean }>();
    @Input() selectedGroupId: number;

    isLoading = false;
    showScheduleFields = false;
    visible = false;
    model = new UserMediaAppUpsertModel();
    showGroupSelection = true;
    subscriptions = new Array<Subscription>();
    subscriptionPlans = new Array<SelectListItemModel>();
    mediaAppModel = new MediaAppDetailModel();
    groups = new Array<GroupDetailModel>();
    folders = new Array<SelectListItemModel>();
    selectedFolder = new Array<SelectListItemModel>();
    displayLists = new Array<SelectListItemModel>();
    selectedDisplayLists = new Array<SelectListItemModel>();
    mediaApps: Array<MediaAppListItemModel>;
    showDuration = false;
    isEndDateValid = true;
    isEndTimeValid = true;
    isStartTimeEndTimeValid = true;
    schedule = false;
    modelLoaded = false;
    isScheduled: boolean = false;
    scheduleStartTime: any;
    scheduleEndTime: any;
    pickerApiLoaded: boolean;
    imageUrlToPreview: any;
    isGroupLoaded: boolean = false;

    get constants() { return Constants; }

    constructor(
        private baseService: BaseService,
        private groupService: GroupService,
        private folderService: FolderService,
        private userMediaAppService: UserMediaAppService,
        private displayListService: DisplayListService,
        private mediaAppService: MediaAppService,
        public appUtils: AppUtils,
        private httpClient: HttpClient,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.model = new UserMediaAppUpsertModel();
        this.model.googleSlide = new UserMediaAppGoogleSlideUpsertModel();

        this.subscriptionPlans = new Array<SelectListItemModel>();
        this.folders = new Array<SelectListItemModel>();
        this.groups = new Array<GroupDetailModel>();
    }

    ngOnInit() {
        this.model.startDate = new Date();
        this.model.endDate = new Date();
        this.model.mediaAppId = this.mediaAppId;
        this.getMediaAppDetails();
        this.loadGroups();
        this.getMediaAppList();
    }

    getMediaAppDetails() {
        this.mediaAppService.get(this.model.mediaAppId)
            .subscribe({
                next: (response: MediaAppDetailModel) => {
                    this.mediaAppModel = response;
                    this.mediaAppModel.iconUrl = this.generateMediaIcon(this.mediaAppModel.typeString)

                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    generateMediaIcon(mediaAppType: string): string {
        return `./assets/images/media-types/icons/v2/${mediaAppType}.svg`;
    }

    loadGroups(): void {
        this.isLoading = true;
        const requestSubscription =
            this.groupService.getList()
                .subscribe({
                    next: (response: Array<GroupDetailModel>) => {
                        let selectListItem = new SelectListItemModel();
                        selectListItem.keyString = '-1';
                        selectListItem.keyInt = -1;
                        selectListItem.value = `My Subscription (${this.appUtils.getUserName()})`;

                        this.subscriptionPlans.push(selectListItem);

                        Object.assign(this.groups, response);

                        this.groups.forEach(group => {
                            selectListItem = new SelectListItemModel();
                            selectListItem.keyInt = group.id;
                            selectListItem.keyString = group.id.toString();
                            selectListItem.value = `${group.name} (${group.ownerName})`;
                            this.subscriptionPlans.push(selectListItem);
                        });

                        if (this.selectedGroupId != undefined && this.selectedGroupId > 0) {
                            this.model.groupId = this.selectedGroupId;
                        }
                        else {
                            this.selectedGroupId = -1;
                            this.model.groupId = -1;
                        }

                        this.isGroupLoaded = true;
                        this.isLoading = false;
                        this.getFolders();
                        this.loadDisplayLists();
                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);

                    }
                });

        this.subscriptions.push(requestSubscription);
    }

    getFolders() {
        this.isLoading = true;
        if (!this.model.folderId) {
            this.model.folderId = null;
        }
        const requestsubscription = this.folderService
            .getList(this.model.groupId, this.model.folderId)
            .subscribe({
                next: (response: Array<SelectListItemModel>) => {
                    this.folders = response;
                    this.updateSelectedFolder();
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestsubscription);
    }

    updateSelectedFolder() {
        if (this.folders.length === 0 || !this.model.folderId) {
            this.model.folderId = null;
            this.isLoading = false;
            return;
        }

        const selectedFolder = this.folders.filter(
            (x) => Number(x.keyInt) === Number(this.model.folderId)
        );

        if (selectedFolder) {
            this.selectedFolder = selectedFolder;
        }
    }

    loadDisplayLists() {
        if (!this.isGroupLoaded) {
            return;
        }
        this.displayLists = new Array<SelectListItemModel>();
        this.isLoading = true;
        const requestSubscription = this.displayListService
            .getSelectListItems(this.model.groupId)
            .subscribe({
                next: (data: Array<any>) => {
                    this.isLoading = false;
                    this.displayLists = data;
                    this.updateSelectedDisplayList();
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestSubscription);
    }

    updateSelectedDisplayList() {
        if (this.displayLists.length === 0) {
            return;
        }

        const selectedDisplayLists = new Array<SelectListItemModel>();
        this.model.displayLists.forEach((displayList) => {
            selectedDisplayLists.push(
                this.displayLists.find(
                    (x) => Number(x.keyInt) === Number(displayList.keyInt)
                )
            );
        });

        if (selectedDisplayLists && selectedDisplayLists.length > 0) {
            this.selectedDisplayLists = selectedDisplayLists;
        }
    }

    getMediaAppList(): void {
        this.isLoading = true;
        this.mediaAppService.getList().subscribe({
            next: (response: any) => {
                if (response) {
                    this.mediaApps = new Array<MediaAppListItemModel>();
                    response.forEach((x) => {
                        let mediaApp = new MediaAppListItemModel();
                        mediaApp.id = x.typeString;
                        mediaApp.name = x.title;
                        mediaApp.iconUrl =
                            x.iconUrl ?? this.generateMediaIconUrl(x.typeString);
                        mediaApp.previewUrl =
                            x.previewUrl ?? this.generateMediaPreviewUrl(x.typeString);
                        this.mediaApps.push(mediaApp);
                    });
                }
                this.imageUrlToPreview = this.getMediaForPreview();
                this.isLoading = false;
            },
            error: (error) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            },
        });
    }

    generateMediaIconUrl(mediaType: string): string {
        return `./assets/images/media-types/icons/${mediaType}.svg`;
    }

    generateMediaPreviewUrl(mediaType: string): string {
        return `./assets/images/media-types/preview/${mediaType}.svg`;
    }

    getMediaForPreview(): string {
        return `./assets/images/media-types/preview/GoogleSlides.svg`;
    }

    validateScheduleDetails(): boolean {
        if (!this.model.isScheduled) {
            this.model.startTime = null;
            this.model.endTime = null;
            this.model.startDate = null;
            this.model.endDate = null;
            return true;
        }

        if (this.scheduleStartTime && this.scheduleEndTime) {
            if (!this.isStartTimeEndTimeValid) {
                return false;
            }

            const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
            const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
            if (
                !moment(endTime).isAfter(moment(startTime)) &&
                startTime.format('a') === endTime.format('a')
            ) {
                this.isEndTimeValid = false;
                return false;
            }
            this.model.startTime = this.appUtils.getFormattedTime(
                startTime.toString(),
                null
            );
            this.model.endTime = this.appUtils.getFormattedTime(
                endTime.toString(),
                null
            );

            if (this.model.isDaily) {
                this.model.startDate = null;
                this.model.endDate = null;
                return true;
            }
            this.model.startDate = this.appUtils.getFormattedDate(
                this.model.startDate,
                null
            );
            this.model.endDate = this.appUtils.getFormattedDate(
                this.model.endDate,
                null
            );
            if (moment(this.model.endDate).isBefore(this.model.startDate)) {
                this.isEndDateValid = false;
                return false;
            }
            this.isEndDateValid = true;
            return true;
        } else {
            return false;
        }
    }

    isTimeValid(time: any) {
        const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
        const endTime = moment(time, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    checkMediaStatus() {
        if (!this.model.startTime || !this.model.endTime) {
            this.model.isScheduled = false;
            return;
        }
        this.model.isScheduled = true;
    }

    onScheduleClick() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.model.startTime = null;
        this.model.endTime = null;
        this.showScheduleFields = this.model.isScheduled;
    }

    disableStartDate = (current: Date): boolean => {
        return current && current < new Date(new Date().setHours(0, 0, 0, 0));
    }

    disableEndDate = (current: Date): boolean => {
        if (!this.model.startDate) {
            return current && current < new Date(new Date().setHours(0, 0, 0, 0));
        }
        return current && current < new Date(this.model.startDate);
    }

    onSchedule() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.scheduleStartTime = null;
        this.scheduleEndTime = null;

    }

    scheduleTypeChanged(isDaily: boolean) {
        this.model.isDaily = isDaily;
        this.model.startDate = null;
        this.model.endDate = null;
    }

    isStartTimeValid(time: any): void {
        const startTime = moment(time, 'hh:mm A').set({ s: 0 });
        const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    removeDisplayList(item: SelectListItemModel) {
        const tempArr = [];
        this.selectedDisplayLists.forEach((displayList) => {
            tempArr.push(displayList);
        });
        const itemIndex = tempArr.indexOf(item);
        tempArr.splice(itemIndex, 1);
        this.selectedDisplayLists = tempArr;
    }

    removeSlide(): void {
        this.model.googleSlide = new UserMediaAppGoogleSlideUpsertModel();
    }

    showGooglePicker() {
        this.isLoading = true;
        gapi.load('auth', { 'callback': this.onAuthApiLoad.bind(this) });
        gapi.load('picker', { 'callback': this.onPickerApiLoad.bind(this) });
    }

    onAuthApiLoad() {
        gapi.auth2.authorize(
            {
                'client_id': environment.googleApi.client_id,
                'scope': environment.googleApi.scope,
                'immediate': false
            },
            this.handleAuthResult.bind(event, this.model, this.changeDetectorRef, this.httpClient, this.isLoading));
        this.isLoading = false;

    }


    onPickerApiLoad() {
        this.pickerApiLoaded = true;
    }

    handleAuthResult(model: UserMediaAppUpsertModel, changeDetectorRef: any, httpClient: HttpClient, isLoading: boolean, authResult: any) {
        if (authResult && !authResult.error) {
            httpClient.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${authResult.access_token}`).subscribe(
                (res: any) => {
                    model.googleSlide.autherizedBy = res.email;
                });
            if (authResult.access_token) {
                const view = new google.picker.View(google.picker.ViewId.DOCS);
                view.setMimeTypes('application/vnd.google-apps.presentation');
                const pickerBuilder = new google.picker.PickerBuilder();
                const picker = pickerBuilder.
                    enableFeature(google.picker.Feature.NAV_HIDDEN).
                    setOAuthToken(authResult.access_token).
                    addView(view).
                    addView(new google.picker.DocsUploadView()).
                    setCallback((e: any) => {
                        if (e[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
                            const doc = e[google.picker.Response.DOCUMENTS][0];
                            model.googleSlide.slideName = doc.name;
                            model.googleSlide.slideEmbeddedUrl = doc.embedUrl;
                            model.googleSlide.slideEditUrl = doc.url;
                            model.googleSlide.autoAdvanceSlide = 10;
                            setTimeout(() => {
                                changeDetectorRef.detectChanges();
                            }, 100);
                            isLoading = false;
                        } else if (e[google.picker.Response.ACTION] == google.picker.Action.CANCEL) {
                            console.log(e[google.picker.Response.ACTION]);
                            isLoading = false;
                            changeDetectorRef.detectChanges();
                        }
                    }).
                    build();
                picker.setVisible(true);

            } else {
                isLoading = false;
            }
        } else {
            isLoading = false;
        }
    }


    startEditingDriveFile() {
        if (this.model.googleSlide.slideEditUrl) {
            window.open(this.model.googleSlide.slideEditUrl, '_blank');
        }
    }


    cancel(): void {
        this.closeDrawer.emit({ mediaId: 0, mediaAdded: false });
    }

    submit(): void {
        this.isLoading = true;
        if (this.model.groupId == null || this.model.groupId == -1) {
            this.model.groupId = null;
        }

        this.model.isScheduled = this.isScheduled;
        if (!this.validateScheduleDetails()) {
            this.isScheduled = true;
            this.isLoading = false;
            return;
        }

        this.model.displayLists = new Array<SelectListItemModel>();
        if (this.selectedDisplayLists && this.selectedDisplayLists.length > 0) {
            this.selectedDisplayLists.forEach((item) => {
                let selectedDisplayListModel = new SelectListItemModel();
                selectedDisplayListModel.keyInt = item.keyInt;
                selectedDisplayListModel.value = item.value;
                this.model.displayLists.push(selectedDisplayListModel);
            });
            this.model.lengthInSeconds =
                this.selectedDisplayLists.length === 1 || this.showDuration
                    ? this.model.lengthInSeconds
                    : '0';
        }

        if (this.selectedFolder && this.selectedFolder.length > 0) {
            this.model.folderId = this.selectedFolder[0].keyInt;
        } else {
            this.model.folderId = null;
        }

        this.model.id = 0;
        this.userMediaAppService.add(this.model).subscribe({
            next: (response: number) => {
                this.isLoading = false;
                this.baseService.successNotification('Media added successfully.');
                this.closeDrawer.emit({ mediaId: response, mediaAdded: true });
            },
            error: (error: any) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            }
        });
    }

    onGroupChange() {
        this.getFolders();
    }

}