export class UserMediaAppVimeoUpsertModel {
    public id: string;
    public userMediaAppId: number;
    public url: string;
    public embedUrl: string;
    public lengthInSeconds: string;
    public duration: number;
    public isEnabled: boolean;
    public displayOrder: number;
    public authorizedBy: string;
    public embeddedUrl: string;
    public loopEnabled: boolean;
    public videoTitle: string;
    public searchText: string;

    constructor() {
        this.isEnabled = true;
        this.loopEnabled = true;
        this.displayOrder = 0;
    }
}
