export class UserMediaAppPowerPointUpsertModel {
    public id: string;
    public userMediaAppId: number;
    public url: string;
    public lengthInSeconds: string;
    public isEnabled: boolean;
    public displayOrder: number;
    public autherizedBy: string;
    public pptName: string;
    public pptEditUrl: string;
    public pptEmbeddedUrl: string;

    constructor() {
        this.isEnabled = true;
        this.displayOrder = 0;
    }
}
