import { Component } from "@angular/core";
import { BaseService, GroupService } from "../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidateInvitationModel } from "../../models";
import { AppUtils } from "src/helpers";

@Component({
    selector: 'app-validate-invitation',
    templateUrl: 'validate-invitation.component.html',
    styleUrls: ['./validate-invitation.component.scss']
})

export class ValidateInvitationComponent {
    groupId: number;
    invitationId: string;
    isModelLoaded: boolean;
    isLoading: boolean;
    existingUser: boolean;
    groupExists: boolean;
    invitationExists: boolean;
    redirectUrl: string;
    acceptInvitation: boolean;
    loggedInUser: boolean = false;
    linkOrInvitationExpired: boolean;
    returnUrl: string;
    invitationToken: string;
    validateInvitationModel: ValidateInvitationModel = new ValidateInvitationModel();

    constructor(private groupService: GroupService,
        private router: Router,
        private route: ActivatedRoute,
        private appUtils: AppUtils,
        private baseService: BaseService
    ) {
    }

    ngOnInit(): void {
        this.loggedInUser = !AppUtils.isNullOrUndefined(this.appUtils.isUserAuthenticated()) && this.appUtils.isUserAuthenticated() != '';
        this.route.queryParams.subscribe(params => {
            this.groupId = +params['groupId']; 
            this.invitationId = params['invitationId'];
            this.invitationId = params['invitationId'];
            this.existingUser = params['u'] === 'e';
            this.acceptInvitation = params['i'] === 'a';
            this.invitationToken = params['invitationToken'];

            console.log('Group ID:', this.groupId);
            console.log('Invitation ID:', this.invitationId);
            console.log('Is Existing User:', this.existingUser);
            console.log('Accept Invitation:', this.acceptInvitation);
            console.log("invitationToken", this.invitationToken);
        });

        this.validateInvitation();
    }

    validateInvitation() {
        this.isLoading = true;
        this.isModelLoaded = false;
        this.groupService.validateInvitation(this.groupId, this.invitationId, this.invitationToken)
            .subscribe({
                next: (response: ValidateInvitationModel) => {
                    console.log("response", response);
                    this.invitationExists = response.invitationExists;
                    this.groupExists = response.groupExists;
                    console.log("existing user", this.existingUser)
                    if (!this.existingUser) {
                        console.log("existing user", this.existingUser)
                        if(response.inviteAccepted || response.inviteRejected){
                            this.linkOrInvitationExpired = true;
                            this.isModelLoaded = true;
                            this.isLoading = false;
                            return;
                        }
                        if (!response.groupExists || !response.invitationExists) {
                            if(response.inviteAccepted || response.inviteRejected){
                                this.linkOrInvitationExpired = true;
                            }
                            this.isLoading = false;
                            this.isModelLoaded = true;
                            return;
                        }
                        if (response.groupExists && response.invitationExists) {
                            const returnUrl = `group/edit/${this.groupId}?invite=true`;
                            const encodedReturnUrl = encodeURI(returnUrl);
                            this.isLoading = false;
                            this.isModelLoaded = true;
                            this.router.navigate(['/account/signup'], { queryParams: { returnUrl: encodedReturnUrl } });
                            return;
                        }
                    } else {
                        if (!response.groupExists || !response.invitationExists) {
                            this.isLoading = false;
                            this.isModelLoaded = true;
                            return;
                        }
                        //apply check if logged in or not
                        if (this.loggedInUser) {

                            if (response.groupExists && response.invitationExists) {
                                if (this.acceptInvitation) {
                                    this.router.navigate(['group/edit', this.groupId], { queryParams: { invite: true, i: 'a' } });
                                }
                                else {
                                    this.router.navigate(['group/edit', this.groupId], { queryParams: { invite: true, i: 'r' } });
                                };

                                this.isModelLoaded = true;
                                this.isLoading = false;
                                return;
                            }
                        }
                        else {
                            if (response.groupExists && response.invitationExists) {
                                let redirectUrl = '';
                                if (this.acceptInvitation) {
                                    redirectUrl = `group/edit/${this.groupId}?invite=true&i=a`;
                                }
                                else {
                                    redirectUrl = `group/edit/${this.groupId}?invite=true&i=r`;
                                }
                                const encodedReturnUrl = encodeURI(redirectUrl);
                                this.isLoading = false;
                                this.isModelLoaded = true;
                                this.router.navigate(['/account/login'], { queryParams: { returnUrl: encodedReturnUrl } });
                                return;
                            }
                        }
                    }

                    this.isLoading = false;
                    this.isModelLoaded = true;
                },
                error: (err: any) => {
                    this.isLoading = false;
                    this.isModelLoaded = true;
                    this.baseService.processErrorResponse(err);
                }
            })
    }

    goToGroups() {
        var redirectUrl= encodeURI('group/manage');
        this.router.navigate(['/account/login'], {queryParams: {returnUrl: redirectUrl}});

    }
}