import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BroadcastAddModel, BroadcastEditModel, FilterModel, ListItemsFilterModel } from "../models";
import { group } from "@angular/animations";

@Injectable()
export class BroadcastService {
    private apiEndPoint = environment.apiBaseUrl + 'api/broadcast';

    constructor(private http: HttpClient) { }

    getList(groupId?: number, model?: FilterModel): Observable<any> {
        if (!groupId || groupId === -1) {
            groupId = null;
        }

        if (model == null || model == undefined) {
            return this.http.get(this.apiEndPoint + '/list/' + groupId);
        }

        if (model.offset == undefined) {
            model.offset = null;
        }
        if (model.limit == undefined) {
            model.limit = null;
        }
        if (model.filterKey == undefined || model.filterKey == null) {
            model.filterKey = '';
        }

        return this.http.get(this.apiEndPoint + '/list/' + groupId + '?' + model.toQueryString());
    }

    addBroadcast(model: BroadcastAddModel) {
        return this.http.post(`${this.apiEndPoint}/add`, model);
    }

    editBroadcast(model: BroadcastEditModel) {
        return this.http.post(this.apiEndPoint + '/edit', model);
    }

    getForEdit(id: number) {
        return this.http.get(this.apiEndPoint + '/get-for-edit/' + id);
    }
}