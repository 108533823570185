export class EstimateModel {
    subTotal: number;
    quantity: number;
    unitAmount: number;
    nextBillingDate: any;
    amountDue: number;
    nextBillingAmount: number;
    availableCredits: number;
    refundableCredits: number;
    creditsApplied: number;
}