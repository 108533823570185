import { Component, EventEmitter, Input, input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { GroupModel } from 'src/app/display/models';
import {
  BroadcastAddModel,
  BroadcastRecurringModel,
  GroupDetailModel,
  SelectListItemModel,
  TimeZoneModel,
} from 'src/app/shared/models';
import {
  BaseService,
  GroupService,
  DisplayService,
  UserMediaAppService,
  BroadcastService,
} from 'src/app/shared/services';
import { FolderService } from 'src/app/shared/services/folder.service';
import { TimeZoneService } from 'src/app/shared/services/time-zone.service';
import { AppUtils, Constants } from 'src/helpers';

@Component({
  selector: 'app-broadcast-add',
  templateUrl: './broadcast-add.component.html',
  styleUrls: ['./broadcast-add.component.scss']
})
export class BroadcastAddComponent implements OnInit {
  @Output() closeDrawer: EventEmitter<{broadcastAdded: boolean, broadcastId: number}> = new EventEmitter<{broadcastAdded: boolean, broadcastId: number}>();
  @Input() selectedGroupId: number;
  model = new BroadcastAddModel();
  subscriptions = new Array<Subscription>();
  subscriptionPlans = new Array<SelectListItemModel>();
  displays = new Array<SelectListItemModel>();
  userMediaApps = new Array<SelectListItemModel>();
  selectedDisplays = new Array<SelectListItemModel>();
  startDateTypes = new Array<SelectListItemModel>();
  endDateTypes = new Array<SelectListItemModel>();
  recurrenceTypes = new Array<SelectListItemModel>();
  weekDays = new Array<SelectListItemModel>();
  monthDays = new Array<SelectListItemModel>();
  folders: Array<SelectListItemModel>;
  timeZones = new Array<TimeZoneModel>();
  groups = new Array<GroupModel>();

  minDate: any;
  minDateTime: any;
  minEndDateTime: any;

  isGroupLoaded = false;
  isDisplaysLoaded = false;
  isUserMediaAppLoaded = false;
  isSeparationCountValid = true;
  isEndDateTimeValid = true;
  isLoading = false;
  isMediaSelected = false;
  isDisplaySelected = false;
  isDrawerVisible = false;
  disableGroupSelection: boolean = false;
  showGroupSelection = true;


  get constants() { return Constants; }

  constructor(
    private appUtils: AppUtils,
    private groupService: GroupService,
    private baseService: BaseService,
    private displayService: DisplayService,
    private userMediaAppService: UserMediaAppService,
    private timeZoneService: TimeZoneService,
    private folderService: FolderService,
    private broadcastService: BroadcastService
  ) {
    const startDate = new Date();
    this.minDate = {
      year: startDate.getFullYear(),
      month: startDate.getMonth() + 1,
      day: startDate.getDate()
    };

    this.minDateTime = new Date().setHours(0, 0, 0, 0);
    this.minEndDateTime = new Date();

    const selectedGroupId = this.appUtils.getGroupId();
    if (selectedGroupId) {
      if (selectedGroupId !== -1) {
        this.model.groupId = selectedGroupId;
        this.disableGroupSelection = true;

      } else {
        this.model.groupId = null;
        this.disableGroupSelection = true;
      }
    } else {
      this.model.groupId = -1;
    }
    this.startDateTypes = this.appUtils.getStartTypeSelectListItems();
    if (this.startDateTypes.find((x) => x.isSelected)) {
      this.model.startDateType = this.startDateTypes.find(
        (x) => x.isSelected
      ).keyInt;
    }
    this.endDateTypes = this.appUtils.getEndTypeSelectListItems();
    if (this.endDateTypes.find((x) => x.isSelected)) {
      this.model.endDateType = this.endDateTypes.find(
        (x) => x.isSelected
      ).keyInt;
    }
    this.recurrenceTypes = this.appUtils.getRecurrenceTypeSelectListItems();
    this.weekDays = this.appUtils.getWeekDaysSelectListItem();
    this.monthDays = this.appUtils.getDateSelectListItem();
  }

  ngOnInit(): void {
    this.loadGroups();
    this.loadDisplays();
    this.loadUserMediaApps();
    this.loadTimeZones();
    this.loadFolders();
  }

  loadGroups(): void {
    this.isLoading = true;
    this.groupService.getList()
      .subscribe({
        next: (response: Array<GroupDetailModel>) => {
          let selectListItem = new SelectListItemModel();
          selectListItem.keyString = '-1';
          selectListItem.keyInt = -1;
          selectListItem.value = `My Subscription (${this.appUtils.getUserName()})`;

          this.subscriptionPlans.push(selectListItem);

          Object.assign(this.groups, response);

          this.groups.forEach(group => {
            selectListItem = new SelectListItemModel();
            selectListItem.keyInt = group.id;
            selectListItem.keyString = group.id.toString();
            selectListItem.value = `${group.name} (${group.ownerName})`;
            this.subscriptionPlans.push(selectListItem);
          });

          if (this.selectedGroupId != undefined && this.selectedGroupId > 0) {
            this.model.groupId = this.selectedGroupId;
          }
          else {
            this.selectedGroupId = -1;
            this.model.groupId = -1;
          }

          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.baseService.processErrorResponse(error);
        }
      })
  }

  loadDisplays() {
    this.isLoading = true;
    this.isDisplaysLoaded = false;
    this.selectedDisplays = new Array<SelectListItemModel>();
    const requestSubscription = this.displayService
      .getSelectListItems(this.model.groupId)
      .subscribe({
        next: (data: Array<SelectListItemModel>) => {
          this.isLoading = false;
          this.displays = data;
          this.isDisplaysLoaded = true;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.isDisplaysLoaded = true;
          this.baseService.processErrorResponse(error);
        },
      });

    this.subscriptions.push(requestSubscription);
  }

  loadUserMediaApps() {
    this.isLoading = true;
    this.isUserMediaAppLoaded = false;
    const requestSubscription = this.userMediaAppService
      .getSelectListItems(this.model.groupId)
      .subscribe({
        next: (data: Array<SelectListItemModel>) => {
          this.isLoading = false;
          this.userMediaApps = data;
          this.isUserMediaAppLoaded = true;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.isUserMediaAppLoaded = true;
          this.baseService.processErrorResponse(error);
        },
      });

    this.subscriptions.push(requestSubscription);
  }

  loadTimeZones() {
    const offSet = this.appUtils.getUtcOffSet();
    const requestSubscription = this.timeZoneService.getAll().subscribe({
      next: (timeZones: Array<TimeZoneModel>) => {
        Object.assign(this.timeZones, timeZones);

        const currentTimeZone = this.timeZones.find(
          (x) => x.baseUtcOffsetMinutes === offSet
        );

        if (currentTimeZone) {
          this.model.timeZone = currentTimeZone.standardName;
        } else {
          console.log('Unable to detect current timezone');
        }
      },
      error: (error: any) => {
        this.baseService.processErrorResponse(error);
      },
    });

    this.subscriptions.push(requestSubscription);
  }

  loadFolders(): void {
    this.isLoading = true;
    this.folderService.getList(this.model.groupId, this.model.folderId).subscribe({
      next: (response: Array<SelectListItemModel>) => {
        this.isLoading = false;
        this.folders = response;
      },
      error: (error: any) => {
        this.isLoading = false;
        this.baseService.processErrorResponse(error);
      },
    });
  }

  onStartDateTypeChanged() {
    this.model.startDateTime = null;
    if (Number(this.model.startDateType) === 2) {
      this.model.resetStartDate = true;
      this.model.startDateTime = this.appUtils.getFullDateTime();
    } else {
      this.model.resetStartDate = false;
    }
    if (Number(this.model.startDateType) !== 1 || Number(this.model.endDateType) !== 1) {
      this.model.isRecurring = false;
    }
  }

  onEndDateTypeChanged() {
    this.model.endDateTime = null;
    if (Number(this.model.endDateType) === 2) {
      this.model.resetEndDate = true;
      this.model.isAutoDelete = false;
    } else {
      this.model.resetEndDate = false;
    }
    if (Number(this.model.startDateType) !== 1 || Number(this.model.endDateType) !== 1) {
      this.model.isRecurring = false;
    }
  }

  onRecurrenceClick(event: boolean): void {
    this.model.isRecurring = event;
    if (!this.model.isRecurring || (this.model.isRecurring && !this.model.recurring)) {
      this.model.recurring = new BroadcastRecurringModel();
    }
  }

  cancel(): void {
    this.closeDrawer.emit({broadcastAdded: false, broadcastId: 0});
  }

  onDisplaySelected() {
    this.selectedDisplays = this.model.displayIds.map(id => {
      return this.displays.find(display => display.keyInt === id);
    });
  }

  disableStartDate = (current: Date): boolean => {
    return current && current < this.minDateTime;
  };

  disableEndDate = (current: Date): boolean => {
    if (!this.model.startDateTime) {
      return current && current.getDate() < this.minEndDateTime.getDate();
    }
    return current && current.getDate() < this.model.startDateTime.getDate();
  };

  disableRecurringEndDate = (current: Date): boolean => {
    return current && current.getDate() < this.minEndDateTime.getDate();
  };

  isEndDateValid(): void {
    if (Number(this.model.startDateType) === 1 &&
      (!moment(this.model.endDateTime).set({ s: 0 }).isAfter(moment(this.model.startDateTime).set({ s: 0 }))
        || !moment(this.model.endDateTime).isAfter(this.appUtils.getFullDateTime()))) {
      this.isEndDateTimeValid = false;
      return;

    }
    // live immediately
    else if (Number(this.model.startDateType) === 2 && !moment(this.model.endDateTime).set({ s: 0 }).isAfter(this.appUtils.getFullDateTime())) {
      this.isEndDateTimeValid = false;
      this.model.startDateTime = this.appUtils.getFullDateTime();
      return;
    } else {
      this.isEndDateTimeValid = true;
    }
  }

  onRepeatTypeChange() {
    this.model.recurring.days = new Array<number>();
    if (this.model.recurring.days && !this.model.recurring.days[0]) {
      this.model.recurring.days[0] = null;
    }
    if (Number(this.model.recurring.recurringType) === this.constants.recurrenceType.monthly) {
      this.model.recurring.days[0] = new Date(this.model.startDateTime).getDate();
    }
    if (Number(this.model.recurring.recurringType) === this.constants.recurrenceType.minutely) {
      this.model.recurring.separationCount = 5;
    }
  }

  submit(): void {
    if (this.model.displayIds.length === 0) {
      this.isDisplaySelected = false;
    }

    if (!this.isDisplaySelected || (!this.isEndDateTimeValid && Number(this.model.endDateType) !== 2)) {
      return;
    }


    if (Number(this.model.startDateType) !== 2) {
      if (this.model.startDateTime) {
        this.model.startDate = this.appUtils.getFormattedDate(this.model.startDateTime, null);
        this.model.startTime = this.appUtils.getFormattedTime(this.model.startDateTime, null);
      }
    } else {
      this.model.startDateTime = null;
      this.model.startDate = null;
      this.model.startTime = null;
      this.model.isStartDateValid = true;
    }

    if (Number(this.model.endDateType) !== 2) {
      if (Number(this.model.startDateType) === 1 && (!moment(this.model.endDateTime).set({ s: 0 }).isAfter(moment(this.model.startDateTime).set({ s: 0 })) || !moment(this.model.endDateTime).set({ s: 0 }).isAfter(this.appUtils.getFullDateTime()))) {
        this.isEndDateTimeValid = false;
        return;

      } else if (Number(this.model.startDateType) === 2 && !moment(this.model.endDateTime).set({ s: 0 }).isAfter(this.appUtils.getFullDateTime())) {
        this.isEndDateTimeValid = false;
        this.model.startDateTime = this.appUtils.getFullDateTime();
        return;
      }
      if (this.model.endDateTime) {
        this.model.endDate = this.appUtils.getFormattedDate(this.model.endDateTime, null);
        this.model.endTime = this.appUtils.getFormattedTime(this.model.endDateTime, null);
      }
    } else {
      this.model.endDateTime = null;
      this.model.endDate = null;
      this.model.endTime = null;
      this.model.isEndDateValid = true;
    }

    if (this.model.isRecurring) {
      if (Number(this.model.recurring.recurringType) === this.constants.recurrenceType.weekly) {
        this.model.recurring.days = this.weekDays.filter(x => x.isSelected)
          .map((x) => {
            return x.keyInt;
          });
      }
      if (Number(this.model.recurring.recurringType) !== this.constants.recurrenceType.weekly
        && Number(this.model.recurring.recurringType) !== this.constants.recurrenceType.monthly) {
        this.model.recurring.days = new Array<number>();
      }
      if ((Number(this.model.recurring.recurringType) === this.constants.recurrenceType.minutely)
        && (moment(this.model.endDateTime).diff(moment(this.model.startDateTime).set({ s: 0 })) / 60000) >= this.model.recurring.separationCount) {
        this.isSeparationCountValid = false;
        return;
      }
      if ((Number(this.model.recurring.recurringType) === this.constants.recurrenceType.hourly)
        && (moment(this.model.endDateTime).diff(moment(this.model.startDateTime).set({ s: 0 })) / 60000) >= (this.model.recurring.separationCount * 60)) {
        this.isSeparationCountValid = false;
        return;
      }
      this.isSeparationCountValid = true;
    } else {
      this.model.recurring = null;
    }

    this.isLoading = true;
    this.model.id = 0;

    this.broadcastService.addBroadcast(this.model).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.baseService.success('Broadcast added successfully.');
        if (res.isAnyBroadcastDisabled) {
          setTimeout(() => {
            const message = `You can only have one active broadcast per screen scheduled
                     at one time. We disabled the previous broadcast scheduled for this time`;
            this.baseService.success(message);
          }, 1000);
        }
        this.close(res.id);
      },
      error: error => {
        this.isLoading = false;
        this.baseService.processErrorResponse(error);
      }
    });
  }

  removeDisplay(display: SelectListItemModel): void {
    this.selectedDisplays = this.selectedDisplays.filter(d => d !== display);
    this.model.displayIds = this.model.displayIds.filter(id => id !== display.keyInt);
    if (this.selectedDisplays.length == 0) {
      this.isDisplaySelected = false;
    }
  }

  close(id: number = 0) {
    this.closeDrawer.emit({broadcastAdded: true, broadcastId: id});
  }

  openMediaStoreDrawer() {
    this.isDrawerVisible = true;
  }

  closeMediaStoreDrawer() {
    this.isDrawerVisible = false;
    this.loadUserMediaApps();
  }

  onGroupChange(groupId: number): void {
    this.model.folderId = null;
    this.model.groupId = groupId;
    this.loadFolders();
    this.loadDisplays();
    this.loadUserMediaApps();
  }
}
