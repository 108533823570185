import { Component, Output, EventEmitter, Input, ChangeDetectorRef, ViewChild, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AppUtils, Constants } from "src/helpers";
import { DisplayListModel, GroupListItemModel, SelectListItemModel, UserMediaAppSummaryModel, DisplayListUserMediaAppModel, UserMediaAppImageUpsertModel, UserMediaAppVideoUpsertModel, UserMediaAppPdfUpsertModel, UserMediaAppCanvaUpsertModel, UserMediaAppGoogleSlideUpsertModel, UserMediaAppPowerPointUpsertModel, UserMediaAppVimeoUpsertModel, UserMediaAppYouTubeUpsertModel, UserMediaAppWeatherUpsertModel, MediaModel, DisplayListListItemModel, GroupModel } from "src/app/shared/models";
import { BaseService, DisplayListService, DisplayService, FolderService, GroupService, UserMediaAppService } from "src/app/shared/services";
import { ListenerService } from "src/services";
import { NzDrawerComponent } from "ng-zorro-antd/drawer";

@Component({
    selector: 'app-display-list-upsert',
    templateUrl: './display-list-upsert.component.html'
})

export class DisplayListUpsertComponent implements OnInit {
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @Input() selectedGroupId: number;
    @Input() displaylistId: number;
    @Input() addFromDisplay: boolean;
    @Input() addFromMedia: boolean;
    @Output() closeDrawer: EventEmitter<{ displayListId: number, displayListAdded: boolean }> = new EventEmitter<{ displayListId: number, displayListAdded: boolean }>();
    model: DisplayListModel;
    groups: Array<GroupModel>;
    folders: Array<SelectListItemModel>;
    transitionTypes = new Array<SelectListItemModel>();
    subscriptionPlans = new Array<SelectListItemModel>();
    userMediaAppSummaryListItems = new Array<UserMediaAppSummaryModel>();
    isLoading = false;
    userMediaApps = new Array<UserMediaAppSummaryModel>();
    isUserMediaAppsSummaryLoaded: boolean = false;
    isDisplaysLoaded: boolean = false;
    isUserMediaAppsLoaded: boolean = false;
    subscriptions = new Array<Subscription>();
    pendingDisplayListIds: Array<number>;
    showGroupSelection: boolean;
    pendingDisplayListIdIndex: number;
    showMediaDrawer = false;
    isDrawerVisible = false;
    isModelLoaded: boolean;
    displayTime: number;
    isScheduled: boolean = false;
    isEndTimeValid: boolean = true;
    isStartTimeEndTimeValid: boolean;
    isEndDateValid: boolean = true;
    showDisplayDrawer: boolean;

    selectedUserMediaApps = new Array<UserMediaAppSummaryModel>();
    selectedDisplays = new Array<SelectListItemModel>();
    displays = new Array<SelectListItemModel>();
    selectedUserMediaAppSummaryListItems = new Array<UserMediaAppSummaryModel>();
    disableAddAndPreviewButton = true;
    selectedFolder = new Array<SelectListItemModel>();
    singleSelctionMultiSelectDropdownForFolder = { ...Constants.configsSingleSelctionMultiSelectDropdown };
    displaysMultiSelectDropdown = { ...Constants.configsMultiSelectDropdown };
    userMediaAppsMultiSelectDropdown = { ...Constants.configsMultiSelectDropdown };

    constructor(
        private messageService: ListenerService,
        private groupService: GroupService,
        private displayListService: DisplayListService,
        private folderService: FolderService,
        private userMediaAppService: UserMediaAppService,
        private baseService: BaseService,
        private appUtils: AppUtils,
        private cdr: ChangeDetectorRef,
        private displayService: DisplayService
    ) {
        this.model = new DisplayListModel();
        this.groups = new Array<GroupModel>();
        this.folders = new Array<SelectListItemModel>();
        this.transitionTypes = this.appUtils.getTransitionTypeSelectListItems();

        const subscription = new Subscription();

        const reloadUserMediaAppDataSubscription = this.messageService
            .listenReloadUserMediaAppData.subscribe(() => {
                this.loadUserMediaAppSummaryListItems();
            });

        this.subscriptions.push(reloadUserMediaAppDataSubscription);

        this.singleSelctionMultiSelectDropdownForFolder.noDataAvailablePlaceholderText
            = 'No Folder added';
        this.displaysMultiSelectDropdown.noDataAvailablePlaceholderText = 'No Screens added';
        this.userMediaAppsMultiSelectDropdown.noDataAvailablePlaceholderText = 'No Medias added';

    }

    onDragged(item: any, list: UserMediaAppSummaryModel[]) {
        const index = list.indexOf(item);
        list.splice(index, 1);

        list.forEach((x, index) => {
            x.displayOrder = index + 1;
        });
    }

    ngOnInit(): void {
        this.model.id = this.displaylistId;
        this.getGroups();
        this.pendingDisplayListIds = this.appUtils.getPendingDisplayListIds();
        // this.getUserMediaApps();
        // this.openAddDisplayListModal();

    }

    updateUserMediaApp(event: any) {

    }

    getGroups(): void {
        this.isLoading = true;
        this.groupService.getList()
            .subscribe({
                next: (response: Array<GroupModel>) => {
                    this.groups = new Array<GroupModel>();
                    this.groups = response;

                    this.subscriptionPlans = new Array<SelectListItemModel>();
                    let subscriptionPlan = new SelectListItemModel();
                    subscriptionPlan.keyInt = -1;
                    subscriptionPlan.value = `My Subscription (${this.appUtils.getUserName()})`;
                    this.subscriptionPlans.push(subscriptionPlan);

                    this.groups.forEach((group => {
                        subscriptionPlan = new SelectListItemModel();
                        subscriptionPlan.keyInt = group.id;
                        subscriptionPlan.value = `${group.name} (${group.ownerName})`;
                        this.subscriptionPlans.push(subscriptionPlan);
                    }));

                    if (this.selectedGroupId != undefined && this.selectedGroupId > 0) {
                        this.model.groupId = this.selectedGroupId;
                    }
                    else {
                        this.selectedGroupId = -1;
                        this.model.groupId = -1;
                    }

                    this.isLoading = false;
                    this.getFolders();
                    this.getUserMediaApps();
                    this.openAddDisplayListModal();
                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    onGroupChange(): void {
        this.getFolders();
    }

    getFolders(): void {
        // if (this.selectedGroupId) {
        //     this.model.groupId = this.selectedGroupId;
        // }
        this.folderService.getList(this.model.groupId, null)
            .subscribe({
                next: (response: Array<SelectListItemModel>) => {
                    this.folders = response;
                    this.isLoading = false;
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    openAddDisplayListModal() {

        this.transitionTypes = this.appUtils.getTransitionTypeSelectListItems();
        this.model.transitionType = this.transitionTypes.filter(x => x.keyString.toString() === 'Fade')[0].value;

        this.isLoading = true;
        this.loadDisplays();
        this.getUserMediaApps();
        this.loadUserMediaAppSummaryListItems();
        this.getFolders();
    }

    deleteMedia(event) {
        const tempArr = [];
        this.selectedUserMediaApps.forEach((userMediaApp) => {
            tempArr.push(userMediaApp);
        });
        const selectedUserMediaAppIndex = this.selectedUserMediaApps.findIndex(x => x.id === event);
        tempArr.splice(selectedUserMediaAppIndex, 1);
        this.selectedUserMediaApps = tempArr;

        this.updateSelectedUserMediaApps();

    }


    getUserMediaApps(): void {
        this.isLoading = true;
        this.isUserMediaAppsLoaded = false;
        this.userMediaAppService.getList(this.model.groupId)
            .subscribe({
                next: (response: any) => {
                    this.isUserMediaAppsLoaded = true;
                    this.hideLoader()
                    this.userMediaApps = new Array<UserMediaAppSummaryModel>();
                    this.userMediaApps = response;
                    this.userMediaApps.forEach((x) => {
                        x.iconUrl = x.iconUrl ?? this.appUtils.getBorderlessMediaAppIconUrl(x.mediaAppType);
                    })
                    this.refreshUserMediaAppSelection();
                    this.isLoading = false;
                },
                error: (error: any) => {
                    this.isUserMediaAppsLoaded = true;
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            })
    }

    generateIconUrl(code: string): string {
        return `./assets/images/player-types/${code}.png`;
    }

    onMediaSelect(): void {

    }

    submit() {
        if (this.model.id > 0) {
            this.edit();
        } else {
            this.add();
        }
    }

    edit() {
        this.isLoading = true;
        this.model.displayIds = new Array<number>();
        this.selectedDisplays.forEach((item) => {
            this.model.displayIds.push(item.keyInt);
        });

        this.model.userMediaApps = new Array<DisplayListUserMediaAppModel>();
        this.selectedUserMediaAppSummaryListItems.forEach((item) => {
            const displayListUserMediaAppModel = new DisplayListUserMediaAppModel();
            displayListUserMediaAppModel.userMediaAppId = item.id;
            displayListUserMediaAppModel.lengthInSeconds = item.lengthInSeconds;
            displayListUserMediaAppModel.displayOrder = item.displayOrder;
            this.model.userMediaApps.push(displayListUserMediaAppModel);
        });
        if (this.model.groupId === null) {
            this.model.groupId = null;
        }
        if (this.selectedFolder && this.selectedFolder.length > 0) {
            this.model.folderId = this.selectedFolder[0].keyInt;
        } else {
            this.model.folderId = null;
        }

        this.displayListService.edit(this.model)
            .subscribe({
                next: () => {
                    this.baseService.success("Your playlist was updated successfully.");
                    let displayListEditedModel = new DisplayListListItemModel();
                    displayListEditedModel.id = this.model.id;
                    displayListEditedModel.name = this.model.name;
                    if (this.selectedUserMediaApps.length > 0) {
                        displayListEditedModel.defaultUserMediaAppUrl = this.selectedUserMediaApps[0].iconUrl;
                        displayListEditedModel.userMediaAppCount = this.selectedUserMediaApps.length;
                    }

                    this.closeDrawer.emit({ displayListId: this.model.id, displayListAdded: true });
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    add() {
        this.isLoading = true;

        this.model.displayIds = new Array<number>();
        this.selectedDisplays.forEach((x) => {
            this.model.displayIds.push(x.keyInt);
        });

        this.model.userMediaApps = new Array<DisplayListUserMediaAppModel>();
        this.selectedUserMediaAppSummaryListItems.forEach((item) => {
            const displayListUserMediaAppModel = new DisplayListUserMediaAppModel();
            displayListUserMediaAppModel.userMediaAppId = item.id;
            displayListUserMediaAppModel.lengthInSeconds = item.lengthInSeconds;
            displayListUserMediaAppModel.displayOrder = item.displayOrder;
            this.model.userMediaApps.push(displayListUserMediaAppModel);
        });
        if (this.model.groupId === -1) {
            this.model.groupId = null;
        }

        // if (this.selectedFolder && this.selectedFolder.length > 0) {
        //     this.model.folderId = this.selectedFolder[0].keyInt;
        // } else {
        //     this.model.folderId = null;
        // }

        this.displayListService.add(this.model)
            .subscribe({
                next: (response: number) => {
                    this.isLoading = true;
                    this.baseService.success("Playlist added successfully.");
                    this.closeDrawer.emit({ displayListId: response, displayListAdded: true });
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            })
    }

    onUserMediaAppSelect(event: any) {
        this.cdr.detectChanges();
        setTimeout(() => {
            this.updateSelectedUserMediaApps();
        });
    }

    updateSelectedUserMediaApps() {
        const tempArr = new Array<UserMediaAppSummaryModel>();

        setTimeout(() => {
            this.disableAddAndPreviewButton = this.selectedUserMediaApps.length === 0;
        }, 100);

        if (this.selectedUserMediaApps.length === 0) {
            this.selectedUserMediaAppSummaryListItems = tempArr;
            return;
        }

        this.selectedUserMediaAppSummaryListItems.forEach(item => {
            const userMediaAppSummaryModel = new UserMediaAppSummaryModel();
            userMediaAppSummaryModel.id = item.id;
            userMediaAppSummaryModel.mediaAppType = item.mediaAppType;
            userMediaAppSummaryModel.mediaAppTitle = item.mediaAppTitle;
            userMediaAppSummaryModel.title = item.title;
            userMediaAppSummaryModel.lengthInSeconds = item.lengthInSeconds;
            userMediaAppSummaryModel.displayOrder = item.displayOrder;
            userMediaAppSummaryModel.status = item.status;
            userMediaAppSummaryModel.isScheduled = item.isScheduled;
            userMediaAppSummaryModel.startDate = item.startDate;
            userMediaAppSummaryModel.endDate = item.endDate;
            userMediaAppSummaryModel.startTime = item.startTime;
            userMediaAppSummaryModel.endTime = item.endTime;
            userMediaAppSummaryModel.videoEncodingStatus = item.videoEncodingStatus;
            userMediaAppSummaryModel.mediaAppType = item.mediaAppType;
            userMediaAppSummaryModel.url = item.url;
            var selectUserMedia = this.userMediaApps.find(x => x.id == item.id);
            if (selectUserMedia != undefined) {
                userMediaAppSummaryModel.iconUrl = selectUserMedia.iconUrl;
            }


            tempArr.push(userMediaAppSummaryModel);
        });

        // process added items
        this.selectedUserMediaApps.forEach((item: UserMediaAppSummaryModel) => {
            const selectedUserMediaAppSummary = this.userMediaAppSummaryListItems.find(x => x.id === item.id);
            if (tempArr.findIndex(x => x.id === selectedUserMediaAppSummary.id) === -1) {
                const userMediaAppSummary = new UserMediaAppSummaryModel();
                userMediaAppSummary.id = selectedUserMediaAppSummary.id;
                userMediaAppSummary.title = selectedUserMediaAppSummary.title;
                userMediaAppSummary.lengthInSeconds = selectedUserMediaAppSummary.lengthInSeconds > 0 ?
                    selectedUserMediaAppSummary.lengthInSeconds : 10;
                userMediaAppSummary.mediaAppType = selectedUserMediaAppSummary.mediaAppType;
                userMediaAppSummary.mediaAppTitle = selectedUserMediaAppSummary.mediaAppTitle;
                userMediaAppSummary.status = selectedUserMediaAppSummary.status;
                userMediaAppSummary.isScheduled = selectedUserMediaAppSummary.isScheduled;
                userMediaAppSummary.startDate = selectedUserMediaAppSummary.startDate;
                userMediaAppSummary.endDate = selectedUserMediaAppSummary.endDate;
                userMediaAppSummary.startTime = selectedUserMediaAppSummary.startTime;
                userMediaAppSummary.endTime = selectedUserMediaAppSummary.endTime;
                userMediaAppSummary.videoEncodingStatus = selectedUserMediaAppSummary.videoEncodingStatus;
                userMediaAppSummary.mediaAppType = selectedUserMediaAppSummary.mediaAppType;
                userMediaAppSummary.url = selectedUserMediaAppSummary.url;
                userMediaAppSummary.iconUrl = this.appUtils.getBorderlessMediaAppIconUrl(item.mediaAppType);
                var selectUserMedia = this.userMediaApps.find(x => x.id == item.id);
                if (selectUserMedia != undefined) {
                    userMediaAppSummary.iconUrl = selectUserMedia.iconUrl;
                }
                tempArr.push(userMediaAppSummary);
            }
        });

        tempArr.forEach((item: UserMediaAppSummaryModel) => {
            if (this.selectedUserMediaApps.findIndex(x => x.id === item.id) === -1) {
                const removedItemIndex = tempArr.indexOf(item);
                tempArr.splice(removedItemIndex, 1);
            }
        });

        tempArr.sort((a, b) => a.displayOrder - b.displayOrder);
        this.selectedUserMediaAppSummaryListItems = tempArr;

        setTimeout(() => {
            this.updateDisplayOrder();
            this.checkMediaStatus();
        });
    }

    updateDisplayOrder() {
        this.selectedUserMediaAppSummaryListItems.forEach((x, i) => {
            x.displayOrder = i + 1;
        });
    }

    checkMediaStatus() {
        for (let i = 0; i < this.selectedUserMediaAppSummaryListItems.length; i++) {
            if (!this.selectedUserMediaAppSummaryListItems[i].startTime || !this.selectedUserMediaAppSummaryListItems[i].endTime) {
                this.selectedUserMediaAppSummaryListItems[i].isScheduled = false;
                continue;
            }
            this.selectedUserMediaAppSummaryListItems[i].isScheduled = true;
        }
    }

    onUserMediaAppSelectAll(event: any) {
        this.cdr.detectChanges();
        setTimeout(() => {
            this.updateSelectedUserMediaApps();
        });
    }

    onUserMediaAppDeSelect(event: any) {
        this.cdr.detectChanges();
        setTimeout(() => {
            this.updateSelectedUserMediaApps();
        });
    }

    onUserMediaAppDeSelectAll(event: any) {
        this.cdr.detectChanges();
        setTimeout(() => {
            this.updateSelectedUserMediaApps();
        });
    }

    removeUserMediaApp(item: UserMediaAppSummaryModel) {
        const tempArr = [];
        this.selectedUserMediaApps.forEach((userMediaApp) => {
            tempArr.push(userMediaApp);
        });
        const selectedUserMediaAppIndex = this.selectedUserMediaApps.findIndex(x => x.id === item.id);
        tempArr.splice(selectedUserMediaAppIndex, 1);
        this.selectedUserMediaApps = tempArr;

        this.updateSelectedUserMediaApps();
    }


    removeSelectedItem(event: any) {
        const index = this.selectedUserMediaAppSummaryListItems.findIndex(app => app.id === event.id);
        if (index !== -1) {
            this.selectedUserMediaAppSummaryListItems.splice(index, 1);
        }

        const selectedIndex = this.selectedUserMediaApps.findIndex(x => x.id == event.id);
        if (index !== -1) {
            this.selectedUserMediaApps.splice(selectedIndex, 1);
        }
    }

    loadUserMediaAppSummaryListItems() {
        this.isUserMediaAppsSummaryLoaded = false;
        this.userMediaAppService.getList(this.model.groupId)
            .subscribe({
                next: (data: Array<any>) => {
                    this.isUserMediaAppsSummaryLoaded = true;
                    this.hideLoader();
                    if (!data || data.length === 0) {
                        return;
                    }

                    this.userMediaAppSummaryListItems = data;
                    this.userMediaAppSummaryListItems.forEach(x => x.url = this.generateMediaUrl(x.mediaAppTitle))
                    this.refreshUserMediaAppSelection();
                },
                error: (error) => {
                    this.isUserMediaAppsSummaryLoaded = true;
                    this.hideLoader();
                    this.baseService.processErrorResponse(error)
                }
            });

    }

    generateMediaUrl(code: string): string {
        return `./assets/images/media-types/icons/${code}.svg`;
    }


    hideLoader() {
        if (this.isDisplaysLoaded
            && this.isUserMediaAppsLoaded
            && this.isUserMediaAppsSummaryLoaded) {
            this.isLoading = false;
        }
    }

    refreshUserMediaAppSelection() {
        if (!this.isUserMediaAppsLoaded || !this.isUserMediaAppsSummaryLoaded) {
            return;
        }

        const tempSelectedUserMediaApps = new Array<UserMediaAppSummaryModel>();
        const tempSelectedUserMediaAppSummaryListItems = new Array<UserMediaAppSummaryModel>();

        this.model.userMediaApps.forEach((item: DisplayListUserMediaAppModel) => {
            const userMediaApp = this.userMediaApps.find(x => x.id === item.id);
            if (userMediaApp) {
                const tempSelectedUserMediaApp = new UserMediaAppSummaryModel();
                tempSelectedUserMediaApp.id = userMediaApp.id;
                tempSelectedUserMediaApp.title = userMediaApp.title;

                tempSelectedUserMediaApps.push(tempSelectedUserMediaApp);

                const userMediaAppSummary = this.userMediaAppSummaryListItems.find(x => x.id === item.id);
                if (userMediaAppSummary) {

                    const tempSelectedUserMediaAppSummary = new UserMediaAppSummaryModel();
                    tempSelectedUserMediaAppSummary.id = item.id;
                    tempSelectedUserMediaAppSummary.mediaAppType = userMediaAppSummary.mediaAppType;
                    tempSelectedUserMediaAppSummary.mediaAppTitle = userMediaAppSummary.mediaAppTitle;
                    tempSelectedUserMediaAppSummary.title = userMediaAppSummary.title;
                    tempSelectedUserMediaAppSummary.lengthInSeconds = item.lengthInSeconds;
                    tempSelectedUserMediaAppSummary.displayOrder = item.displayOrder;
                    tempSelectedUserMediaAppSummary.videoEncodingStatus = userMediaAppSummary.videoEncodingStatus;
                    tempSelectedUserMediaAppSummary.status = userMediaAppSummary.status;
                    tempSelectedUserMediaAppSummary.mediaAppType = userMediaAppSummary.mediaAppType;
                    tempSelectedUserMediaAppSummary.url = userMediaAppSummary.url;
                    tempSelectedUserMediaAppSummary.iconUrl = this.appUtils.getBorderlessMediaAppIconUrl(userMediaAppSummary.mediaAppType);

                    tempSelectedUserMediaAppSummaryListItems.push(tempSelectedUserMediaAppSummary);
                }
            }
        });

        this.selectedUserMediaApps = tempSelectedUserMediaApps;
        this.selectedUserMediaAppSummaryListItems = tempSelectedUserMediaAppSummaryListItems;
        this.checkMediaStatus();
        this.updateSelectedUserMediaApps();
    }


    cancel(): void {
        this.closeDrawer.emit({ displayListId: 0, displayListAdded: false });
    }

    loadDisplays() {
        if (this.selectedGroupId == -1) {
            this.selectedGroupId = null;
        }
        this.isDisplaysLoaded = false;
        const requestSubscription =
            this.displayService.getSelectListItems(this.model.groupId)
                .subscribe({
                    next: (response: Array<SelectListItemModel>) => {
                        this.displays = response;
                        this.isDisplaysLoaded = true;
                        this.hideLoader();
                    },
                    error: (error) => {
                        this.isDisplaysLoaded = true;
                        this.hideLoader();
                        this.baseService.processErrorResponse(error);
                    }

                });
        this.subscriptions.push(requestSubscription);
    }

    onGroupChanged() {
        this.loadDisplays();
        this.getUserMediaApps();
        this.getFolders();
        this.loadUserMediaAppSummaryListItems();
    }

    loadDisplayList(id: number) {
        if (!id) {
            id = 547;
        }
        const getForEditRequestSubscription =
            this.displayListService.getForEdit(id)
                .subscribe({
                    next: (data: any) => {
                        this.isLoading = false;

                        Object.assign(this.model, data);

                        if (AppUtils.isNullOrUndefined(this.model.groupId)) {
                            this.model.groupId = null;
                        }

                        // get group name
                        const group = this.groups.find(x => x.id === Number(this.model.groupId));
                        if (group) {
                            this.model.groupName = group.name;
                        }

                        const tempSelectedDsiplays = new Array<SelectListItemModel>();
                        this.model.displays.forEach((items) => {
                            tempSelectedDsiplays.push(items);
                        });
                        this.selectedDisplays = tempSelectedDsiplays;

                        this.refreshUserMediaAppSelection();
                        this.updateSelectedUserMediaApps();
                        this.updateSelectedFolder();

                        if (!this.model.transitionType) {
                            this.model.transitionType = this.transitionTypes.filter(x => x.keyString.toString() === 'Fade')[0].value;
                        }

                        setTimeout(() => {
                            this.loadDisplays();
                            this.getFolders();
                            this.getUserMediaApps();
                            this.loadUserMediaAppSummaryListItems();
                        }, 100);

                    },
                    error: (error: any) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);
                    }
                });

        this.subscriptions.push(getForEditRequestSubscription);
    }

    openEditDisplayListModal(id: number) {
        const selectedGroupId = this.appUtils.getGroupId();
        if (selectedGroupId) {
            if (selectedGroupId !== -1) {
                this.model.groupId = selectedGroupId;
                this.showGroupSelection = true;
            } else {
                this.showGroupSelection = false;
                this.model.groupId = null;
            }
        } else {
            this.showGroupSelection = true;
        }

        this.transitionTypes = this.appUtils.getTransitionTypeSelectListItems();
        this.pendingDisplayListIds = this.appUtils.getPendingDisplayListIds();
        if (this.pendingDisplayListIds !== null) {
            this.pendingDisplayListIdIndex += 1;
            this.loadDisplayList(id);
        } else if (this.appUtils.getFirstModalName() === Constants.modalList.displayListEditModel &&
            this.appUtils.getTwoModalProcessState() === Constants.twoModalOpenCloseState.firstModalOpenAgain) {
            const model = this.appUtils.getTempModelOfModal();
            Object.assign(this.model, model);

            const tempSelectedDsiplays = new Array<SelectListItemModel>();
            this.model.displays.forEach((items) => {
                tempSelectedDsiplays.push(items);
            });
            this.selectedDisplays = tempSelectedDsiplays;

            this.refreshUserMediaAppSelection();
            this.updateSelectedUserMediaApps();

            setTimeout(() => {
                this.loadDisplays();
                this.getFolders();
                this.getUserMediaApps();
                this.loadUserMediaAppSummaryListItems();
            }, 100);
        } else {
            this.loadDisplayList(id);
        }

    }

    updateSelectedFolder() {
        if (this.folders.length === 0 || !this.model.folderId) {
            return;
        }

        const selectedFolder = this.folders.filter(x => Number(x.keyInt) === Number(this.model.folderId));

        if (selectedFolder) {
            this.selectedFolder = selectedFolder;
        }
    }


    close() {

    }

    openMediaStoreDarwer() {
        this.showMediaDrawer = true;
        this.drawer.open();

    }

    closeMediaStoreDrawer(event: any) {
        if (event.mediaAdded) {
            console.log("media id", event.mediaId)
        }
        this.drawer.close();
        this.isDrawerVisible = false;
        this.showMediaDrawer = false;
        this.loadUserMediaAppSummaryListItems();
        this.getUserMediaApps();
    }



    onDisplaySelectAll(event: any) {
        this.cdr.detectChanges();
    }

    onDisplaySelect(event: any) { }

    onDisplayDeSelect(event: any) { }

    onDisplayDeSelectAll(event: any) { }

    openDisplayDrawer() {
        this.showDisplayDrawer = true;
        this.drawer.open();
    }

    removeDisplay(display: SelectListItemModel): void {
        this.selectedDisplays = this.selectedDisplays.filter(d => d !== display);
        this.model.displayIds = this.model.displayIds.filter(id => id !== display.keyInt);
    }

    //TODO: update the method
    closeDisplayDrawer(event: any) {
        if (event.updateValue) {
            let addedDisplay = new SelectListItemModel();
            addedDisplay.keyInt = event.selectedDisplay.keyInt;
            addedDisplay.value = event.selectedDisplay.value;
            addedDisplay.isSelected = true;

            this.selectedDisplays.push(addedDisplay);
            this.displays.push(addedDisplay);
        }

        this.drawer.close();
        this.showDisplayDrawer = false;
    }

}