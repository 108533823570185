export class UpdateSubscriptionModel{
    subscriptionId: string;
    planId: string;
    planQuantity: number;
    rejectedDisplayIds: number[];
    public isUpdatedByGroupUser: boolean;

    constructor() { 
        this.rejectedDisplayIds = new Array<number>();
        this.isUpdatedByGroupUser = false;
    }    
}