export class UserMediaAppPdfUpsertModel {
    public id: string;
    public userMediaAppId: number;
    public fileName: string;
    public originalFileName: string;
    public fileUrl: string;
    public lengthInSeconds: string;
    public pageChangeDuration: number;
    public isEnabled: boolean;
    public displayOrder: number;
    public size: string;

    public file: File;
    public isUploadingPdf: boolean;
    public fileUploadPercent: number;

    constructor() {
        this.isEnabled = true;
        this.isUploadingPdf = true;
        this.fileUploadPercent = 0;
        this.originalFileName = '';
        this.displayOrder = 0;
    }
}
