import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { AppUtils, Constants } from "src/helpers";
import { UserMediaAppVimeoUpsertModel, VimeoVideoModel } from "src/app/media-app/models";
import { BaseService } from "src/app/shared/services";

@Component({
    selector: 'app-vimeo-video',
    templateUrl: './user-media-app-vimeo-video.component.html',
    styleUrls: ['./user-media-app-vimeo-video.component.scss']
})
export class UserMediaAppViemoVideoComponent {
    @Input() accessToken: string;
    @Output() closeDrawer: EventEmitter<{ selectedVideo: VimeoVideoModel, updateValue: boolean }> = new EventEmitter<{ selectedVideo: VimeoVideoModel, updateValue: boolean }>();
    @Output() close: EventEmitter<any> = new EventEmitter();
    vimeo = new UserMediaAppVimeoUpsertModel();
    vimeoVideos: any;
    nextPageToken: string;
    showYouTubeView: boolean;
    isLoading: boolean = false;
    subscriptions = new Array<Subscription>();
    authorizedBy: string;
    vimeoVideosResponse: any;
    isModelLoaded: boolean;

    constructor(private httpClient: HttpClient,
        private baseService: BaseService,
        private appUtils: AppUtils) {
        this.getUserVideos();
    }

    get constants() { return Constants }

    ngOnchanges() {

    }

    clearSearch() {
        this.vimeo.searchText = '';
        this.getUserVideos();
    }

    getUserVideos(): void {
        this.isLoading = true;
        let headers = new HttpHeaders();
        this.accessToken = this.appUtils.getTempAuthToken();
        headers = headers.set('Authorization', `Bearer ${this.accessToken}`);

        this.httpClient.get('https://api.vimeo.com/me/videos', { headers: headers })
            .subscribe({
                next: (response: any) => {
                    this.vimeoVideosResponse = response.data;
                    if (this.vimeoVideos && this.vimeoVideos.length > 0) {
                        this.vimeoVideos = this.vimeoVideosResponse.slice(this.vimeoVideos.length - 1, this.vimeoVideos.length + 2);
                    } else {
                        this.vimeoVideos = this.vimeoVideosResponse.slice(0, 3);
                    }
                    this.isModelLoaded = true;
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isModelLoaded = true;
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }

            })
    }

    searchVimeoVideos(isLoadMore: boolean = false) {
        this.isLoading = true;

        let headers = new HttpHeaders();
        this.accessToken = this.appUtils.getTempAuthToken();
        headers = headers.set('Authorization', `Bearer ${this.accessToken}`);

        this.httpClient.get(`https://api.vimeo.com/me/videos?query=${this.vimeo.searchText}`, { headers: headers })
            .subscribe({
                next: (response: any) => {
                    this.vimeoVideosResponse = response.data;
                    if (this.vimeoVideos && this.vimeoVideos.length > 0) {
                        this.vimeoVideos = this.vimeoVideosResponse.slice(this.vimeoVideos.length - 1, this.vimeoVideos.length + 2);
                    } else {
                        this.vimeoVideos = this.vimeoVideosResponse.slice(0, 3);
                    }
                    this.isModelLoaded = true;
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isModelLoaded = true;
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }

            })
    }

    selectVideo(item: VimeoVideoModel): void {
        this.closeDrawer.emit({ selectedVideo: item, updateValue: true });
    }

    cancel(): void {
        this.close.emit();
        this.closeDrawer.emit({ selectedVideo: new VimeoVideoModel, updateValue: false })
    }
}