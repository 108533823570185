import { PageContentCardDetailModel } from './page.content.card.detail.model';

export class PageContentDetailModel {
    public content: string;
    public title: string;
    public description: string;
    public type: string;
    public videoDescription: string;

    public cards: Array<PageContentCardDetailModel>;

    constructor() {
        this.cards = new Array<PageContentCardDetailModel>();
    }
}
