export class UserMediaAppGoogleSlideUpsertModel {
    public id: string;
    public userMediaAppId: number;
    public url: string;
    public lengthInSeconds: string;
    public isEnabled: boolean;
    public displayOrder: number;
    public autoAdvanceSlide: number;

    public autherizedBy: string;
    public slideName: string;
    public slideEmbeddedUrl: string;
    public slideEditUrl: string;
    public hideSlideControls: boolean;
    public loopSlides: boolean;

    constructor() {
        this.isEnabled = true;
        this.displayOrder = 0;
        this.hideSlideControls = true;
        this.loopSlides = true;
    }
}