export class UpdateSubscriptionModel {
    public hostedPageId: string;
    public subscriptionId: string;
    public addOnId: string;
    public rejectedDisplayIds: Array<number>;
    public isUpdatedByGroupUser: boolean;
    public planQuantity: number;
    public planId: string;

    constructor() {
        this.rejectedDisplayIds = new Array<number>();
        this.isUpdatedByGroupUser = false;
    }
}