import { Injectable } from "@angular/core";
import { Constants, AppUtils } from "src/helpers";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Router } from "@angular/router";

@Injectable()
export class BaseService {

    errorMessage: string;
    get constants() { return Constants }
    get appUtils() { return AppUtils }

    constructor(private notification: NzNotificationService, private router: Router) {

    }

    processErrorResponse(error: any): void {
        const errorTitle = 'Error!'; // Adjust as needed
        // Use NzNotificationService with clear error type

        if (error.status === 0) {
            this.errorMessage = "Unable to connect with API Gateway."
        } else if (error.status === 404) {
            this.errorMessage = "Something went wrong. Please try refreshing the page.";
        } else if (error.status === 500) {
            this.errorMessage = "Internal server error.";
        } else if (error.status === 401) {
            localStorage.removeItem(Constants.varUserInfo);
            this.removeAuthToken();
            if (this.router.url.indexOf('account/logout') === -1) {
                console.log('redirecting to logout');
                this.router.navigate(['/account/logout']);
            }
        } else {
            this.errorMessage = error.error;
        }

        this.notification.error(errorTitle, this.errorMessage, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    info(message: string): void {
        const infoTitle = 'Info!'; // Adjust as needed
        this.notification.info(infoTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    successNotification(message: string): void {
        const successTitle = 'Success!';
        this.notification.success(successTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    success(message: string) {
        const infoTitle = 'Success!'; // Adjust as needed
        return this.notification.success(infoTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    error(message: string): void {
        const errorTitle = 'Error!'; // Adjust as needed
        this.notification.error(errorTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    warning(message: string): void {
        const warningTitle = 'Warning!'; // Adjust as needed
        this.notification.warning(warningTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    public removeAuthToken()
    {
        localStorage.removeItem(Constants.varAuthToken);
        sessionStorage.removeItem(Constants.varAuthToken);
    }
} 