import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AppUtils } from "src/helpers";
import { ListenerService } from "src/services";

@Component({
    selector: 'app-display-preview',
    templateUrl: './display.preview.component.html',
    styleUrls: ['./display.preview.component.scss']
})
export class DisplayPreviewComponent implements OnInit, OnChanges {
    @Input() imageUrl: string;
    @Input() noMediaInPlaylist: boolean;
    @Input() hidePreviewMessage: boolean;
    @Input() lastUpdated: string;
    @Input() uploadedMediaCount: number;
    @Input() uploadMedia: boolean;
    @Input() uploadMediaFromBroadcast: boolean;
    @Input() uploadMediaFromPlaylist: boolean;
    @Input() mediaExists: string;
    @Input() screenOrientation: number;
    showDefault = true;
    mediaUploaded: boolean;
    noPlaylistOrBroadcast: boolean;
    noMediaInAddedPlaylist: boolean;
    lastUpdatedAt: string;
    url: string;
    subscriptions = new Array<Subscription>();

    constructor(private appUtils: AppUtils,
        private listenerService: ListenerService
    ) {
        const appThemeChange = this.listenerService
            .appThemeChange.subscribe(() => {
                this.handlePreview();
            });
        this.subscriptions.push(appThemeChange);
    }

    ngOnInit() {
        this.handlePreview()
    }

    ngOnChanges() {
        this.handlePreview();
    }

    handlePreview(): void {
        if (this.imageUrl != null || this.imageUrl != undefined) {
            this.url = this.imageUrl;
            this.showDefault = false;
        }
        else {
            this.showDefault = true;
            if (this.appUtils.isDarkModeActive()) {
                this.url = "./assets/images/darkmode-preview.svg";
            }
            else {
                this.url = "./assets/images/default_preview.png";
            }
        }

        if (this.noMediaInPlaylist) {
            this.noMediaInAddedPlaylist = true;
            this.noPlaylistOrBroadcast = false;
        }
        else {
            this.noPlaylistOrBroadcast = true;
            this.noMediaInAddedPlaylist = false;
        }

        if (this.lastUpdated) {
            this.lastUpdatedAt = this.lastUpdated;
        }

        if (this.uploadedMediaCount > 0) {
            this.mediaUploaded = true;
        }
        else {
            this.mediaUploaded = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }
}
