export class SubscriptionSummaryModel{
    id: string;
    planId: string;
    planName: string;
    planUnitPrice: number;
    planQuantity: number;
    planFreeQuantity: number;
    billingPeriodUnit: string;
    customerEmail: string;
    nextBillingAt: string;
    trialStartAt: string;
    trialEndAt: string;
    trialRemaining: string;
    status: string;
    totalDueAmount: number;
    cardNumber: string;
    cardNumberLast4: string;
    cardType: string;
    isProPlan: boolean;
    createdAt: string;
    activatedAt: string;
    displaysPurchased: number;
}