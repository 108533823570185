import { AppUtils, Constants } from "../helpers";
export class FilterModel {
    public filterKey: string;
    public page: number;
    public pageSize: number;
    public sortBy: string;
    public sortDir: string;
    public mineOnly: boolean;
    public groupId: number;
    public reloading: boolean;
    public recordIndex: number;
    public recordsToSkip: number;
    public offset: number;
    public limit: number;

    
    constructor() {
        this.mineOnly = null;
        this.groupId = null;
        this.reloading = false;
        this.filterKey = '';
        this.sortDir = 'asc';
        this.page = 1;
        this.pageSize = Constants.defaultPageSize;
        this.recordIndex = 0;
        this.recordsToSkip = 0;
    }

    toQueryString() {
        let queryString = `filterKey=${this.filterKey}&sortBy=${this.sortBy}&sortDir=${this.sortDir}&page=${this.page}&pageSize=${this.pageSize}&recordIndex=${this.recordIndex}&reloading=${this.reloading}`;

        if (!AppUtils.isNullOrUndefined(this.mineOnly)) {
            queryString += '&mineOnly=' + this.mineOnly;
        }

        if (!AppUtils.isNullOrUndefined(this.groupId)) {
            queryString += '&groupId=' + this.groupId;
        }

        if(this.offset != null)
        {
            queryString += '&offSet=' + this.offset;
        }

        if(this.limit != null){
            queryString += '&limit=' + this.limit;
        }

        return queryString;
    }

}
