import { Directive, ElementRef, Output, EventEmitter, OnInit } from "@angular/core";
@Directive({
    selector: "[nativeAutofill]",
})
export class NativeAutofillDetector implements OnInit {
    private elRef: HTMLInputElement;
    @Output()
    public nativeAutofill: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(private elementRef: ElementRef) { }
    ngOnInit(): void {
        this.elRef = this.elementRef.nativeElement;
        if (this.elRef) {
            this.detectAutofill().then((isAutofilled) => {
                this.nativeAutofill.emit(isAutofilled);
            });
        }
    }
    private detectAutofill(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            setTimeout(() => {
                const isAutofilled =
                    window.getComputedStyle(this.elRef, null).getPropertyValue("appearance") === "menulist-button";
                resolve(isAutofilled);
            }, 1200);
        });
    }
}