export class DisplayModel {
    id: number;
    name: string;
    description: string;
    displayCode: string;
    displayListId?: number;
    playerType: string;
    playerTypeId: number;
    broadcastId: number;
    groupId: number;
    folderId: number;
    rotationDegree: number;
    priceHidden: boolean;
    status: number;
}