export class ExternalLoginModel {
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    provider: string;
    providerKey: string;
    authToken: string;
    userId: string;
    canvaUserId: string;
    resellerId: string;
    signUpUrlParams: string;
    plan: string;
    addon: string;
    coupon: string;
    issuer: string;
    phone: string;
}
