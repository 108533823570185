import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";


@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    message: string;
    submitButtonText: string;

    constructor(private router: Router,
        private modal: NzModalRef,
        @Inject(NZ_MODAL_DATA) private modalData: any) {
        this.message = modalData.message;
        this.submitButtonText = modalData.submitButtonText
    }

    cancel() {
        this.modal.destroy(false);
    }

    proceed() {
        this.modal.destroy(true);
    }
}

