<section class="mt-5 mb-3 lsit-epmty-view">
    <div nz-row>
        <div nz-col nzSpan="24" class="justify-content-center">
            <nz-card class="help-card mr-2" [nzCover]="videoContent">
                <div class="mt-5">
                    <p class="heading-fs-14 help-text-color mb-0">Watch Video</p>
                    <p class="text-muted fs-12 mt-1">{{model.videoDescription}}</p>
                </div>
            </nz-card>
            <ng-template #videoContent>
                <div class="text-center mt-3 image-container cursor-pointer" (click)="showModal()">
                    <img src="../../assets/images/help-video/screen.png" alt=""
                        *ngIf="model.type == constants.type.screen">
                    <img src="../../assets/images/help-video/media.png" alt=""
                        *ngIf="model.type == constants.type.media">
                    <img src="../../assets/images/help-video/playlist.png" alt=""
                        *ngIf="model.type == constants.type.playlist">
                    <img src="./assets/images/help-video/broadcast.png" alt=""
                        *ngIf="model.type == constants.type.broadcast">
                    <img src="./assets/images/help-video/group.png" alt="" *ngIf="model.type == constants.type.group">
                    <img src="./assets/images/help-video/folder.png" alt="" *ngIf="model.type == constants.type.folder">

                </div>
            </ng-template>

            <nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" (nzOnCancel)="closeModal()" [nzOkText]="null"
                [nzContent]="modalContent" [nzCancelText]="null">
                <ng-template class="fw-bold" #modalTitle>Get started</ng-template>

                <ng-template #modalContent>
                    <div [innerHtml]="helpVideo" class="modal-body"></div>
                </ng-template>
            </nz-modal>

            <nz-card class="help-card ml-2 disp-web" [nzCover]="imageTemplate">
                <p class="heading-fs-14 mb-0 help-text-color">Still looking for help?</p>
                <p class="text-muted fs-12 mt-1">Find out how to get most out of this product.</p>
                <div class="mt-4 mb-2 d-flex align-items-center">
                    <p class="help-text mb-0 cursor-pointer"><a href="https://help.displaynow.io" target="blank"
                            class="text-black">Help Center</a></p>
                    <a href="https://help.displaynow.io" target="blank" class="text-black d-flex">
                        <div nz-icon nzType="right-circle" nzTheme="outline" class="ml-3 mb-0 cursor-pointer"></div>
                    </a>
                </div>
            </nz-card>
            <ng-template #imageTemplate>
                <div class="text-center mt-3 image-container cursor-pointer">
                    <a href="https://help.displaynow.io" target="blank" class="text-black">
                        <img src="./assets/images/static-image.png" class="lightmode_img" alt="">
                        <img src="./assets/images/static-image-dark.png" class="darkmode_img" alt="">
                        </a>
                </div>
            </ng-template>
        </div>
    </div>
    <div nz-row class="mt-4 mb-3 justify-content-center">
        <div class="text-center text-container">
            <div class="title-container">
                <p class="heading-fs-20 mb-0 help-text-color">{{model.title}}</p>
                <p class="text-muted mt-2">{{model.description}}</p>
            </div>

            <div class="mt-5 mb-2">
                <button nz-button nzType="primary" class="add-screen-btn" [routerLink]="['/display/add']"
                    *ngIf="model.type == constants.type.screen"><span nz-icon nzType="plus" nzTheme="outline"></span>Add
                    Screen
                </button>

                <button nz-button nzType="primary" class="add-screen-btn" [routerLink]="['/media-app/store']"
                    *ngIf="model.type == constants.type.media"><span nz-icon nzType="plus" nzTheme="outline"></span>Add
                    Media
                </button>
                <button nz-button nzType="primary" class="add-broadcast-btn" [routerLink]="['/broadcast/add']"
                    *ngIf="model.type == constants.type.broadcast"><span nz-icon nzType="plus"
                        nzTheme="outline"></span>Add Broadcast
                </button>
                <button nz-button nzType="primary" class="add-screen-btn" [routerLink]="['/display-list/add']"
                    *ngIf="model.type == constants.type.playlist"><span nz-icon nzType="plus"
                        nzTheme="outline"></span>Add Playlist
                </button>
                <button nz-button nzType="primary" class="add-group-btn" [routerLink]="['/group/add']"
                    *ngIf="model.type == constants.type.group"><span nz-icon nzType="plus" nzTheme="outline"></span>Add
                    Group
                </button>
                <button nz-button nzType="primary" class="add-group-btn"
                    [routerLink]="['/folder/add']" *ngIf="model.type == constants.type.folder"><span nz-icon
                        nzType="folder-add" nzTheme="outline"></span> Add Folder
                </button>
            </div>
        </div>
    </div>
</section>