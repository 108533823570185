<section>
    <div class="d-flex justify-content-between mb-3">
        <div class="heading-fs-16">Preview</div>
        <div class="preview-chip" *ngIf="lastUpdatedAt"><span class="text-muted">Last updated at:</span>
            {{lastUpdatedAt}}</div>
    </div>
    <div nz-col nzSpan="24">
        <nz-divider></nz-divider>
    </div>

    <div nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="justify-content-center p-5">
            <div [ngClass]="{'preview-display-static-container': showDefault, 'preview-display-dynamic-container': !showDefault && (screenOrientation == null || screenOrientation == 0 || screenOrientation == 180), 'preview-display-dynamic-container-rotate': !showDefault && (screenOrientation == 90 || screenOrientation == 270), 'rotate-90 ' : screenOrientation == 90,
            'rotate-180' : screenOrientation == 180, 'rotate-270' : screenOrientation == 270}">
                <img [src]="url" class="lightmode_img" alt="preview-image">
                <!-- Dev Need to add dynamic url for dark  -->
                <img [src]="url" class="darkmode_img" alt="preview-image">
                <div [ngClass]="{'container-notch' : screenOrientation == null || screenOrientation == 0, 'container-notch-rotate' : screenOrientation == 90 || screenOrientation == 180 || screenOrientation == 270,}" *ngIf="!showDefault"></div>
            </div>
        </div>
  
        <div nz-col nzSpan="24" class="preview-content"
            *ngIf="!hidePreviewMessage && showDefault && noPlaylistOrBroadcast">
            <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="mr-2"></span> Select a playlist or a
            broadcast to see a preview
        </div>
        <div nz-col nzSpan="24" class="preview-content"
            *ngIf="!hidePreviewMessage && showDefault && noMediaInAddedPlaylist">
            <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="mr-2"></span> Add media to your playlist to
            see a preview
        </div>
        <div nz-col nzSpan="24" class="preview-content" *ngIf="showDefault && !mediaUploaded && uploadMedia">
            <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="mr-2"></span> Upload media to see a preview
        </div>
        <div nz-col nzSpan="24" class="preview-content" *ngIf="showDefault && !mediaExists && uploadMediaFromBroadcast">
            <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="mr-2"></span> Select media to see a preview
        </div>
        <div nz-col nzSpan="24" class="preview-content"
            *ngIf="showDefault && !mediaUploaded  && uploadMediaFromPlaylist">
            <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="mr-2"></span> Select media to see a preview
        </div>
    </div>
</section>