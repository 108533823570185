import { BroadcastListItemModel, DisplayListListItemModel, SelectListItemModel, UserMediaAppCanvaUpsertModel, UserMediaAppClockUpsertModel, UserMediaAppGoogleSlideUpsertModel, UserMediaAppImageUpsertModel, UserMediaAppPdfUpsertModel, UserMediaAppPowerPointUpsertModel, UserMediaAppVideoUpsertModel, UserMediaAppVimeoUpsertModel, UserMediaAppWeatherUpsertModel, UserMediaAppWebPageUpsertModel, UserMediaAppYouTubeUpsertModel } from "src/app/shared/models";

export class UserMediaAppUpsertModel{
    id: number;
    mediaAppId: number;
    type: number;
    //type: string;
    title: string;
    subtitle: string;
    description: string;
    groupId: any;
    groupName: string;
    groupOwnerId: string;
    lengthInSeconds: string;
    folderId: number;
    startDate: any;
    startTime: any;
    endDate: any;
    endTime: any;
    isDaily: boolean;
    isScheduled: boolean;
    hasError: boolean;
    errorMessage: string;
    status: number;
    isEnabled: boolean;
    originalIsEnabled: boolean;

    broadcastIds: Array<number>;

    playlists = new Array<DisplayListListItemModel>();
    broadcasts = new Array<BroadcastListItemModel>();

    displayLists: Array<SelectListItemModel>;

     public webPage: UserMediaAppWebPageUpsertModel;

    public clock: UserMediaAppClockUpsertModel;
    public youTube: UserMediaAppYouTubeUpsertModel;
    public weather: UserMediaAppWeatherUpsertModel;
    public canva: UserMediaAppCanvaUpsertModel;
    public image: UserMediaAppImageUpsertModel;
    public vimeo: UserMediaAppVimeoUpsertModel;
    public powerPoint: UserMediaAppPowerPointUpsertModel;
    public googleSlide: UserMediaAppGoogleSlideUpsertModel;
    public pdf: UserMediaAppPdfUpsertModel;
    public video: UserMediaAppVideoUpsertModel;

    constructor() {
        this.displayLists = new Array<SelectListItemModel>();
        this.broadcastIds = new Array<number>();
        this.lengthInSeconds = '10';
        this.isDaily = true;
        this.startDate = null;
        this.startTime = null;
        this.endDate = null;
        this.endTime = null;
        this.isScheduled = false;
    }
}
