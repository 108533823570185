<section>
    <div nz-row>
        <div nz-col nzSpan="12">
            <div class="heading-fs-18 mb-0">
                <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
                    (click)="close()"></span>
                New Media
            </div>
        </div>
        <div nz-col nzSpan="12" class="text-right disp-web">
            <button nz-button class="mr-3" type="button" (click)="close()">Cancel</button>
            <button nz-button nzType="primary" type="submit" [disabled]="true">Save</button>
        </div>
        <div nz-col nzSpan="24" class="mt-2">
            <nz-divider></nz-divider>
        </div>
    </div>
    <div nz-row class="mt-2 mb-3">
        <div nz-col nzSpan="24" class="heading-fs-16">
            All Media Apps
        </div>
    </div>
    <nz-spin [nzSpinning]="isLoading" class="mobOne-action-m-b">
        <div nz-row [nzGutter]="[16,16]" class="mb-3">
            <div nz-col nzXs="12" nzSm="12" nzMd="24" nzLg="24" nzXl="24" *ngFor="let mediaApp of mediaApps">
                <div class="media-list-container list-item">
                    <div nz-row>
                        <div nz-col nzLg="10" nzMd="10" nzXs="24" nzSm="24" class="content-center">
                            <div class="align-items-center">
                                <div class="mr-3"
                                    [ngClass]="{'list-img-wrapper': !mediaApp.urlError, 'list-default-wrapper': mediaApp.urlError}">
                                    <img *ngIf="!mediaApp.urlError" [src]="mediaApp.iconUrl" alt=""
                                        (error)="onError(mediaApp)" [ngClass]="{'hidden': mediaApp.urlError}">
                                </div>
                                <span class="ml-2 fs-16 fw-bold">{{mediaApp.title}}</span>
                            </div>
                        </div>
                        <div nz-col nzLg="8" nzMd="8" nzXs="24" nzSm="24" class="media-list-container-content pr-2">
                            {{mediaApp.description}}
                        </div>
                        <div nz-col nzLg="6" nzMd="6" nzXs="24" nzSm="24" class="align-items-center media-action-btn">
                            <button nz-button (click)="addUserMediaApp(mediaApp)">
                                <span nz-icon nzType="plus" nzTheme="outline"></span>
                                Add New</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nz-spin>
    <nz-drawer #drawer [nzClosable]="false" nzPlacement="right" [nzVisible]="isDrawerVisible" [nzMask]="false" (nzOnClose)="close()"
        class="custom-drawer">
        <ng-container *nzDrawerContent>
            <app-user-media-app-clock-drawer *ngIf="isClockDrawerVisisble" [selectedGroupId]="selectedGroupId"
                (closeDrawer)="closeUserMediaAppClockDrawer($event)"
                [mediaAppId]="mediaAppId"></app-user-media-app-clock-drawer>
            <app-user-media-web-page-drawer *ngIf="isWebpageDrawerVisible" [selectedGroupId]="selectedGroupId"
                (closeDrawer)="closeUserMediaWebpageDrawer($event)"
                [mediaAppId]="mediaAppId"></app-user-media-web-page-drawer>
            <app-user-media-app-vimeo-drawer *ngIf="isVimeoDrawerVisisble" [selectedGroupId]="selectedGroupId"
                (closeDrawer)="closeUserMediaAppVimeoDrawer($event)"
                [mediaAppId]="mediaAppId"></app-user-media-app-vimeo-drawer>
            <app-user-media-youtube-drawer *ngIf="isYouTubeDrawerVisible" [selectedGroupId]="selectedGroupId"
                (closeDrawer)="closeUserMediaAppYouTubeDrawer($event)"
                [mediaAppId]="mediaAppId"></app-user-media-youtube-drawer>
            <app-user-media-app-weather-drawer *ngIf="isWeatherDrawerVisible" [selectedGroupId]="selectedGroupId"
                (closeDrawer)="closeUserMediaAppWeatherDrawer($event)"
                [mediaAppId]="mediaAppId"></app-user-media-app-weather-drawer>
            <app-user-media-app-google-slide-drawer *ngIf="isGoogleSlideDrawerVisible" [selectedGroupId]="selectedGroupId"
                (closeDrawer)="closeUserMediaAppGoogleSliderDrawer($event)"
                [mediaAppId]="mediaAppId"></app-user-media-app-google-slide-drawer>
            <app-user-media-app-power-point-drawer *ngIf="isPowerPointDrawerVisible" [selectedGroupId]="selectedGroupId"
                (closeDrawer)="closeUserMediaAppPowerPointDrawer($event)"
                [mediaAppId]="mediaAppId"></app-user-media-app-power-point-drawer>
            <app-user-media-app-bulk-upload-drawer *ngIf="isBulkUploadDrawerVisible" [selectedGroupId]="selectedGroupId"
                (closeDrawer)="closeUserMediaAppBulkUploadDrawer($event)"
                [mediaAppId]="mediaAppId"></app-user-media-app-bulk-upload-drawer>
            <app-user-media-app-canva-drawer *ngIf="isCanvaDrawerVisible" [selectedGroupId]="selectedGroupId"
                (closeDrawer)="closeUserMediaAppCanvaDrawer($event)" [mediaAppId]="mediaAppId"></app-user-media-app-canva-drawer>
        </ng-container>
    </nz-drawer>


    <div nz-row class="disp-mob mobOne-action-bar">
        <div nz-col nzSpan="24" class="text-right">
            <nz-card>
                <div nz-row [nzGutter]="[16]">
                    <div nz-col nzSpan="12">
                        <button nz-button class="mr-3 btn-full" type="button" (click)="close()">Cancel</button>
                    </div>
                    <div nz-col nzSpan="12">
                        <button nz-button nzType="primary" class="btn-full" type="submit"
                            [disabled]="true">Save</button>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</section>