<section>
    <nz-spin [nzSpinning]="isLoading">
        <form #form="ngForm" nz-form (ngSubmit)="submit()">
            <div class="drawer-form-header-empty"></div>
            <div nz-row class="drawer-form-header">
                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                    <div class="heading-fs-18 mb-0">
                        <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
                            (click)="cancel()"></span>
                        New Media
                    </div>
                </div>
                <div nz-col nzSpan="12" class="text-right disp-web">
                    <button nz-button class="mr-3" (click)="cancel()">Cancel</button>
                    <button nz-button nzType="primary" type="submit" [disabled]="form.invalid">Save</button>
                </div>
                <div nz-col nzSpan="24">
                    <nz-divider></nz-divider>
                </div>
            </div>

            <div nz-row class="mb-3">
                <div nz-col nzSpan="24">
                    <div class="media-type-header">
                        <div nz-row>
                            <div nz-col nzLg="12" nzMd="12" nzXs="12" nzSm="12" class="content-center">
                                <div class="align-items-center">
                                    <img class="media-type-header-img-wrapper" [src]="mediaAppModel.iconUrl"
                                        alt="media-type">

                                    <span class="ml-2 fs-16 fw-bold">{{mediaAppModel.title}}</span>
                                </div>
                            </div>
                            <div nz-col nzLg="12" nzMd="12" nzXs="12" nzSm="12" class="media-type-header-content">
                                <app-content-text-view
                                    [contentType]="constants.pageContentType.mediaAppClockTop"></app-content-text-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div nz-row class="mobOne-action-m-b">
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0">
                                <nz-form-label [nzRequired]="true" class="fw-bold">Media
                                    name</nz-form-label>
                                <nz-input-group>
                                    <input nz-input required placeholder="Enter your media name" [minlength]="1"
                                        [maxlength]="100" [(ngModel)]="model.title" name="title" #title="ngModel"
                                        appAlphaNumericsLevelThree />
                                </nz-input-group>
                                <div *ngIf="form.submitted || title?.touched || title?.dirty"
                                    class="validation-error-message">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="title?.errors"></span>
                                    <span *ngIf="title?.errors?.['required']" class="ml-1"> Media
                                        name
                                        is required. </span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row class="mb-3 mt-3 w-100">
                    <div nz-col nzSpan="24">
                        <div nz-row class="mb-3">
                            <div nz-col nzSpan="24">
                                Time format
                            </div>
                        </div>
                        <div nz-row class="mb-3">
                            <div nz-col nzSpan="24">
                                <nz-radio-group [(ngModel)]="model.clock.is24HoursFormat" nzName="radiogroup"
                                    name="is24HoursFormat" #is24HoursFormat="ngModel"
                                    (ngModelChange)="timeFormatChanged($event)">
                                    <label nz-radio [nzValue]="true" for="24 hours">
                                        24 hours</label>
                                    <label nz-radio [nzValue]="false" for="12 hours">12
                                        hours</label>
                                </nz-radio-group>
                            </div>
                        </div>
                        <div nz-row class="mb-2">
                            <div nz-col nzSpan="24">
                                <nz-divider></nz-divider>
                            </div>
                        </div>

                        <div nz-row class="mb-3">
                            <div nz-col nzSpan="24">
                                Time zone
                            </div>
                        </div>

                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <nz-radio-group nzName="radiogroup" [(ngModel)]="model.clock.isAutoDetect"
                                    name="isAutoDetect" #isAutoDetect="ngModel"
                                    (ngModelChange)="autoDetectChanged($event)">
                                    <label nz-radio [nzValue]="true" for="auto">Auto
                                        detect (via screen IP Address)</label>
                                    <label nz-radio [nzValue]="false" for="manual">Select
                                        Manually</label>
                                </nz-radio-group>
                            </div>
                        </div>

                        <div nz-row class="mt-3">
                            <div nz-col nzSpan="24">
                                <nz-divider></nz-divider>
                            </div>
                        </div>

                        <div nz-row class="mt-3 w-100" *ngIf="!model.clock.isAutoDetect">
                            <div nz-col nzSpan="24">
                                <nz-form-item>
                                    <nz-form-control class="mb-0">
                                        <nz-select nzPlaceHolder="Select a Time Zone" name="timezone"
                                            [(ngModel)]="model.clock.timeZone" #timeZone="ngModel" required>
                                            <nz-option *ngFor="let zone of timeZoneModel" [nzValue]="zone.standardName"
                                                [nzLabel]="zone.displayName"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                </div>

                <div nz-row class="mb-3 w-100">
                    <div nz-col nzSpan="24">
                        <div nz-row class="mb-2">
                            <div nz-col nzSpan="24">
                                Upload your logo
                            </div>
                        </div>
                        <div nz-row *ngIf="!model.clock.fileUrl">
                            <div nz-col nzSpan="24">
                                <nz-upload [nzAccept]="'.png, .jpg'" [nzMultiple]="false"
                                    [nzBeforeUpload]="beforeUpload" class="avatar-uploader" nzName="avatar"
                                    nzListType="picture-card" nzName="avatar">
                                    <ng-container>
                                        <div class="upload-icon">
                                            <img class="lightmode_img" src="./assets/images/media-types/icons/v2/BulkUpload.svg"
                                                alt="media-type">
                                            <img class="darkmode_img" src="./assets/images/media-types/icons/v2/BulkUpload-darkmode.svg"
                                                alt="media-type">

                                            <div class="heading-fs-16 mt-2">Upload</div>
                                        </div>
                                    </ng-container>
                                </nz-upload>
                                <div nz-col nzSpan="24" class="mt-2">Must be 1:1
                                    .png or jpg (upto 2mb)</div>
                            </div>
                        </div>

                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <div *ngIf="model.clock.fileUrl" class="logo-container">
                                    <!-- <div class="dtl-btn">
                                        <span nz-icon nzType="delete" nzTheme="fill" class="dtl-btn-icon" ></span>
                                    </div> -->
                                    <img [src]="model.clock.fileUrl" alt="Logo" />
                                </div>
                            </div>
                        </div>
                        <div nz-row *ngIf="model.clock.fileUrl">
                            <div nz-col nzSpan="24">
                                <nz-form-item>
                                    <nz-form-control class="mb-0">
                                        <input type="file" name="fileName" class="d-none" accept=".png, .jpg"
                                            #fileUploadElem [(ngModel)]="selectedImageName" #fileName="ngModel"
                                            (change)="onFileSelected(model.clock, $event)" />
                                        <!-- <div class="resellerbranding_logo mt-3" *ngIf="model.clock.fileUrl">
                                            <div class="thumbnail ml-3">

                                            </div>
                                        </div> -->
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col nzSpan="24" class="d-flex">
                                <button *ngIf="model.clock.fileUrl" type="button" nz-button
                                    nzType="primary" (click)="fileUploadElem.click()">
                                    <span nz-icon nzType="reload" nzTheme="outline"></span>
                                    <span>Update Logo</span>
                                </button>

                                <button *ngIf="model.clock.fileUrl" type="button" nz-button (click)="initRemove()" class="ml-3">
                                    <span nz-icon nzType="delete" nzTheme="outline"></span>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

                <div nz-row class="w-100 mb-2">
                    <div nz-col nzSpan="24">
                        <nz-divider></nz-divider>
                    </div>
                </div>

                <div nz-row class="mb-3 w-100">
                    <div nz-col nzSpan="24">
                        <div nz-row class="mb-3">
                            <div nz-col nzSpan="24">
                                Background color
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <nz-radio-group [(ngModel)]="model.clock.isDefaultBackgroundColor"
                                    nzName="colorRadiogroup" name="isDefaultBackgroundColor"
                                    #isDefaultBackgroundColor="ngModel"
                                    (ngModelChange)="backgroundColorChanged($event)">
                                    <label nz-radio [nzValue]="true" for="defaultColor">
                                        Default</label>
                                    <label nz-radio [nzValue]="false" for="customColor">Custom</label>
                                </nz-radio-group>
                            </div>
                        </div>
                        <div nz-row class="mt-3">
                            <div nz-col nzXs="24" nzSm="24" nzMd="10" nzLg="10" nzXl="10">
                                <div class="input-container" *ngIf="!model.clock.isDefaultBackgroundColor">
                                    <nz-form-item>
                                        <nz-form-control class="mb-0">
                                            <nz-form-label>Enter a hex code</nz-form-label>
                                            <nz-input-group [nzPrefix]="prefixBackground">
                                                <nz-color-picker [(ngModel)]="model.clock.backgroundColor"
                                                    name="backgroundColor" #backgroundColor="ngModel"
                                                    nzPlaceHolder="Select Color"></nz-color-picker>
                                                <input nz-input [(ngModel)]="model.clock.backgroundColor"
                                                    name="backgroundColor" #backgroundColor="ngModel"
                                                    placeHolder="#000000" />
                                            </nz-input-group>
                                            <ng-template #prefixBackground></ng-template>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 mb-3">
                            <nz-divider></nz-divider>
                        </div>
                        <div nz-row class="mb-3">
                            <div nz-col nzSpan="24">
                                Font color
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <nz-radio-group [(ngModel)]="model.clock.isDefaultFontColor" nzName="fontRadiogroup"
                                    name="isDefaultFontColor" #isDefaultFontColor="ngModel"
                                    (ngModelChange)="fontColorChanged($event)">
                                    <label nz-radio [nzValue]="true" for="defaultFont">
                                        Default</label>
                                    <label nz-radio [nzValue]="false" for="customFont">Custom</label>
                                </nz-radio-group>
                            </div>
                        </div>
                        <div nz-row class="mt-3" *ngIf="!model.clock.isDefaultFontColor">
                            <div nz-col nzXs="24" nzSm="24" nzMd="10" nzLg="10" nzXl="10">
                                <div class="input-container">
                                    <nz-form-item>
                                        <nz-form-control class="mb-0">
                                            <nz-form-label>Enter a hex code</nz-form-label>
                                            <nz-input-group [nzPrefix]="prefixFontColor">
                                                <nz-color-picker [(ngModel)]="model.clock.fontColor" name="fontColor"
                                                    #fontColor="ngModel"></nz-color-picker>
                                                <input nz-input [(ngModel)]="model.clock.fontColor" name="fontColor"
                                                    #fontColor="ngModel" placeHolder="#000000" />
                                            </nz-input-group>
                                            <ng-template #prefixFontColor></ng-template>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Playlist(s)
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                                        nzTooltipTitle="Select Playlist" class="label-icon"></span>
                                </nz-form-label>
                                <nz-select name="displayList" [(ngModel)]="selectedDisplayLists" [nzShowArrow]="true"
                                    nzPlaceHolder="Select Playlist" nzMode="multiple" [nzMaxTagCount]="0"
                                    [nzMaxTagPlaceholder]="maxTagPlaceholder">
                                    <nz-option *ngFor="let item of displayLists" [nzValue]="item"
                                        nzLabel="{{item.value}}"></nz-option>
                                    <nz-option *ngIf="displayLists.length == 0" nzDisabled
                                        nzLabel="No Playlist Added."></nz-option>
                                </nz-select>
                                <ng-template #maxTagPlaceholder let-selected>
                                    {{ selected.length }} Item selected
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mt-2" *ngIf="selectedDisplayLists.length > 0">
                    <div class="selected-chip-container">
                        <nz-tag nzMode="closeable" class="selected-tag" (click)="removeDisplayList(item)"
                            *ngFor="let item of selectedDisplayLists">
                            <span class="selected-tag-content">{{item.value}}</span></nz-tag>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Group
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip nzTooltipTitle="Groups allow you to share things within manager with other people."></span>
                                </nz-form-label>
                                <nz-select nzPlaceHolder="Select group" name="groupId" #groupId="ngModel"
                                    [(ngModel)]="model.groupId" (ngModelChange)="onGroupChange()" [nzDisabled]="true">
                                    <nz-option *ngFor="let item of subscriptionPlans" [nzValue]="item.keyInt"
                                        nzLabel="{{item.value}}"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Folder
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip nzTooltipTitle="Organize items in Manager with folders."></span>
                                </nz-form-label>
                                <nz-select nzPlaceHolder="Select folder" name="folderId" [(ngModel)]="model.folderId">
                                    <nz-option *ngFor="let item of folders" [nzValue]="item.keyInt"
                                        nzLabel="{{item.value}}"></nz-option>
                                    <nz-option *ngIf="folders.length == 0" nzLabel="No Folder Added" nzValue="disabled"
                                        nzDisabled></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row class="mb-3">
                    <div nz-col nzSpan="24">
                        <div class="align-items-center">
                            <label class="mr-3" for="" class="fs-16 mb-0 fw-bold">Schedule</label>
                            <span class="ml-3"><nz-switch [(ngModel)]="isScheduled" (ngModelChange)="onSchedule()"
                                    name="schedule" #schedule="ngModel"></nz-switch></span>
                        </div>
                        <div nz-row *ngIf="isScheduled">
                            <div nz-col nzSpan="24">
                                <nz-divider></nz-divider>
                            </div>
                        </div>
                        <div nz-row class="mt-2 ml-2" *ngIf="isScheduled">
                            <div nz-col nzSpan="24">
                                <nz-radio-group [(ngModel)]="model.isDaily" name="isDaily" #isDaily="ngModel"
                                    (ngModelChange)="scheduleTypeChanged($event)">
                                    <label nz-radio [nzValue]="true">Daily</label>
                                    <label nz-radio [nzValue]="false">Date range</label>
                                </nz-radio-group>
                            </div>
                        </div>

                        <div nz-row [nzGutter]="[16]" class="mt-2" *ngIf="isScheduled">
                            <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                <div class="input-container">
                                    <nz-form-item>
                                        <nz-form-control class="mt-3 mb-0">
                                            <nz-form-label>Start time</nz-form-label>
                                            <nz-time-picker [nzUse12Hours]="true" [nzAllowEmpty]="false"
                                                (ngModelChange)="isStartTimeValid($event)" class="cursor-pointer"
                                                [(ngModel)]="scheduleStartTime" name="startTime" #startTime="ngModel"
                                                nzFormat="hh:mm a" nzPlaceHolder="Select" required>
                                            </nz-time-picker>
                                            <div *ngIf="form.submitted || startTime?.touched"
                                                class="validation-error-message">
                                                <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                    class="validation-icon mt-1" *ngIf="startTime?.errors"></span>
                                                <span *ngIf="startTime?.errors?.['required']" class="ml-1">Start time is
                                                    required</span>
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                <div class="input-container">
                                    <nz-form-item>
                                        <nz-form-control class="mt-3 mb-0">
                                            <nz-form-label>End time</nz-form-label>
                                            <nz-time-picker [nzUse12Hours]="true" class="cursor-pointer"
                                                (ngModelChange)="isTimeValid($event)" [nzAllowEmpty]="false"
                                                [(ngModel)]="scheduleEndTime" name="endTime" #endTime="ngModel"
                                                nzFormat="hh:mm a" nzPlaceHolder="Select" required>
                                            </nz-time-picker>
                                            <div *ngIf="form.submitted || endTime?.touched"
                                                class="validation-error-message">
                                                <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                    class="validation-icon mt-1"
                                                    *ngIf="(endTime?.errors) || (!endTime?.errors?.['required'] && !isEndTimeValid)"></span>
                                                <span *ngIf="endTime?.errors?.['required']" class="ml-1">End
                                                    time is required</span>
                                                <span *ngIf="!endTime?.errors?.['required'] && !isEndTimeValid"
                                                    class="ml-1">Please select any future time</span>
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div nz-row [nzGutter]="[16]" *ngIf="isScheduled && !model.isDaily">
                            <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                <div class="input-container">
                                    <nz-form-item>
                                        <nz-form-control class="mt-3 mb-0">
                                            <nz-form-label>Start date</nz-form-label>
                                            <nz-date-picker nzFormat="MM/dd/YYYY" name="startDate"
                                                [nzAllowClear]="false" [(ngModel)]="model.startDate"
                                                #startDate="ngModel" [nzDisabledDate]="disableStartDate"
                                                nzPlaceHolder="Select" class="cursor-pointer"></nz-date-picker>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                <div class="input-container">
                                    <nz-form-item>
                                        <nz-form-control class="mt-3 mb-0">
                                            <nz-form-label>End date</nz-form-label>
                                            <nz-date-picker nzFormat="MM/dd/YYYY" name="endDate" [nzAllowClear]="false"
                                                [(ngModel)]="model.endDate" #endDate="ngModel"
                                                [nzDisabledDate]="disableEndDate" nzPlaceHolder="Select"
                                                class="cursor-pointer"></nz-date-picker>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mt-2 mb-3">
                    <div class="align-items-center">
                        <label class="fs-16 mb-0 fw-bold">Show this media</label>
                        <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                            nzTooltipTitle="Show or hide media" class="label-icon"></span>
                        <span class="ml-3">
                            <nz-switch name="isEnabled" [(ngModel)]="model.clock.isEnabled"
                                #isEnabled="ngModel"></nz-switch>
                        </span>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0">
                                <nz-form-label>Notes</nz-form-label>
                                <nz-textarea-count [nzMaxCharacterCount]="100">
                                    <textarea rows="2" nz-input placeHolder="Enter Notes" name="description"
                                        #description="ngModel" [(ngModel)]="model.description"
                                        [maxlength]="100"></textarea>
                                </nz-textarea-count>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>

            <div nz-row class="disp-mob mobOne-action-bar">
                <div nz-col nzSpan="24" class="text-right">
                    <nz-card>
                        <div nz-row [nzGutter]="[16]">
                            <div nz-col nzSpan="12">
                                <button nz-button class="btn-full" type="button" (click)="cancel()">Cancel</button>
                            </div>
                            <div nz-col nzSpan="12">
                                <button nz-button class="btn-full" nzType="primary"
                                    [disabled]="form.invalid">Save</button>
                            </div>
                        </div>
                    </nz-card>
                </div>
            </div>
        </form>
    </nz-spin>
</section>