export class UserMediaAppClockUpsertModel {
    id: string;
    userMediaAppId: number;
    timeZone: string;
    baseUtcOffsetTicks: number;
    is24HoursFormat: boolean;
    isAutoDetect: boolean;
    isEnabled: boolean;
    lengthInSeconds: string;
    displayOrder: number;
    backgroundColor: string;
    fontColor: string;
    isDefaultBackgroundColor: boolean;
    isDefaultFontColor: boolean;

    file: File;
    isUploadingImage: boolean;
    fileUploadPercent: number;
    isFileUploaded: boolean;
    fileName: string;
    originalFileName: string;
    fileUrl: string;
    logo: string;
    logoUrl: string;

    constructor() {
        this.timeZone = '';
        this.is24HoursFormat = false;
        this.isEnabled = true;
        this.isAutoDetect = true;
        this.lengthInSeconds = '';
        this.displayOrder = 0;
        this.isUploadingImage = true;
        this.fileUploadPercent = 0;
        this.originalFileName = '';
        this.isDefaultBackgroundColor = true;
        this.isDefaultFontColor = true;
    }
}