export class LoginModel {
    email: string;
    password: string;
    canvaUserId: string;
    resellerId: string;
    signUpUrlParams: string;
    plan: string;
    addon: string;
    coupon: string;
}
