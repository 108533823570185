export class UserMediaAppCanvaUpsertModel{
    public id: string;
    public userMediaAppId: number;
    public canvaDesignId: string;
    public canvaFileName: string;
    public url: string;
    public lengthInSeconds: string;
    public isEnabled: boolean;
    public displayOrder: number;

    constructor() {
        this.isEnabled = true;
        this.displayOrder = 0;
    }
}