import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppUtils } from "src/helpers";

@Injectable()

export class GroupService {
    private apiEndPoint = environment.apiBaseUrl + 'api/group';

    constructor(private http: HttpClient,
        private appUtils: AppUtils
    ) { }

    getList(): Observable<any> {
        return this.http.get(`${this.apiEndPoint}/list-for-user`);
    }

    validateInvitation(groupId: number, invitationId: string, invitationToken: string): Observable<any> {
        const headers = new HttpHeaders({
            'x-api-key': this.appUtils.encrypt(environment.apiKey)
        })
        return this.http.get(`${this.apiEndPoint}/validate-invitation/${groupId}/${invitationId}/${invitationToken}`, {headers});
    }
}